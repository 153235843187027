// ****************************************************
// [배포] 1.혜택 > 전체 제휴처 > 혜택 브랜드 상세 (VIP콕 바텀시트)
// ****************************************************
import {BottomSheet, Header, IconButton} from '@lguuxe/2024_designsystem_uni';
import React, {useEffect, useState} from 'react';
import '../../css/style.css';
import SectionContentHeaderBenefit from "../Vipkok/SectionContentHeaderBenefit";
import CommonDivider from "../Common/CommonDivider";
import BoxHeaderInfo from "../Vipkok/BoxHeaderInfo";
import CallCenter from "../Vipkok/CallCenter";
import {PartnerDetailData, PartnerType, UserGrade} from "../../pages/Partner/types";
import {CouponItem} from "../../pages/My/types";
import {getItemByKey} from "../../utils/indexedDB";
import CommonBsBackground from "../Common/CommonBsBackground";

interface BS_VipKokDetailProps {
    isOpen: boolean;
    onClose: () => void;
    // ptnId: string;
    partnerInfo: PartnerDetailData;
    couponList: CouponItem[];
}

const BS_VipKokDetail: React.FC<BS_VipKokDetailProps> = ({isOpen, onClose, partnerInfo, couponList}) => {
    const [partnerType, setPartnerType] = useState<PartnerType>("normal");
    const [userGrade, setUserGrade] = useState<UserGrade>("미로그인");
    const [useVipkokYn, setUseVipkokYn] = useState<string>("N");

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const memberStatus = await getItemByKey("memberStatus")
            setUserGrade(
                memberStatus === "0" ? "미로그인" :
                    memberStatus === "1" || memberStatus === "3" ? "VIP/VVIP" :
                        memberStatus === "2" || memberStatus === "4" ? "우수" :
                            memberStatus === "5" ? "일반" : "미로그인"
            );
            setUseVipkokYn(await getItemByKey("useVipkokYn"))
        }
        fetchIndexedDb();

        switch (partnerInfo.vipPartnerType) {
            case '165001': // 일반형
                setPartnerType('normal')
                break;
            case '165002': // 영화형
                setPartnerType('movie')
                break;
            case '165003': // 쿠폰형
                setPartnerType('coupon')
                break;
            default:
                setPartnerType('normal')
                break;
        }
    }, [partnerInfo]);

    const handleChangeState = (state: string) => {
        setUseVipkokYn(state);
    }

    return (
        <>
            <CommonBsBackground isOpen={isOpen} onClose={onClose}/>

            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading' divider={false}>
                        VIP 콕
                        <Header.RightSlot>
                            <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>


                    {/*<BottomSheet.CenterSlot>*/}
                    <div style={{
                        paddingTop: '0px', height: 'auto', maxHeight: 'calc(100vh - 10rem)',
                        overflowY: 'auto',  // 내용이 넘치면 스크롤 가능하게 설정
                    }}>
                        {/*<VipkokDetail isPopup={true} popupPtnId={ptnId}/>*/}

                        <SectionContentHeaderBenefit partnerDetail={partnerInfo} partnerType={partnerType}
                                                     useVipkokYn={useVipkokYn}
                                                     userGrade={userGrade} couponList={couponList}
                                                     onChangeState={handleChangeState}/>
                        <CommonDivider/>

                        {/* VIP콕 혜택 정보 */}
                        <BoxHeaderInfo title="혜택" description={partnerInfo.vipBnf}/>
                        <BoxHeaderInfo title="혜택한도" description={partnerInfo.vipBnfLimit}/>
                        <BoxHeaderInfo title="꼭 확인하세요*" description={partnerInfo.vipNotice}/>
                        <BoxHeaderInfo title="이용방법" description={partnerInfo.vipGuide}/>
                        <CommonDivider/>

                        {/* 대표문의 */}
                        <CallCenter phone={partnerInfo.phone || ""} homepage={partnerInfo.homepage || ""}
                                    name={partnerInfo.ptnName}/>
                    </div>
                </BottomSheet.TopSlot>

                {/*<BottomSheet.CenterSlot>*/}
                {/*    <div style={{paddingTop: '0px', height: 'auto'}}>*/}
                {/*        <PartnerProfile profileInfo={profileInfo}/>*/}
                {/*        <CommonDivider/>*/}

                {/*        <div style={{padding: '0 0 24px'}}>*/}
                {/*            <TextSet*/}
                {/*                subComponent='Heading_4'*/}
                {/*                text={{*/}
                {/*                    title: '혜택',*/}
                {/*                    description:*/}
                {/*                        '하드코딩입니다... 수정 필요 ',*/}
                {/*                }}*/}
                {/*                blockSpacing={{top: '24px', bottom: '12px'}}*/}
                {/*                isIndent={true}*/}
                {/*            />*/}

                {/*            <TextSet*/}
                {/*                subComponent='Heading_4'*/}
                {/*                text={{*/}
                {/*                    title: '혜택 한도',*/}
                {/*                    description:*/}
                {/*                        '혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구',*/}
                {/*                }}*/}
                {/*                blockSpacing={{top: '24px', bottom: '12px'}}*/}
                {/*                isIndent={true}*/}
                {/*            />*/}

                {/*            <TextSet*/}
                {/*                subComponent='Heading_3'*/}
                {/*                text={{*/}
                {/*                    title: '꼭 확인하세요*',*/}
                {/*                    description:*/}
                {/*                        '유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사',*/}
                {/*                }}*/}
                {/*                blockSpacing={{top: '24px', bottom: '12px'}}*/}
                {/*                isIndent={true}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</BottomSheet.CenterSlot>*/}

                {/*<BottomSheet.BottomSlot>*/}
                {/*    <CTA gradation layout="row">*/}
                {/*        <Button color="secondary" fill="solid">*/}
                {/*            혜택 사용하러 가기*/}
                {/*        </Button>*/}
                {/*    </CTA>*/}
                {/*</BottomSheet.BottomSlot>*/}
            </BottomSheet>
        </>
    );
};

export default BS_VipKokDetail;
