import React, {useEffect, useState} from "react";
import {Accordion, Box, Button, CTA, Image, List, Text} from "@lguuxe/2024_designsystem_uni";

import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import CommonDividerThin from "../../components/Common/CommonDividerThin";
import defaultThumbnail from '../../images/default/default thumbnail.svg';

import {useLocation, useNavigate, useParams} from "react-router-dom";
import BarcodeSection from "../../components/My/Coupon/BarcodeSection";
import DateSection from "../../components/My/Coupon/DateSection";
import {couponApi} from "../../api/gatewayApi/other";
import {CouponInfo, initCouponInfo} from "./types";
import ButtonSection from "../../components/My/Coupon/ButtonSection";
import NumberSection from "../../components/My/Coupon/NumberSection";
import CouponMessage from "../../components/My/Coupon/CouponMessage";
import CommonPopup from "../../components/Common/CommonPopup";
import BS_CouponEmployee from "../../components/My/Coupon/BS_CouponEmployee";
import {sendEventReturn} from "../../api/nativeBridge";
import BS_CouponEmployeeCheck from "../../components/My/Coupon/BS_CouponEmployeeCheck";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {gtmDeleteComp, gtmOpenPopup, gtmPageLoad, gtmUseCouponComp} from "../../api/gtm";
import {decodeHtmlEntities} from "../../utils/textUtils";

interface CouponDetailsProps {
    isPopup?: boolean;
    popupCpnId?: string;
}

const CouponDetails: React.FC<CouponDetailsProps> = ({isPopup, popupCpnId}) => {
    const {cpnId} = useParams<{ cpnId: string }>() || {cpnId: ''};
    const [couponInfo, setCouponInfo] = useState<CouponInfo>(initCouponInfo);
    const [couponState, setCouponState] = useState<'ready' | 'used' | 'expired'>('ready');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('쿠폰을 삭제하시겠어요?');
    const [showEmployeeBS, setShowEmployeeBS] = useState(false);
    const [showEmployeeCheckBS, setShowEmployeeCheckBS] = useState(false);
    const location = useLocation();
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const id = isPopup && popupCpnId ? popupCpnId : cpnId;
        if (!id) {
            console.error("termId가 정의되지 않았습니다.");
            setIsLoading(false);
            return;
        }
        try {
            // const id = isPopup && popupCpnId ? popupCpnId : cpnId;
            const response = await couponApi.getCouponIssuedById(id, 'v1');
            if (response.header.resultCode !== '0000' || !response.body) {
                console.log('error fetchData:', response);
                return;
            }
            const couponInfo = response.body;
            setCouponInfo(couponInfo);
            await gtmPageLoad({
                url: `/my/coupon/${cpnId}`,
                contentGroup: '마이|내 쿠폰|쿠폰 상세',
                moduleName: '',
                pageTitle: `${couponInfo.ptnName}`,
                affiliateId: couponInfo.ptnId,
                affiliateName: couponInfo.ptnName,
                affiliateSector: '',
            });

            if (couponInfo.useYn === 'Y') {
                setCouponState('used');
                return;
            }
            const endDate = new Date(couponInfo.expEndDate.replace(' ', 'T'));
            const now = new Date(response.header.timeStamp.replace(' ', 'T'));
            if (now.getTime() > endDate.getTime()) {
                setCouponState('expired');
                return;
            }
        } catch (e) {
            console.error('error fetchData:', e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveClick = () => {
        setPopupMessage(couponState === 'ready' ? '삭제한 쿠폰은 다시 받을 수 없어요.\n쿠폰을 삭제하시겠어요?' : '쿠폰을 삭제하시겠어요?');
        setShowPopup(true);
        gtmOpenPopup(couponState === 'ready' ? '삭제한 쿠폰은 다시 받을 수 없어요.\n쿠폰을 삭제하시겠어요?' : '쿠폰을 삭제하시겠어요?');
    }

    const handleRemove = () => {
        const fetchCouponRemove = async () => {
            try {
                const response = await couponApi.deleteCouponById(couponInfo.cpnKey, 'v1');
                if (response.header.resultCode === '0000') {
                    showSnackbar('쿠폰이 삭제되었어요.');
                    handleBack();
                    const contentStatus = couponState === 'ready' ? '사용 가능 쿠폰 삭제' : couponState === 'used' ? '사용 불가 쿠폰 삭제' : '기간 만료 쿠폰 삭제';
                    gtmDeleteComp(contentStatus, couponInfo.summary);
                    return;
                }

                showSnackbar('일시적인 오류에요. 쿠폰 삭제에 실패했어요.');
            } catch (e) {
                console.error('error fetchCouponRemove:', e);
            } finally {
                setShowPopup(false);
            }
        }
        fetchCouponRemove();
    }

    const handleEmployeeClick = () => {
        setShowEmployeeBS(true);
        setShowEmployeeCheckBS(false);
    }

    const handleEmployeeAgree = () => {
        setShowEmployeeBS(false);
        setShowEmployeeCheckBS(true);
    }

    const handleEmployeeCheckSuccess = () => {
        showSnackbar('쿠폰을 사용했어요.');
        gtmUseCouponComp(couponInfo.summary);
        fetchData();
    }

    const handleBack = () => {
        navigate(-1);
        // if (location.state && location.state.couponSort) {
        //     navigate('/my/coupon', {state: {couponSort: location.state.couponSort}, replace: true});
        //     console.log('location.state.couponSort:', location.state.couponSort);
        //     // navigate('/my/coupon');
        // } else {
        //     navigate(-1);
        //     console.log('navigate(-1)');
        // }
    }
    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <Box style={{padding: "0 0 100px 0"}}>
                {/* header */}
                {!isPopup &&
                    <CommonRightIconHeader title={couponInfo.ptnName} icon={'close_line'} onClick={handleBack}/>}


                {/* 내쿠폰 혜택 요약 */}
                <div>
                    <Box
                        style={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                            width: "100%"
                        }}
                        type="2_tb"
                    >
                        <List
                            type="view"
                            fill="none"
                            items={[
                                {
                                    leftSlot: (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }}
                                            >
                                                <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                                    {couponInfo.ptnName}
                                                </Text>
                                                <Text typo="font_heading_3B" color="color_text_neutral_6">
                                                    {decodeHtmlEntities(couponInfo.summary)}
                                                </Text>
                                            </div>
                                        </>
                                    ),
                                    rightSlot: [
                                        <Image
                                            src={couponInfo.ptnLogoImgUrl}
                                            srcFallback={defaultThumbnail}
                                            ratio="1:1"
                                            width="48px"
                                            rounded={"50%"}
                                        />
                                    ]
                                }
                            ]}
                        />
                        {/*<SectionHeadingText2row1/>*/}

                        {/* -------- 쿠폰 종류에 따라 달라지는 영역 ---------*/}
                        {couponState === 'ready' ? (
                            <>
                                {/* 현장형 */}
                                {couponInfo.barcodeType === '111006' &&
                                    <ButtonSection onClick={handleEmployeeClick} couponInfo={couponInfo}/>}
                                {/* 비바코드형 */}
                                {couponInfo.barcodeType === '111007' &&
                                    <NumberSection couponNumber={couponInfo.cpnNo}/>}

                                {/* 바코드형 */}
                                {(couponInfo.barcodeType !== '111006' && couponInfo.barcodeType !== '111007') &&
                                    <BarcodeSection barcodeNumber={couponInfo.cpnNo} couponInfo={couponInfo}/>}
                            </>
                        ) : <CouponMessage couponState={couponState}/>}
                    </Box>
                </div>

                <Box
                    style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                        width: "100%"
                    }}
                    type="2_tb"
                >
                    {/* 쿠폰 기간 정보 */}
                    <div style={{padding: "0 20px 8px"}}>
                        <CommonDividerThin/>
                    </div>
                    <DateSection couponInfo={couponInfo}/>
                    <CommonDivider/>

                    {/* 이용방법 및 유의사항 */}
                    <div style={{paddingBottom: "40px"}}>
                        <Accordion
                            fill="underline"
                            size="large"
                            weight="bolder"
                            divider={false}
                            expanded={true}
                            title={
                                <Text typo="font_body_1B" color="color_text_neutral_5">유의사항</Text> // typo="font_body_1SB" 변경
                            }
                        >
                            <Accordion.Body>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    {decodeHtmlEntities(couponInfo.useGuide)}
                                </Text>
                            </Accordion.Body>
                        </Accordion>
                    </div>
                    {/*<MustCheckAccordion items={[couponInfo.useGuide]}/>*/}
                </Box>

                {/* 하단 버튼 */}
                <div style={{position: "fixed", bottom: "0", left: "0", right: "0"}}>
                    <CTA layout="row">
                        <Button color="neutral" size="large" onClick={handleRemoveClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'마이|내 쿠폰|쿠폰 상세 - 링크 클릭'}
                                data-gtm-event-label={`컨텐츠 : 쿠폰 삭제`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'마이|내 쿠폰|쿠폰 상세'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`쿠폰 삭제`}
                                data-gtm-affiliate-id={couponInfo.ptnId}
                                data-gtm-affiliate-name={couponInfo.ptnName}>
                            쿠폰 삭제
                        </Button>
                        {couponState === 'ready' && couponInfo.homepageUrl && (
                            <Button color="primary" size="large"
                                    onClick={() => sendEventReturn('EXTERNAL_CALL', {
                                        type: 1,
                                        url: couponInfo.homepageUrl,
                                    })}
                                    data-gtm-event-name={'all_click'}
                                    data-gtm-event-category={'클릭'}
                                    data-gtm-event-action={'마이|내 쿠폰|쿠폰 상세 - 링크 클릭'}
                                    data-gtm-event-label={`컨텐츠 : 사용하러가기`}
                                    data-gtm-click-url={''}
                                    data-gtm-click-location={'마이|내 쿠폰|쿠폰 상세'}
                                    data-gtm-click-direction={'내부'}
                                    data-gtm-click-text={`사용하러가기`}
                                    data-gtm-affiliate-id={couponInfo.ptnId}
                                    data-gtm-affiliate-name={couponInfo.ptnName}
                            >
                                사용하러가기
                            </Button>
                        )}
                    </CTA>
                </div>
            </Box>
            <CommonPopup submitText={'삭제'} cancelText={'취소'} isOpen={showPopup} onSubmit={handleRemove}
                         onClose={() => setShowPopup(false)} description={popupMessage}/>
            <BS_CouponEmployee isOpen={showEmployeeBS} onClose={() => setShowEmployeeBS(false)}
                               onAgree={handleEmployeeAgree}/>
            <BS_CouponEmployeeCheck isOpen={showEmployeeCheckBS} onClose={() => setShowEmployeeCheckBS(false)}
                                    onSuccess={handleEmployeeCheckSuccess} cpnKey={couponInfo.cpnKey}/>
        </>
    );
};

export default CouponDetails;
