import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, CTA, IconButton, Image, TextSet} from '@lguuxe/2024_designsystem_uni';
import {SwipeableHandlers, SwipeableProps, useSwipeable} from 'react-swipeable';
import tutorialImg from "../../images/img/tutorial/tutorial_1.svg";
import tutorial2Img from "../../images/img/tutorial/tutorial_2.svg";
import tutorial3Img from "../../images/img/tutorial/tutorial_3.svg";
import tutorial4Img from "../../images/img/tutorial/tutorial_4.svg";
import {brazeEventApi} from "../../api/brazeBridge";
import {gtmPageLoad} from "../../api/gtm";

interface TextSet {
    description: JSX.Element;
    title: string;
}

interface TutorialProps {
    onClose: () => void;  // Tutorial을 닫기 위한 콜백 함수
}

const textSets: TextSet[] = [
    {
        description: (
            <span style={{paddingTop: "8px"}}>매달 달라지는 파격 혜택 '유플투쁠'<br/>VIP 이상고객에게 ‘VIP’콕, 가입만 해도 혜택이 가득해요!</span>),
        title: 'U+멤버십 고객 전용 특별 혜택'
    },
    {
        description: (<span style={{paddingTop: "8px"}}>고객님의 취향과 라이프 스타일에 따라<br/>필요한 혜택을 매일 추천해드려요</span>),
        title: '나에게 딱 맞는 혜택 추천'
    },
    {
        description: (<span style={{paddingTop: "8px"}}>나는 얼마나 혜택을 잘 쓰고 있을까?<br/>마이페이지에서 한눈에 확인할 수 있어요</span>),
        title: '나에게 이용현황을 한눈에'
    },
    {
        description: (<span style={{paddingTop: "8px"}}>U+멤버십 미션을 수행하며<br/>다양한 배지를 수집하고 친구들에게 선물할 수 있어요</span>),
        title: '다양한 미션에 참여해보세요'
    }
];

const imgSets: string[] = [
    tutorialImg, tutorial2Img, tutorial3Img, tutorial4Img
];

const Tutorial: React.FC<TutorialProps> = ({onClose}) => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const func = async () => {
            await gtmPageLoad({url: '/tutorial', contentGroup: '기타|튜토리얼', moduleName: '', pageTitle: '튜토리얼'});
        }
        func()
    }, [currentIndex]);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex >= textSets.length - 1) {
                onClose()
                brazeEventApi.tutorial_close();
                return prevIndex; // 현재 인덱스가 마지막 인덱스인 경우 더 이상 증가하지 않음
            }
            return prevIndex + 1;
        });
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex <= 0) {
                return prevIndex; // 현재 인덱스가 첫 번째 인덱스인 경우 더 이상 감소하지 않음
            }
            return prevIndex - 1;
        });
    };

    const handleSkipClick = () => {
        brazeEventApi.tutorial_close();
        onClose();  // 튜토리얼 종료
    };

    interface CustomSwipeableProps extends SwipeableProps {
        preventDefaultTouchmoveEvent?: boolean;
    }

    const handlers: SwipeableHandlers = useSwipeable({
        onSwipedLeft: handleNextClick,
        onSwipedRight: handlePrevClick,
        preventDefaultTouchmoveEvent: true, // 추가
        trackMouse: true
    } as CustomSwipeableProps);

    return (
        <div {...handlers} style={{
            // zIndex: 1002, position: 'relative'
            zIndex: 9999,  // 화면에서 가장 앞에 있도록 z-index 값 충분히 높게 설정
            position: 'fixed',  // 고정 위치로 설정
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflowY: 'auto',  // 세로 스크롤을 활성화
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box
                type="2_trbl"
                style={{
                    marginTop: "16px" // 규격서에는 56px 이다.
                }}
            >
                <TextSet
                    subComponent="Heading_2"
                    text={textSets[currentIndex]}
                    weight="bolder"
                />
            </Box>
            <Box type="1_trl">
                <Image
                    ratio={"32:40"}
                    width={"100%"}
                    customClass="customClass"
                    src={imgSets[currentIndex]}
                />
            </Box>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    padding: "4px 0 8px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px"
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        padding: "6px 0px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "6px"
                    }}
                >
                    {textSets.map((_, index) => (
                        <span
                            key={index}
                            style={{
                                display: "flex",
                                backgroundColor: "#66707A",
                                width: index === currentIndex ? "18px" : "6px",
                                height: "6px",
                                borderRadius: index === currentIndex ? "24px" : "50%",
                                opacity: index === currentIndex ? "1" : "0.4",
                                cursor: "pointer"
                            }}
                        />
                    ))}
                </Box>
            </Box>
            <Box
                style={{
                    // position: "fixed",
                    bottom: "0",
                    width: "100%",
                    padding: "0"
                }}
            >
                <CTA layout="column">
                    <IconButton
                        color="neutral"
                        icon="visibility_filled"
                    />
                    <Button
                        color="primary"
                        onClick={handleNextClick}
                    >
                        다음
                    </Button>
                    {currentIndex !== 3 && (
                        <Button color="neutral" fill="ghost" onClick={handleSkipClick}>
                            건너뛰기
                        </Button>)
                    }
                </CTA>
            </Box>
        </div>
    );
}

export default Tutorial;
