import React, {useEffect, useState} from 'react';
import {Button, Chip, Text} from "@lguuxe/2024_designsystem_uni";
import {api, ApiEndpoints} from '../../api/api';
import {gtmViewSearchResults} from "../../api/gtm";

interface RecommandSearchProps {
    onSearch: (query: string) => void;
    input?: string; // gtm 사용을 위한 props
}

interface Chip {
    word: string;
}

const RecommandSearch: React.FC<RecommandSearchProps> = ({onSearch, input}) => {
    const [chipList, setChipList] = useState<Chip[]>([]);

    const fetchRecommendedSearches = async () => {
        try {
            const response = await api.get(ApiEndpoints.SEARCH.RECOMM);
            setChipList(response.body.keywordList || []);

            let recommendedResult: string = response.body.keywordList
                .map((keyword: Chip) => keyword.word)
                .join(',');
            if (input) {
                gtmViewSearchResults(input, '0', recommendedResult);
            }
        } catch (error) {
            console.error("Failed to fetch recommended searches:", error);
        }
    };

    useEffect(() => {
        fetchRecommendedSearches();
    }, []);

    return (
        <div>
            <div style={{padding: "0 20px"}}>
                <div>
                    <div style={{
                        paddingTop: "24px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text typo="font_heading_4B" color="color_text_neutral_6">
                            추천 검색어
                        </Text>
                        <Button
                            color="neutral"
                            fill="text"
                            size="medium"
                            state="default"
                            typo="font_label_3Sb"
                            onClick={fetchRecommendedSearches}
                        >
                            <Text typo="font_label_3Sb" color="color_text_neutral_3">
                                새로고침
                            </Text>
                        </Button>
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap", gap: "8px", paddingTop: "16px"}}>
                        {chipList.map((chip, index) => (
                            <Chip.Button
                                key={index}
                                color="neutral"
                                label={`# ${chip.word}`}
                                size='medium'
                                onClick={() => onSearch(chip.word)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecommandSearch;

