import React, {useEffect, useState} from 'react';
import {Box, Button, CTA, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import SectionContents2 from "../../../components/Entire/Pop/SectionContents2";
import SectionContents3 from "../../../components/Entire/Pop/SectionContents3";
import CommonDivider from "../../../components/Common/CommonDivider";
import {affiliateServiceApi} from "../../../api/gatewayApi/partnerAndAffiliate-service";
import {handlePromiseResult} from "../../../api/api";
import {getItemByKey} from "../../../utils/indexedDB";
import ContentSection from "../../../components/Entire/Pop/ContentSection";
import useLoading from "../../../hooks/useLoading";
import {useNavigate} from "react-router-dom";
import paymentApi from "../../../api/gatewayApi/payment";
import CommonPopup from "../../../components/Common/CommonPopup";
import MustCheckAccordion from "../../../components/Setting/SectionAccordion";
import {gtmPageLoad} from "../../../api/gtm";

interface PaymentAgree {
    useTermYn: string;
}

interface PaymentBlock {
    payBlckYn: string;
    autoPayBlckYn: string;
}


const Pop: React.FC = () => {
    const [isJoin, setIsJoin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [paymentAgree, setPaymentAgree] = useState<PaymentAgree>({useTermYn: 'N'});
    const [paymentBlock, setPaymentBlock] = useState<PaymentBlock>({payBlckYn: 'Y', autoPayBlckYn: 'Y'});
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const MUST_CHECK_ITEMS = [
        '결제금액은 휴대폰 이용요금에 포함되어 청구됩니다.',
        '오프라인 매장에서만 이용할 수 있습니다.',
        'POP 서비스 약관에 동의해야 GS&POINT 적립이 가능합니다.',
        '일부 매장에서는 서비스가 제한될 수 있습니다.\n(휴게소 입점 매장 등 일부 특수 매장)',
    ]


    useEffect(() => {
        const fetchData = async () => {
            const ci = await getItemByKey('ci');

            try {
                const [joinStatus, paymentAgreePromise, paymentBlockPromise] = await Promise.allSettled([
                    affiliateServiceApi.getJoinStatus('v1'),
                    paymentApi.getServiceUseTerm('v1'),
                    paymentApi.getSetting('v1')
                ]);
                const joinStatusBody = handlePromiseResult(joinStatus, {joinYn: 'N'});
                setIsJoin(joinStatusBody.joinYn === 'Y');
                setPaymentAgree(handlePromiseResult(paymentAgreePromise, {useTermYn: 'N'}));
                setPaymentBlock(handlePromiseResult(paymentBlockPromise, {payBlckYn: 'N', autoPayBlckYn: 'N'}));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
                await gtmPageLoad({
                    url: '/pop',
                    contentGroup: '마이|POP 서비스',
                    moduleName: '',
                    pageTitle: 'POP 서비스'
                });
            }
        }
        fetchData();
    }, []);

    const handleJoin = () => {
        if (paymentAgree.useTermYn === 'Y' || paymentBlock.payBlckYn === 'N') {
            navigate('/pop/terms');
            return;
        }
        setShowPopup(true);
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={'POP 서비스'}/>

            <ContentSection/>

            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        description: (
                            <span style={{paddingTop: "6px"}}>
                                바코드로 편리하게 결제하고 U+멤버스 할인, POP상품 추가 할인과 함께 상품별 할인 또는 무료 쿠폰도 받을 수 있습니다.
                            </span>
                        ),
                        title: (
                            <Text typo="font_heading_2B" color="color_text_neutral_6">
                                이런 점이 좋아요
                            </Text>
                        ),
                    }}
                    weight="default"
                />
            </Box>

            <SectionContents2/>
            <SectionContents3/>

            <div style={{marginBottom: "8px"}}>
                <CommonDivider/>
            </div>

            <MustCheckAccordion items={MUST_CHECK_ITEMS}/>

            {isJoin ? <Box style={{padding: "0"}}>
                    <Text typo="font_label_2M"
                          style={{display: "flex", padding: "12px 0 8px", justifyContent: "center"}}>
                        이미 가입했어요</Text>
                    <CTA
                        layout="column"
                    >
                        <Button color="primary"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    navigate('/partners')
                                }}
                                data-gtm-event-name="all_click"
                                data-gtm-event-category="클릭"
                                data-gtm-event-action={`마이|POP 서비스 - 링크 클릭`}
                                data-gtm-event-label={`컨텐츠 : 제휴사 상세보기`}
                                data-gtm-click-url=""
                                data-gtm-click-location='마이|POP 서비스'
                                data-gtm-click-direction="내부"
                                data-gtm-click-text='제휴사 상세보기'
                        >
                            제휴사 상세보기
                        </Button>
                    </CTA>
                </Box> :
                <Box style={{padding: "0"}}>
                    <Text typo="font_label_2M"
                          style={{display: "flex", padding: "12px 0 8px", justifyContent: "center"}}>
                        아래 버튼을 누르면 약관 동의 화면으로 이동해요</Text>
                    <CTA
                        layout="column"
                    >
                        <Button color="primary" onClick={handleJoin}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'마이|POP 서비스 - 링크 클릭'}
                                data-gtm-event-label={'컨텐츠 : 가입하기'}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'마이|POP 서비스'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={'가입하기'}
                        >
                            가입하기
                        </Button>
                    </CTA>
                </Box>
            }

            <CommonPopup description={'오프라인 매장에서 휴대폰결제가 가능한 고객만 이용할 수 있어요.'} submitText={'확인'}
                         onSubmit={() => setShowPopup(false)} isOpen={showPopup}/>
        </>
    );
}

export default Pop;