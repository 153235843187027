import React from 'react';
import {Header, IconButton, Text} from "@lguuxe/2024_designsystem_uni";

// Define a type for the props
interface CommonRightIconHeaderProps {
    title: string;
    icon: string;
    onClick: () => void;
}

const CommonRightIconHeader: React.FC<CommonRightIconHeaderProps> = ({title, icon, onClick}) => {
    return (
        <Header
            align="center"
            variant="heading"
            divider={false}
        >
            <Header.CenterSlot>
                <div style={{
                    width: 'calc(100vw - 110px)',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Text typo="font_heading_4Sb" style={{
                        display: "inline-block",
                        // WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // 최대 2줄로 설정
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                    }}>
                        {title}
                    </Text>
                </div>
            </Header.CenterSlot>
            <Header.RightSlot>
                <IconButton
                    color="neutral"
                    fill="icon"
                    icon={icon}
                    noStates
                    onClick={onClick}
                />
            </Header.RightSlot>
        </Header>
    );
};

export default CommonRightIconHeader;
