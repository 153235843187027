import React, {useEffect, useRef, useState} from 'react'
import {FAB, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni'
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import ChipButtonGroup from "../../../components/Entire/Help/ChipButtonGroup";
import {faqApi} from "../../../api/gatewayApi/notiAndQna";
import {useNavigate} from "react-router-dom";
import BarcodeLoading from "../../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import {decodeHtmlEntities} from "../../../utils/textUtils";
import {gtmPageLoad} from "../../../api/gtm";

const chipButtons = [
    {id: 1, label: '전체', type: '', gtmLocation: '카테고리'},
    {id: 2, label: '결제', type: '113001', gtmLocation: '카테고리'},
    {id: 3, label: '혜택', type: '113003', gtmLocation: '카테고리'},
    {id: 4, label: '기타', type: '113004', gtmLocation: '카테고리'},
];

interface Faq {
    faqId: string;
    title: string;
    date: string;
}

const Faq: React.FC = () => {
    const navigate = useNavigate();
    const [activeChipId, setActiveChipId] = useState<number>(1);
    const [faqList, setFaqList] = useState<Faq[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true); // 무한 스크롤에서 더 가져올 데이터가 있는지 여부
    const isFirstRender = useRef(true);
    const [showFab, setShowFab] = useState(false);

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: '/help/faq',
                contentGroup: `자주하는 질문`,
                moduleName: '카테고리|전체|리스트',
                pageTitle: '자주하는 질문'
            });
        }
        gtm();

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setShowFab(true);
            } else {
                setShowFab(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        fetchData(1);
    }, [activeChipId]);

    const fetchData = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page;

        try {
            const request = {
                orderType: '', // 필요없어보임
                faqType: chipButtons[activeChipId - 1].type,
                pageNo: currentPage.toString(),
            }
            faqApi.getCategories(request, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setFaqList(res.body.faqList);
                        setPage(2);
                    } else {
                        setFaqList([...faqList, ...res.body.faqList]);
                        setPage(currentPage + 1);
                    }

                    if (res.body.faqList.length === 0) {
                        setHasMore(false);
                    }
                }
            });
        } catch (error) {
            console.log('Failed to fetchData :', error);
        }
    }

    const handleChipClick = (id: number) => {
        console.log(`Chip with id ${id} clicked`);
        setActiveChipId(id);
        setPage(1);
        setHasMore(true);
    }

    const handleClick = (event: any) => {
        console.log(event.target.id);
        navigate(`/help/faq/${event.target.id}`);
    }

    return (
        <>
            <InfiniteScroll next={fetchData} hasMore={hasMore}
                            loader={<BarcodeLoading/>}
                            dataLength={Number(faqList.length)}
                            scrollableTarget={"window"}
            >

                <CommonLeftIconHeader title={'자주하는 질문'}/>

                <ChipButtonGroup chipButtons={chipButtons} activeChipId={activeChipId} onChipClick={handleChipClick}/>

                <List
                    fill="none"
                    type="view"
                    size="large"
                    items={faqList.map((item, index) => ({
                        id: item.faqId,
                        leftSlot: [
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: <Text
                                        style={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'normal',
                                            WebkitLineClamp: 2,
                                        }}
                                    >
                                        {decodeHtmlEntities(item.title)}
                                    </Text>,
                                }}
                                weight="bolder"
                            />
                        ],
                        linkIconOnlyId: item.faqId,
                        onClick: handleClick,
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `${chipButtons.find((item) => item.id === activeChipId)?.label || ''}|리스트 - 링크 클릭`,
                            'data-gtm-event-label': `컨텐츠 : ${item.title}`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `${chipButtons.find((item) => item.id === activeChipId)?.label || ''}|리스트`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': item.title
                        }
                    }))}
                />

                {showFab &&
                    <FAB
                        icon="chevron_up_small_line"
                        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                        size="small"
                        style={{
                            position: "fixed",
                            right: "24px",
                            bottom: "47px"
                        }}
                    />}

            </InfiniteScroll>
        </>
    )
}

export default Faq
