export const formatCurrency = (value: string | undefined) => {
    if (!value) {
        return '0';
    }
    const numericValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(numericValue)) {
        // throw new Error('Invalid input: the value must be a valid number');
        return '0';
    }
    const formattedValue = numericValue.toLocaleString('ko-KR');
    return `${formattedValue}`;
};

export const formatLikeNum = (likeNum: string) => {
    const num = Number(likeNum); // 문자열을 숫자로 변환

    if (num >= 1000 && num < 10000) {
        // 1000에서 9999 사이일 때, 천 단위로 변환
        return `${(num / 1000).toFixed(1)}천`;
    } else if (num >= 10000) {
        // 10000 이상일 때, 만 단위로 변환
        return `${(num / 10000).toFixed(2)}만`;
    } else {
        // 그 외의 경우에는 그대로 반환
        return likeNum;
    }
};