import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {getItemByKey} from "../../utils/indexedDB";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import useLoading from "../../hooks/useLoading";
import {searchApi} from "../../api/gatewayApi/other";
import {gtmPageLoad} from "../../api/gtm";
import CommonSearch from "../../components/Common/CommonSearch";
import SearchList from "../../components/Search/SearchList";
import CommonDivider from "../../components/Common/CommonDivider";

export interface SearchResults {
    sectionCount: string;
    sectionList: any[];
}

const ResultDetail: React.FC = () => {
    const location = useLocation();
    const [searchResults, setSearchResults] = useState<SearchResults>({sectionCount: "0", sectionList: []});
    const [memberStatus, setMemberStatus] = React.useState<string>('');
    const [showLoginPopup, setShowLoginPopup] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemberStatus = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        const fetchData = async () => {
            try {
                const response = await searchApi.getSearch({keyword: search}, 'v1');
                if (response.header.resultCode === '0000' && response.body) {
                    setSearchResults(response.body.searchResultList);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false)
                await gtmPageLoad({url: '/', contentGroup: '검색|검색 결과', moduleName: '', pageTitle: '검색 결과'});
            }
        }
        const search = searchParams.get('search')
        setSearch(search ?? '')
        fetchMemberStatus();
        fetchData();

    }, []);

    const getTitleByCode = (code: string) => {
        switch (code) {
            case "1000":
                return '이벤트';
            case "1001":
                return '유플투쁠';
            case "1002":
                return '제휴사';
            case "1003":
                return '쿠폰';
            case "1004":
                return '공지사항';
            case "1005":
                return '자주하는 질문';
            default:
                return '';
        }
    };

    const getThresholdByTitle = (title: string) => {
        switch (title) {
            case '이벤트':
            case '제휴사':
                return 5;
            case '유플투쁠':
                return 3;
            case '쿠폰':
            case '공지사항':
            case '자주하는 질문':
                return 2;
            default:
                return 0; // 기본값을 설정 (필요한 경우)
        }
    };

    const handleLoginCheck = (): boolean => {
        if (!memberStatus || memberStatus === '0') {
            setShowLoginPopup(true);
            return false;
        }
        return true;
    }

    const handleCouponIssue = (couponId: string) => {
        const newSearchResults = searchResults.sectionList.map((section: any) => {
            if (section.target === '1003') {
                return {
                    ...section,
                    itemList: section.itemList.map((item: any) => {
                        console.log('item : ', item);
                        console.log('couponId : ', couponId);
                        console.log('item.itemId : ', item.itemId);
                        console.log(item.itemId === couponId);
                        if (item.itemId == couponId) { // any 타입으로 비교할 경우 '==' 사용. '===' 사용 시 타입이 다르면 false
                            console.log('isIssuedCoupon : ', item.isIssuedCoupon);
                            return {...item, isIssuedCoupon: 'Y'};
                        }
                        return item;
                    }),
                };
            }
            return section;
        });
        setSearchResults({...searchResults, sectionList: newSearchResults});
    }
    const handleSearchResult = (result: SearchResults, search: string) => {
        setSearchResults(result);
        setSearch(search);
        navigate(`/result-detail?search=${search}`, {replace: true})
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonSearch
                onSearch={handleSearchResult}
                input={search}
            />

            <div style={{paddingTop: '3.6rem'}}>
                {searchResults.sectionList
                    .filter((section: any) => section.target !== "1003")
                    .map((section: any, index: number) => {
                    const title = getTitleByCode(section.target); // 숫자 코드를 문자열로 변환
                    return (
                        section.itemList &&
                        section.itemList.length > 0 &&
                        //TODO GW에서 타사쿠폰 필터링 되도록 검색 수정되는 경우 삭제해야하는 로직 - 쿠폰검색결과 비노출
                        title !== '쿠폰' && (
                            <div key={index}>
                                <SearchList
                                    title={title}
                                    items={section.itemList}
                                    threshold={getThresholdByTitle(title)}
                                    onCheckLogin={handleLoginCheck}
                                    onIssueCoupon={handleCouponIssue}
                                />
                                <CommonDivider/>
                            </div>
                        )
                    );
                })}
            </div>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>

        </>

    );
};

export default ResultDetail;
