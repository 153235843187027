import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import CommonPopup from "../../components/Common/CommonPopup";
import {addItem, getItemByKey, removeItemByKey} from "../../utils/indexedDB";
import AppInfoList from "../../components/Setting/AppInfoList";
import LogoutQuitButtons from '../../components/Setting/LogoutQuitButtons';
import {sendEventReturn, sendEventToAndroid, sendEventToIOS} from "../../api/nativeBridge";
import {settingApi} from "../../api/gatewayApi/settingAndTerms";
import {handlePromiseResult} from "../../api/api";
import {initialTermsPersonalInfo, RecvInfo, TermsPersonalInfo} from "./types";
import AgreementsDetail from "../Agreements/AgreementsDetail";
import {useSnackbar} from "../../provider/SnackbarProvider";
import AlarmSection from "../../components/Setting/AlarmSection";
import LockSection from "../../components/Setting/LockSection";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import UseAgreeListSection, {getGtmLocation} from "../../components/Setting/UseAgreeListSection";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {gtmCommonComp, gtmLogout, gtmPageLoad} from "../../api/gtm";

export interface SettingHandles {
    setBIO: (enabled: boolean) => void;
    setAppLock: (enabled: boolean) => void;
    handleChangePassword: () => void;
    handleResetLoginInfo: () => void;
    handleModifyInfo: () => void;
    getBIO: (enabled: boolean) => void;
    getAppLock: (enabled: boolean) => void;
    getNotification: (enabled: boolean, target?: string) => void;
    triggerKeypressBack: () => void;
}

const Setting2 = forwardRef<SettingHandles>((props, ref) => {
    const [openPopup, setOpenPopup] = useState<string | null>(null);
    const [userStatus, setUserStatus] = useState<string>("0");
    const [termsPersonalInfo, setTermsPersonalInfo] = useState<TermsPersonalInfo[]>([]);
    const [myVersion, setMyVersion] = useState<string>("");
    const [latestVersion, setLatestVersion] = useState<string>("");
    const [selectedTerm, setSelectedTerm] = useState<TermsPersonalInfo>(initialTermsPersonalInfo);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [appLock, setAppLock] = useState<boolean>(false);
    const [useBio, setUseBio] = useState<boolean>(false);
    const [appPush, setAppPush] = useState<boolean>(false);
    const [recvInfo, setRecvInfo] = useState<RecvInfo>({aprPushYn: "N", pushYn: "N", smsYn: "N"});
    const {showMessagePopup} = useMessagePopup();
    const location = useLocation();
    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        checkFirst();
        fetchData();
    }, []);

    useEffect(() => {
        if (recvInfo.pushYn === "Y" || recvInfo.smsYn === "Y") {
            const newRecvInfo = {...recvInfo, aprPushYn: "Y"};
            setRecvInfo(newRecvInfo)
        } else if (recvInfo.pushYn === "N" && recvInfo.smsYn === "N") {
            const newRecvInfo = {...recvInfo, aprPushYn: "N"};
            setRecvInfo(newRecvInfo)
        }
    }, [recvInfo.pushYn, recvInfo.smsYn]);

    useImperativeHandle(ref, () => ({
        setAppLock: async (enabled: boolean) => {
            setAppLock(enabled)
        },
        setBIO: async (enabled: boolean) => {
            setUseBio(enabled)
        },
        getBIO: async (enabled: boolean) => {
            setUseBio(enabled)
        },
        getAppLock: async (enabled: boolean) => {
            setAppLock(enabled)
        },
        getNotification: async (enabled: boolean, target?: string) => {
            // if (target && target === 'membership') { // aprPush on -> off 시에 appPush off 일 경우
            //     if (enabled) {
            //         setAppPush(enabled)
            //         await membershipsServiceApi.putAlarm({appPushYn: 'Y', reqType: "AP"}, 'v1')
            //         await membershipsServiceApi.putAlarm({aprPushYn: 'Y', smsYn: 'Y', pushYn: 'Y', reqType: "MS"}, 'v1')
            //     } else { // 디바이스 설정 안바꿈. 그럼 다시 토글 롤백
            //         setRecvInfo({aprPushYn: "N", pushYn: "N", smsYn: "N"})
            //     }
            // } else { // appPush : enabled 와 appPush 값을 조합해서 api 처리
            //     if (appPush) {
            //         if (enabled) { // appPush off -> on
            //             membershipsServiceApi.putAlarm({appPushYn: 'Y', reqType: "AP"}, 'v1')
            //         } else {
            //             setAppPush(false);
            //         }
            //     } else { // appPush on -> off
            //         if (enabled) { // 말 안듣고 디바이스 설정 안바꿈. 그럼 다시 토글 롤백
            //             setAppPush(true);
            //         } else {
            //             membershipsServiceApi.putAlarm({appPush경: 'N', reqType: "AP"}, 'v1')
            //         }
            //     }
            // }
        },
        handleChangePassword: () => {
            console.log('changePassword done')
            gtmCommonComp('비밀번호 변경');
        },
        handleResetLoginInfo: async () => {
            // const isSuccess = await clearAllDatabases();
            await removeItemByKey('memberId');
            await removeItemByKey('memberStatus');
            await removeItemByKey('customerName');
            await removeItemByKey('isVIP');
            await removeItemByKey('userStatus');
            await gtmLogout();
            sendEventToIOS('APP_FINISH', {});
            sendEventToAndroid('APP_FINISH', {});
            setOpenPopup(null);
        },
        handleModifyInfo: async () => {
            navigate('/setting/modify-user-info');
        },
        triggerKeypressBack() {
            if (isDetailOpen) {
                setIsDetailOpen(false);
            } else {
                navigate(-1);
            }
        }
    }));

    const handleSubmit = async (termId: string) => {
        const reverseTermYn = (termYn: string) => {
            return termYn === 'Y' ? 'N' : 'Y';
        }

        // termId에 해당하는 약관의 체크 상태를 반대로 변경하고 API 호출
        let updatedTerms = termsPersonalInfo.map(term =>
            term.termId === termId ? {...term, termYn: term.termYn === 'Y' ? 'N' : 'Y'} : term
        );

        const selectedTerm = updatedTerms.find(term => term.termId === termId);
        let snackbarText = '';
        console.log('selectedTerm', selectedTerm)

        if (selectedTerm) {
            try {
                // 약관 상태를 서버로 전송하는 API 호출
                await settingApi.putTermsPersonalInfo({termId, termYn: selectedTerm.termYn}, 'v1');
                // gtm

                if (selectedTerm.termsType === '125001') {
                    await addItem('marketingAgreeYn', selectedTerm.termYn);
                    (window as any).digitalData.userInfo.marketing_agreement = selectedTerm.termYn;
                    await gtmCommonComp('마케팅 정보 수집/이용 동의 변경', selectedTerm.termYn);
                }


                if (selectedTerm.termsType === '125001' && selectedTerm.termYn === 'N') { // 125001 철회시에 125003도 같이 철회
                    const childTerm = termsPersonalInfo.find(term => term.termsType === '125003');
                    if (childTerm?.termYn === 'Y') {
                        await settingApi.putTermsPersonalInfo({termId: childTerm.termId, termYn: 'N'}, 'v1');
                        updatedTerms = updatedTerms.map(term => term.termsType === '125003' ? {
                            ...term,
                            termYn: 'N'
                        } : term);
                        await addItem('adAgreeYn', selectedTerm.termYn);
                        (window as any).digitalData.userInfo.ad_agreement_app = selectedTerm.termYn;
                        await gtmCommonComp('광고수신 알림 동의 변경', selectedTerm.termYn);
                    }
                }

                await fetchAlarmData()
                setTermsPersonalInfo(updatedTerms); // 성공적으로 업데이트된 상태를 반영

                if (selectedTerm.termsType === '125004') {
                    await addItem('geoAgreeYn', selectedTerm.termYn);
                    (window as any).digitalData.userInfo.geo_service_agreement = selectedTerm.termYn;
                    await gtmCommonComp('위치기반 서비스 동의 변경', selectedTerm.termYn);
                }


                if (selectedTerm.termsType === '125003') {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, '0'); // 월을 2자리로 포맷
                    const day = String(today.getDate()).padStart(2, '0'); // 일을 2자리로 포맷

                    if (selectedTerm.termYn === 'Y') {
                        snackbarText = `${year}년 ${month}월 ${day}일에 LG U+의 U+멤버십 앱 광고 수신에 동의했어요.`;
                    } else {
                        snackbarText = `${year}년 ${month}월 ${day}일에 LG U+의 U+멤버십 앱 광고 수신에 동의하지 않았어요.`;
                    }
                    await addItem('adAgreeYn', selectedTerm.termYn);
                    (window as any).digitalData.userInfo.ad_agreement_app = selectedTerm.termYn;
                    await gtmCommonComp('광고수신 알림 동의 변경', selectedTerm.termYn);
                }

                if (selectedTerm.termsType === '125006') {
                    (window as any).digitalData.userInfo.third_party_agreement = selectedTerm.termYn;
                    await gtmCommonComp('제3자 정보 제공동의 변경', selectedTerm.termYn);
                }

            } catch (error) {
                console.error("Error updating term status:", error);
            }
        }
        setIsDetailOpen(false); // 세부 항목 창 닫기

        // Snackbar 표시
        if (snackbarText) {
            showSnackbar(snackbarText)
        }
    };
    const fetchAlarmData = async () => {
        try {
            const response = await membershipsServiceApi.getAlarm('v1')
            setRecvInfo(response.body.recvInfo)
            setAppPush(response.body.recvInfo.appPushYn === 'Y')
        } catch (e) {
            console.log(e)
        }

    }
    const fetchData = async () => {
        const platform = await getItemByKey('platform');
        const [termsPersonalInfo, version, alarm] = await Promise.allSettled([
            settingApi.getTermsPersonalInfo({}, 'v1'),
            settingApi.getVersion({osType: platform}, 'v1'),
            membershipsServiceApi.getAlarm('v1')
        ]);
        setTermsPersonalInfo(handlePromiseResult(termsPersonalInfo)?.termsList || []);
        setLatestVersion(handlePromiseResult(version, {version: ''}).version);
        const resAlarm = handlePromiseResult(alarm, {
            recvInfo: {appPushYn: 'N', aprPushYn: "N", pushYn: "N", smsYn: "N"}
        });
        setRecvInfo(resAlarm.recvInfo);
        setAppPush(resAlarm.recvInfo.appPushYn === 'Y');

        await gtmPageLoad({url: '/setting', contentGroup: '설정', moduleName: '', pageTitle: '설정'});
    };

    const checkFirst = async () => {
        sendEventReturn('GET_NOTIFICATION', {})
        sendEventReturn('GET_BIO_AUTH', {})
        sendEventReturn('GET_APP_LOCK', {})
        const myVersion = await getItemByKey('appVersion');
        const status = await getItemByKey('memberStatus');

        setMyVersion(myVersion);
        if (status) setUserStatus(status);
    };

    const handlePopupSubmit = (popupType: string) => {
        switch (popupType) {
            case 'logout':
                sendEventReturn('RESET_LOGIN_INFO', {});
                break;
            case 'membershipDiscount':
                break;
            default:
                setOpenPopup(null);
                break;
        }
    };

    const handleTermClick = async (term: TermsPersonalInfo) => {
        setSelectedTerm(term);
        setIsDetailOpen(true);  // Bottom Sheet 열기
        await gtmPageLoad({
            url: '/setting',
            contentGroup: `설정|개인정보 이용|${getGtmLocation(term.termsType)}`,
            moduleName: '',
            pageTitle: term.termName
        });
    };

    const renderPopup = () => {
        switch (openPopup) {
            case 'logout':
                return <CommonPopup description={"U+멤버십에 로그인 하려면\n본인인증을 다시 해야해요.\n로그아웃하시겠어요?"}
                                    submitText="로그아웃 후 앱 종료"
                                    cancelText="취소"
                                    onSubmit={() => handlePopupSubmit('logout')}
                                    onClose={() => setOpenPopup(null)}/>;
            default:
                return null;
        }
    };

    return (
        <>
            {!isDetailOpen && (
                <>
                    <CommonLeftIconHeader title={"설정"}/>

                    {/* 잠금 설정 */}
                    <LockSection appLock={appLock} setAppLock={setAppLock} useBio={useBio} setUseBio={setUseBio}/>

                    {/* 구분선 */}
                    <div style={{margin: "12px 0 8px 0"}}>
                        <CommonDivider/>
                    </div>

                    {/* 알림 */}
                    <AlarmSection appPush={appPush} setAppPush={setAppPush} recvInfo={recvInfo}
                                  setRecvInfo={setRecvInfo} memberStatus={userStatus}/>

                    <div style={{margin: "12px 0 8px 0"}}>
                        <CommonDivider/>
                    </div>

                    {/* 개인정보 이용 동의 */}
                    {userStatus !== '3' && userStatus !== '4' && (
                        <>
                            <UseAgreeListSection termsPersonalInfoList={termsPersonalInfo}
                                                 onTermClick={handleTermClick}/>
                            <div style={{marginBottom: "8px"}}>
                                <CommonDivider/>
                            </div>
                        </>
                    )}

                    {/* 내정보, 앱정보*/}
                    <AppInfoList currentVersion={myVersion} latestVersion={latestVersion}/>

                    {/* 로그아웃 / 회원탈퇴*/}
                    <LogoutQuitButtons
                        onLogoutClick={() => setOpenPopup('logout')}
                        onQuitClick={() => navigate('/setting/quit')}
                    />
                    {renderPopup()}
                </>
            )}

            {isDetailOpen && (
                <AgreementsDetail
                    selected={selectedTerm.termYn === 'Y'}
                    type={'exist'}
                    termId={selectedTerm.termId}
                    tempTermTitle={selectedTerm.termName}
                    handleSubmit={() => handleSubmit(selectedTerm.termId)}
                    handleClose={() => setIsDetailOpen(false)}
                />
            )}
        </>
    );
})
export default Setting2;
