import React, {useEffect, useState} from 'react';
import CommonDivider from "../../components/Common/CommonDivider";
import SectionHeader from "../../components/MobilePayment/SectionHeader";
import SectionPaymentInfo from "../../components/MobilePayment/SectionPaymentInfo";
import SectionListCustom from "../../components/MobilePayment/SectionListCustom";
import BS_List from "../../components/Common/BS_List";
import {Button, Text} from "@lguuxe/2024_designsystem_uni";
import paymentApi from "../../api/gatewayApi/payment";
import {handlePromiseResult} from "../../api/api";
import useLoading from "../../hooks/useLoading";
import {initLimit, LimitInfo, PaymentBlock} from "./types";
import {gtmPageLoad} from "../../api/gtm";

interface SelectedDate {
    id: string;
    year: number;
    month: number;
}

const MobilePayment: React.FC = () => {
    const [setting, setSetting] = useState<PaymentBlock>({payBlckYn: "N", autoPayBlckYn: "N"});
    const [limit, setLimit] = useState<LimitInfo>(initLimit);
    const [historyList, setHistoryList] = useState<any[]>([]);
    const [isMonthBsOpen, setIsMonthBsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<{ id: string, label: string }[]>([]);
    const today = new Date();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState<SelectedDate>({
        id: '1',
        year: today.getFullYear(),
        month: today.getMonth() + 1
    });

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: '/mobile-pay',
                contentGroup: '마이|휴대폰 결제',
                moduleName: '',
                pageTitle: '휴대폰결제'
            });
        }
        gtm();

        const generateMonthList = () => {
            const list = [];

            for (let i = 0; i < 12; i++) {
                // i개월 전의 날짜 계산
                const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 두 자리 숫자 형식으로 변환

                // 리스트에 추가
                list.push({
                    id: `${i + 1}`,
                    label: `${year}년 ${month}월`
                });
            }
            return list;
        };

        const monthList = generateMonthList();
        setDataList(monthList);
    }, []);

    // YYYYMM 형식으로 변환하는 함수
    const getFormattedDate = (year: number, month: number) => {
        const formattedMonth = month < 10 ? `0${month}` : month.toString();
        return `${year}${formattedMonth}`;
    };

    const handleSelectedMonth = async (id: string) => {
        // dataList에서 id 값이 일치하는 항목을 찾음
        const selectedData = dataList.find(item => item.id === id);

        if (selectedData) {
            // label에서 연도와 월을 추출
            const match = selectedData.label.match(/(\d{4})년 (\d{1,2})월/);

            if (match) {
                const selectedYear = parseInt(match[1], 10);
                const selectedMonth = parseInt(match[2], 10);
                setSelectedDate({id: id, year: selectedYear, month: selectedMonth});

                // 새로운 연도와 월로 데이터 가져오기
                try {
                    const historyResponse = await paymentApi.getUseHistory({
                        date: getFormattedDate(selectedYear, selectedMonth)
                    }, 'v1');

                } catch (error) {
                    console.error('Error fetching use history:', error);
                }
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedDate]);

    const fetchData = async () => {
        try {
            const [setting, limit, history] = await Promise.allSettled([
                paymentApi.getSetting('v1'),
                paymentApi.getLimit({}, 'v1'),
                paymentApi.getUseHistory({date: getFormattedDate(selectedDate.year, selectedDate.month)}, 'v1'),
            ]);

            setSetting(handlePromiseResult(setting) || null);
            setLimit(handlePromiseResult(limit) || null);
            setHistoryList(handlePromiseResult(history)?.useHistoryList || []);
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }

    };

    return (
        <>
            {useLoading(isLoading)}
            <SectionHeader/>

            <SectionPaymentInfo setting={setting} limit={limit}/>
            <CommonDivider/>

            <div
                style={{
                    padding: "12px 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text typo="font_heading_5B" color="color_text_neutral_6">사용 내역</Text>
                <Button
                    color="neutral"
                    rightIcon="chevron_down_small_line"
                    fill="text"
                    onClick={() => setIsMonthBsOpen(true)}
                >
                    <Text typo="font_heading_5Sb"
                          color="color_text_neutral_4">{selectedDate.year}년 {selectedDate.month}월</Text>
                </Button>
            </div>

            <SectionListCustom historyList={historyList}/>

            <BS_List
                title='월 선택'
                dataList={dataList}
                onClose={() => setIsMonthBsOpen(false)}
                onSelected={handleSelectedMonth}
                isOpen={isMonthBsOpen}
                checkedItem={selectedDate.id}
            />

        </>
    );
};

export default MobilePayment;
