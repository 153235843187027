import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {termsApi} from "../../../api/gatewayApi/settingAndTerms";
import {Agreement} from "../../Agreements/types";
import {List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import AgreementsDetail from "../../Agreements/AgreementsDetail";
import {useNavigate} from "react-router-dom";
import {gtmPageLoad} from "../../../api/gtm";


export interface UseTermRef {
    triggerKeypressBack: () => void;
}

const UseTerms = forwardRef<UseTermRef>((props, ref) => {
    const [agreementList, setAgreementList] = useState<Agreement[]>([]);
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
        async triggerKeypressBack() {
            isDetailOpen ? setIsDetailOpen(false) : navigate(-1)
        }
    }));

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: '/help/terms',
                contentGroup: `도움말|이용약관`,
                moduleName: '',
                pageTitle: '이용약관'
            });
        }
        gtm();

        const fetchData = async () => {
            const response = await termsApi.getAgreements({}, 'v1');
            setAgreementList(response.body.termsList);
            console.log(response.body)
        };
        fetchData();
    }, []);

    const handleTermClick = (termId: string) => {
        setSelectedTermId(termId);
        setIsDetailOpen(true);  // Bottom Sheet 열기
    };

    return (
        <>
            {!isDetailOpen &&
                <><CommonLeftIconHeader title={'이용약관'}/>
                    <div style={{paddingBottom: "12px"}}>
                        <List
                            fill="none"
                            name="list_name"
                            size="medium"
                            type="view"
                            weight="default"
                            items={agreementList.map(item => ({
                                id: item.termId,
                                leftSlot: [
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{
                                            title: <>
                                                <Text typo="font_body_1Sb">{item.termName}</Text>
                                            </>,
                                        }}
                                        weight="bolder"/>
                                ],
                                linkIconOnlyId: "empty",
                                onClick: () => handleTermClick(item.termId),
                                dataAttributes: {
                                    'data-gtm-event-name': 'all_click',
                                    'data-gtm-event-category': '클릭',
                                    'data-gtm-event-action': `도움말|이용약관 - 링크 클릭`,
                                    'data-gtm-event-label': `컨텐츠 : ${item.termName}`,
                                    'data-gtm-click-url': '',
                                    'data-gtm-click-location': `도움말|이용약관`,
                                    'data-gtm-click-direction': '내부',
                                    'data-gtm-click-text': item.termName
                                }
                            }))}/>
                    </div>
                </>}

            {isDetailOpen && <AgreementsDetail
                selected={agreementList.find(term => term.termId === selectedTermId)?.selected || false}
                type={'exist'}
                tempTermTitle={agreementList.find(term => term.termId === selectedTermId)?.termName}
                termId={selectedTermId || ""}
                handleClose={() => setIsDetailOpen(false)}
            />}
        </>

    );
});

export default UseTerms;