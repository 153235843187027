import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CommonDivider from '../../components/Common/CommonDivider';
import AllIconHeader from '../../components/Common/AllIconHeader';
import PartnerCarousel from '../../components/Partner/PartnerCarousel';
import {partnerApi} from '../../api/gatewayApi/partnerAndAffiliate-service';
import {
    CategoryItem,
    initSelectedPartnerCategory,
    LikedPartners,
    SelectedPartnerCategory,
    SortItem,
    TotalPartners
} from './types';

import {ENVIROMENT, handlePromiseResult} from "../../api/api";
import '../../css/style.css';
import CategoryTabs from "../../components/Partner/CategoryTabs";
import PartnerList from "../../components/Partner/PartnerList";
import SortFilter from "../../components/Partner/SortFilter";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import useDebounce from "../../hooks/useDebounce";
import useLoading from "../../hooks/useLoading";
import {getItemByKey} from "../../utils/indexedDB";
import InfiniteScroll from "react-infinite-scroll-component";
import BarcodeLoading from "../../components/Barcode/BarcodeLoading";
import {gtmDeleteComp, gtmFavoriteComp, gtmPageLoad} from "../../api/gtm";

const initialLikedPartners: LikedPartners = {
    ptnList: [],
};

interface CardInfo {
    longTermCode: string; // NG1:2년이상, NG2:5년이상, NG3:10년이상
    longTermName: string;
    youthYn: string;
    youthName: string;
}

const initCardInfo: CardInfo = {
    longTermCode: '',
    longTermName: '',
    youthYn: '',
    youthName: '',
};

const initCategoryList = {
    categoryList: [],
};

const initPartners: TotalPartners = {
    totalCount: '0',
    partnerList: [],
};

interface SortState {
    sortType: string;
    isUsable: boolean;

}

const sortList: SortItem[] = [
    {
        id: '1',
        label: '인기순',
    },
    {
        id: '2',
        label: '최신순',
    },
    {
        id: '3',
        label: '가나다순',
    },
];

const Partners = () => {
    const navigate = useNavigate();
    const [likedPartners, setLikedPartners] = useState<LikedPartners>(initialLikedPartners);
    const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
    const [partners, setPartners] = useState<TotalPartners>(initPartners);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedCategory, setSelectedCategory] = useState<SelectedPartnerCategory>(initSelectedPartnerCategory);
    const [cardInfo, setCardInfo] = useState<CardInfo>(initCardInfo);
    const [sortState, setSortState] = useState<SortState>({sortType: 'L', isUsable: false}); // 인기순으로 시작
    const isFirstRender = useRef(true);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true); // 무한 스크롤에서 더 가져올 데이터가 있는지 여부
    const [memberStatus, setMemberStatus] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setMemberStatus(await getItemByKey('memberStatus'));
                const [likedPartners, categories, simpleCardInfo] = await Promise.allSettled([
                    partnerApi.getPartnersLike({orderType: ''}, 'v1'),
                    partnerApi.getCategories('v1'),
                    membershipsServiceApi.getSimpleCardInfo('v1'),
                ]);
                setLikedPartners(handlePromiseResult(likedPartners, initialLikedPartners));
                let categoryList = handlePromiseResult(categories, initCategoryList).categoryList;
                // 전체 카테고리 추가
                categoryList = [{categoryId: '', categoryName: '전체', categoryType: ''}, ...categoryList];

                setCategoryList(categoryList);
                setCardInfo(handlePromiseResult(simpleCardInfo, initCardInfo));
            } catch (error) {
                console.log('Failed to fetch :', error);
            } finally {
                setIsLoading(false);
                window.scrollTo(0, 0.1);
                await gtmPageLoad({
                    url: '/Partners',
                    contentGroup: '혜택|제휴사',
                    moduleName: '',
                    pageTitle: '전체 제휴사'
                });
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        fetchPartners(1);

    }, [selectedCategory, sortState]);

    const fetchPartners = async (pageParam?: number) => {
        const currentPage = pageParam || page
        console.log('fetchPartners page: depth1 :', selectedCategory.depth1, 'page:', currentPage, 'sort: ', sortState.sortType);
        try {
            const requestParam: any = {
                cateDepth1: selectedCategory.depth1,
                cateDepth2: selectedCategory.depth2,
                cateDepth3: selectedCategory.depth3,
                sort: sortState.sortType, // 빈값: 최신, L: 인기, A: 가나다
                pageNo: currentPage
            }
            if (sortState.isUsable) {
                requestParam.cardLevel = memberStatus !== '5' ? await getItemByKey('cardLevel') : '';
                requestParam.longTermCode = memberStatus !== '5' ? cardInfo.longTermCode : '';
                requestParam.youthYn = memberStatus !== '5' ? cardInfo.youthYn : '';
            }

            partnerApi.getPartners(requestParam, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setPartners(res.body);
                        setPage(2);
                    } else {
                        const newPartnerList = partners.partnerList.concat(res.body.partnerList);
                        setPartners({...res.body, partnerList: newPartnerList});
                        setPage(currentPage + 1);
                    }

                    if (res.body.partnerList.length === 0) {
                        setHasMore(false);
                    }
                }
            });

        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    }

    const handleCategoryChange = (selectedCategory: SelectedPartnerCategory) => {
        if (selectedCategory.categoryName === '지역' && selectedCategory.depth2 === '' && selectedCategory.depth3 === '') {
            setSelectedCategory({
                categoryName: selectedCategory.categoryName,
                depth1: selectedCategory.depth1,
                depth2: ENVIROMENT === 'prod' ? '11' : '1022',
                depth3: ''
            });
        } else {
            setSelectedCategory(selectedCategory);
        }
        setPage(1);
        setHasMore(true); // 카테고리 변경 시 더 가져올 데이터가 있다고 초기화
    }

    const handleSortChange = useDebounce((sortId: string, isUsable: boolean) => {
        let sortType = '';
        switch (sortId) {
            case '1':
                sortType = 'L';
                break;
            case '2':
                sortType = '';
                break;
            case '3':
                sortType = 'A';
                break;
        }

        setSortState({sortType, isUsable});
        setPage(1);
        setHasMore(true);
    });

    const handleLike = (ptnId: string, nowIsLike: boolean) => {
        if (nowIsLike) {
            const newPartnerList = partners.partnerList.map((item) => (item.ptnId === ptnId ? {
                ...item,
                isLike: 'N',
                likeNum: (Number(item.likeNum) - 1).toString()
            } : item));

            setPartners({...partners, partnerList: newPartnerList});
            setLikedPartners({ptnList: likedPartners.ptnList.filter((item) => item.ptnId !== ptnId)});

            const partnerToRemove = partners.partnerList.find((item) => item.ptnId === ptnId);
            const ptnName = partnerToRemove?.ptnName || '';
            gtmDeleteComp('제휴사 즐겨찾기 삭제', ptnName);
        } else { // 좋아요 제휴처 목록에 해당 제휴처 추가, 제휴처 목록에서 좋아요 상태 변경
            const newPartnerList = partners.partnerList.map((item) => (item.ptnId === ptnId ? {
                ...item,
                isLike: 'Y',
                likeNum: (Number(item.likeNum) + 1).toString()
            } : item));
            setPartners({...partners, partnerList: newPartnerList});
            partners.partnerList.filter((item) => item.ptnId === ptnId).forEach((item) => {
                setLikedPartners({ptnList: [...likedPartners.ptnList, item]});
                gtmFavoriteComp(item.ptnName);
            });
        }
    }

    return (
        <>
            {useLoading(isLoading)}
            <div
                id='partners'
                style={{
                    width: '100%',
                    // height: '100vh',
                }}
            >
                <InfiniteScroll next={fetchPartners} hasMore={hasMore}
                                loader={<BarcodeLoading/>}
                                dataLength={Number(partners.partnerList.length)}
                                scrollableTarget={"window"}
                >
                    <AllIconHeader title='전체 제휴사' isNotiIcon={false}/>

                    <PartnerCarousel partnerList={likedPartners.ptnList}/>
                    <CommonDivider/>

                    <div style={{
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                    }}>
                        <CategoryTabs title='혜택 카테고리' itemList={categoryList} onItemChange={handleCategoryChange}/>
                        <SortFilter sortList={sortList} onSortChange={handleSortChange}
                                    totalCount={partners.totalCount}/>

                        <PartnerList partners={partners} onClickLike={handleLike}
                                     categoryName={selectedCategory.categoryName} isUsable={sortState.isUsable}/>
                    </div>
                </InfiniteScroll>
            </div>
        </>
    );
};

export default Partners;
