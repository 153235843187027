import React, { useEffect, useState } from 'react';
import { Button, Image, List, Tag, TextSet } from '@lguuxe/2024_designsystem_uni';
import upltwoplIcon from '../../images/icon/1_grade/membership_48=vvip.svg';
import upltwoplIcon2 from '../../images/icon/1_grade/membership_48=vip.svg';
import upltwoplIcon3 from '../../images/icon/1_grade/membership_48=excellent.svg';
import upltwoplIcon4 from '../../images/icon/1_grade/membership_48=general.svg';
import uptpLongTermIcon from '../../images/icon/1_grade/customer_48=10y.svg';
import uptpLongTermIcon2 from '../../images/icon/1_grade/customer_48=5y.svg';
import uptpLongTermIcon3 from '../../images/icon/1_grade/customer_48=2y.svg';
import uptpUthIcon from '../../images/icon/1_grade/youth_48.svg';
import { useSnackbar } from '../../provider/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { getItemByKey } from '../../utils/indexedDB';
import { CouponInfo } from '../../pages/UPTP/types';
import { isEligibleForBenefit } from '../../utils/helper';
import { useMessagePopup } from '../../provider/MessagePopupProvider';
import { sendEventReturn } from '../../api/nativeBridge';
import { decodeHtmlEntities } from '../../utils/textUtils';
import useDebounce from '../../hooks/useDebounce';

interface Tag {
    colorToken: string;
    label: string;
    size: 'xSmall' | 'small' | 'medium';
    style: 'solid' | 'outline';
    fill: 'solid' | 'outline';
}

// SectionListProps 인터페이스 추가
interface SectionListProps {
    proTarget: string;
    cpnInfoList: CouponInfo[];
    proId: string;
    timestamp: string;
}

// SectionList 컴포넌트 수정
const SectionList: React.FC<SectionListProps> = ({ proTarget, cpnInfoList, proId, timestamp }) => {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { showMessagePopup } = useMessagePopup();
    const currentTimestamp = timestamp;
    const [userMemLevel, setUserMemLevel] = useState<string>('150003');
    const [longTermCode, setLongTermCode] = useState<'NG1' | 'NG2' | 'NG3' | ''>('');
    const [checkYouth, setCheckYouth] = useState<boolean>(true);
    useEffect(() => {
        const fetchUserMemLevel = async () => {
            const cardLevel = await getItemByKey('cardLevel');
            const memLevel = cardLevelToMemLevel[cardLevel];
            if (proTarget === '137003') {
                setCheckYouth((await getItemByKey('youthYn')) === 'Y');
            }
            setUserMemLevel(memLevel);
            setLongTermCode(await getItemByKey('longTermCode'));
        };
        fetchUserMemLevel();
    }, []);

    const cardLevelToMemLevel: { [key: string]: string } = {
        '6': '150001', // VVIP
        '8': '150002', // VIP
        '7': '150003', // 우수
    };

    const memLevelOrder = ['150001', '150002', '150003']; // VVIP, VIP, 우수 순서
    const CouponItem = ({
        icon = '',
        eyebrow = '',
        title = '',
        buttonText = '',
        buttonDisabled = false,
        buttonOnClick = () => {},
        buttonColor = 'primary',
        tagList = [] as Tag[],
    }) => ({
        leftSlot: [
            <Image alt='coupon icon' ratio='1:1' src={icon} width='48' key='1' />,
            <TextSet
                subComponent='Body_1'
                text={{
                    eyebrow: eyebrow,
                    title: decodeHtmlEntities(title),
                    description: proTarget === '137004' ? <Tag item={tagList} /> : undefined,
                }}
                key='2'
            />,
        ],
        rightSlot: [
            <Button size='small' key='3' onClick={buttonOnClick} disabled={buttonDisabled} color={buttonColor}>
                {buttonText}
            </Button>,
        ],
    });

    const setToElevenAM = (dateTimeString: string): string => {
        const datePart = dateTimeString.split(' ')[0]; // "2024-10-28" 부분만 추출
        return `${datePart} 11:00:00`;
    };

    const issueCoupon = useDebounce(async (coupon: CouponInfo) => {
        const now = new Date(currentTimestamp);
        const expStartDate = new Date(setToElevenAM(currentTimestamp));

        if (now.getTime() < expStartDate.getTime()) {
            showMessagePopup(`${expStartDate.getHours() % 12 || 12}시부터 다운 가능해요`);
        } else {
            // 타사 여부 체크
            const telcoCode = await getItemByKey("telcoCode");
            const memberStatus = await getItemByKey("memberStatus")
            if (telcoCode !== "L") {
                showMessagePopup('U+모바일 고객만 이용하실수 있어요');
                return;
            }
            // 유저 데이터 정상 여부 체크
            const cardLevel = await getItemByKey('cardLevel');
            const youthYn = await getItemByKey('youthYn');
            const gender = (await getItemByKey('gender')) || 'A';
            if (!cardLevel || !youthYn || !gender) {
                showMessagePopup('일시적인 오류에요');
                return;
            }

            sendEventReturn('WAITING_LINE_POPUP', {
                id: `${proId}|${coupon.proCpnId}|${coupon.cpnId}`,
                target: 'coupon',
            });
        }
    });

    // 등급에 따른 아이콘 매핑
    const getIcon = (memLevel: string, longMemLevel: string) => {
        if (proTarget === '137001' || proTarget === '137004') {
            // 멤버십형, 통합형
            switch (memLevel) {
                case '150001':
                    return upltwoplIcon;
                case '150002':
                    return upltwoplIcon2;
                case '150003':
                    return upltwoplIcon3;
                default:
                    return upltwoplIcon4;
            }
        } else if (proTarget === '137002') {
            // 장기고객 기준
            switch (longMemLevel) {
                case '140001':
                    return uptpLongTermIcon;
                case '140002':
                    return uptpLongTermIcon2;
                case '140003':
                    return uptpLongTermIcon3;
                default:
                    return uptpLongTermIcon3;
            }
        } else if (proTarget === '137003') {
            // 유쓰
            return uptpUthIcon;
        }
    };

    const getText = (memLevel: string, longMemLevel: string) => {
        if (proTarget === '137001' || proTarget === '137004') {
            // 멤버십형, 통합형
            switch (memLevel) {
                case '150001':
                    return 'VVIP';
                case '150002':
                    return 'VIP';
                case '150003':
                    return '우수';
                default:
                    return '일반';
            }
        } else if (proTarget === '137002') {
            // 장기고객 기준
            switch (longMemLevel) {
                case '140001':
                    return '10년이상';
                case '140002':
                    return '5년이상';
                case '140003':
                    return '2년이상';
                default:
                    return '일반';
            }
        } else if (proTarget === '137003') {
            // 유쓰
            return '유쓰';
        }
    };

    const getLongMemTag = (longMemLevel: string) => {
        switch (longMemLevel) {
            case '140001':
                return {
                    colorToken: 'blue_low',
                    label: '10년이상',
                    size: 'xSmall',
                    style: 'solid',
                    fill: 'solid',
                };
            case '140002':
                return {
                    colorToken: 'blue_low',
                    label: '5년이상',
                    size: 'xSmall',
                    style: 'solid',
                    fill: 'solid',
                };
            case '140003':
                return {
                    colorToken: 'blue_low',
                    label: '2년이상',
                    size: 'xSmall',
                    style: 'solid',
                    fill: 'solid',
                };
        }
    };

    const getLowestMemLevel = (memLevel: string) => {
        const res = memLevel
            .split(',')
            .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
            .pop();
        if (!res) {
            return '';
        }
        return res;
    };

    // cpnInfoList를 쿠폰 항목으로 변환
    const items = cpnInfoList.map((coupon, index): any => {
        // 쿠폰의 memLevel 가져오기
        console.log(coupon);
        const couponMemLevel = getLowestMemLevel(coupon.memLevel);
        const couponLongMemLevel = getLowestMemLevel(coupon.longMemLevel);

        // 혼합형 (137004)의 경우 장기고객과 유쓰 태그를 추가해준다
        const tagList: any[] = [];
        if (couponLongMemLevel && getLongMemTag(couponLongMemLevel)) {
            tagList.push(getLongMemTag(couponLongMemLevel));
        }
        if (coupon.ageStartDate !== null || coupon.ageEndDate !== null) {
            tagList.push({ colorToken: 'green_low', label: '유쓰', size: 'xSmall', style: 'solid', fill: 'solid' });
        }
        console.log(tagList);

        // 회원의 등급과 쿠폰 등급 비교 (회원 등급이 쿠폰 등급보다 높거나 같아야 함)
        // 현재는 웹에서 따로 비교는 하지 않음. 서버 응답값으로 판단하게 변경됨
        const userLevelIndex = memLevelOrder.indexOf(userMemLevel);
        const couponLevelIndex = memLevelOrder.indexOf(couponMemLevel);
        const isEligible = userLevelIndex <= couponLevelIndex;

        // 날짜 비교를 위한 현재 시간과 쿠폰의 시작/종료 날짜 파싱
        const now = new Date(currentTimestamp);
        const expStartDate = new Date(coupon.expStartDate);
        const expEndDate = new Date(coupon.expEndDate);

        const longTerm = coupon.longMemLevel;
        const longTermYn = isEligibleForBenefit(longTermCode, longTerm);

        let buttonText = '';
        let buttonDisabled = false;
        let buttonColor = 'primary';
        if (now < expStartDate) {
            // 시작 전인 경우
            const startDateOnly = new Date(expStartDate.setHours(0, 0, 0, 0));
            const nowDateOnly = new Date(now.setHours(0, 0, 0, 0));

            const diffDays = Math.ceil((startDateOnly.getTime() - nowDateOnly.getTime()) / (1000 * 60 * 60 * 24));
            if (diffDays === 0) {
                buttonText = '쿠폰받기';
                buttonDisabled = false;
                buttonColor = 'primary';
            } else {
                buttonText = `D-${diffDays}`;
                buttonDisabled = true;
                buttonColor = 'neutral';
            }
        } else if (now > expEndDate) {
            // 종료된 경우
            buttonText = '종료';
            buttonDisabled = true;
            buttonColor = 'primary';
            // } else if (!isEligible && !longTermYn && checkYouth) {
            //     // 등급 조건이 맞지 않는 경우
            //     buttonText = '조건 안됨';
            //     buttonDisabled = true;
            //     buttonColor = 'primary';
        } else {
            // 쿠폰 받기가 가능한 경우
            buttonText = '쿠폰받기';
            buttonDisabled = false;
            buttonColor = 'primary';
        }

        return CouponItem({
            icon: getIcon(couponMemLevel, couponLongMemLevel),
            eyebrow: getText(couponMemLevel, couponLongMemLevel),
            title: coupon.cpnName,
            buttonText: buttonText,
            buttonDisabled: buttonDisabled,
            buttonColor: buttonColor,
            buttonOnClick: () => issueCoupon(coupon),
            tagList: tagList,
        });
    });

    return (
        <>
            <List isSlotClickable={true} fill='none' items={items} type='view' />
        </>
    );
};

export default SectionList;
