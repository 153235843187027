import React from 'react';
import {List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from "react-router-dom";

const extractIndex = (str: string): string => {
    const match = str.match(/\d+/);
    return match ? match[0] : '0';
};

interface MyMenuProps {
    memberStatus: string;
    userStatus: string;
}

const MyMenu: React.FC<MyMenuProps> = ({memberStatus, userStatus}) => {

    const navigate = useNavigate();

    const handleClick = (id: string) => {
        if (id === '0') {
            navigate('/my/coupon');
        } else if (id === '1') {
            console.log('휴대폰 결제 - 휴대폰 결제 화면으로 이동');
            navigate('/mobile-pay');
        }
    };

    // 메뉴 항목 정의
    const menuItemList = [
        {id: '0', title: '내 쿠폰'},
        ...(memberStatus === '1' || memberStatus === '2' && userStatus !== '49' ? [{id: '1', title: '휴대폰 결제'}] : [])
    ];

    // map을 통해 menuItems를 생성
    const menuItems = menuItemList.map((item) => ({
        leftSlot: [
            <TextSet
                subComponent='Body_1'
                text={{
                    title: (
                        <>
                            <Text typo='font_body_1Sb'>{item.title}</Text>
                        </>
                    ),
                }}
            />,
        ],
        linkIconOnlyId: "empty",
        onClick: () => handleClick(item.id),
        dataAttributes: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': '내 메뉴 - 링크 클릭',
            'data-gtm-event-label': `컨텐츠 : ${item.title}`,
            'data-gtm-click-url': '',
            'data-gtm-click-location': '내 메뉴',
            'data-gtm-click-direction': '내부',
            'data-gtm-click-text': `${item.title}`,
        }
    }));

    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>내 메뉴</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div style={{paddingBottom: '12px'}}>
                <List
                    id='myMenu'
                    fill='none'
                    name='listName'
                    size='medium'
                    type='view'
                    weight='default'
                    items={menuItems}
                />
            </div>
        </>
    );
};

export default MyMenu;