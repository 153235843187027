import React, {CSSProperties, useState} from 'react';
import {Box, TextSet, Toggle} from "@lguuxe/2024_designsystem_uni";
import CommonPopup from "../Common/CommonPopup";
import paymentApi from "../../api/gatewayApi/payment";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {gtmCommonComp} from "../../api/gtm";

interface SectionMembershipDiscountProps {
    autoDiscount: string
    setAutoDiscount: (arg0: string) => void
}

const SectionMembershipDiscount: React.FC<SectionMembershipDiscountProps> = ({autoDiscount, setAutoDiscount}) => {
    const {showSnackbar} = useSnackbar();
    const [openPopup, setOpenPopup] = useState(false)


    const handleToggle = async () => {
        const newState = autoDiscount === "Y" ? "N" : "Y"
        setAutoDiscount(newState)
        if (newState === "Y") {
            try {
                await paymentApi.putMembershipDiscount({discountOnOff: "10"}, "v1")
                gtmCommonComp('U+멤버십 할인 자동 적용 설정', '설정')
                showSnackbar("제휴사에서 휴대폰결제 할 때 U+멤버십 할인이\n자동 적용돼요.")
            } catch (e) {
                console.log(e)
                setOpenPopup(true)
            }
        } else {
            setOpenPopup(true)
        }
    }

    const offDiscount = async () => {
        setAutoDiscount("N")
        try {
            await paymentApi.putMembershipDiscount({discountOnOff: "20"}, "v1")
            gtmCommonComp('U+멤버십 할인 자동 적용 설정', '해제')
        } catch (e) {
            console.log(e)
        }
        showSnackbar("휴대폰결제할 때 U+멤버십 할인이\n적용되지 않아요.")
        setOpenPopup(false)
    }

    console.log('autoDiscount :', autoDiscount)
    return (
        <div
            style={{
                paddingBottom: "12px",
            }}
        >
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: 'U+멤버십 설정',
                    }}
                    weight="bolder"
                />
            </Box>
            <div style={sectionStyle}>
                <TextSet
                    subComponent="Body_1"
                    text={{
                        title: 'U+멤버십 할인 자동 적용',
                        description: 'U+ 멤버십 제휴 매장에서 결제할 때 U+멤버십 카드를 보여주지 않아도 할인이 자동으로 적용돼요.'
                    }}
                />
                <Toggle
                    id="autoDiscount"
                    checked={autoDiscount === "Y"}
                    onChange={handleToggle}
                    size="medium"
                    trueValue="value"
                    value="value"
                    weight="default"
                    width="auto"
                />
            </div>
            {openPopup && (
                <CommonPopup
                    description={"설정을 해제하면 휴대폰결제 할 때\nU+멤버십 할인이 자동 적용되지 않아요.\n해제하시겠어요?"}
                    submitText='해제하기'
                    onSubmit={() => offDiscount()}
                    cancelText='취소'
                    onClose={() => {
                        setOpenPopup(false)
                        setAutoDiscount("Y")
                    }}
                />
            )}
        </div>
    );
}
const sectionStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 20px",
};

export default SectionMembershipDiscount;