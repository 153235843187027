import React, {useEffect} from 'react';
import {BottomSheet, Header, IconButton, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import '../../css/style.css'
import CommonDivider from "../Common/CommonDivider";
import {couponApi} from "../../api/gatewayApi/other";
import {formatDate} from "../../utils/dateUtiles";
import {CouponInfo, getCouponTypeName, initCouponInfo} from "../../pages/My/types";
import CouponButton from "./detail/CouponButton";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {useNavigate} from "react-router-dom";
import CommonBsBackground from "../Common/CommonBsBackground";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import defaultThumbnail from "../../images/default/default thumbnail.svg";
import useDebounce from "../../hooks/useDebounce";
import {decodeHtmlEntities} from "../../utils/textUtils";
import UsageAccordion from "./UsageAccordion";


interface BS_CouponDetailProps {
    isOpen: boolean;
    couponId: string;
    onClose: () => void;
    isIssued: boolean;
    onCouponIssue: (couponId: string) => void;
}

// 제휴사 상세 : 값을 들고 들어온다.
// 검색 : id값만 들고 데이터를 호출한다.
const BS_CouponDetail: React.FC<BS_CouponDetailProps> = ({isOpen, couponId, onClose, isIssued, onCouponIssue}) => {
    const [coupon, setCoupon] = React.useState<CouponInfo>(initCouponInfo);
    const {showSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {showMessagePopup} = useMessagePopup()

    useEffect(() => {
        if (!isOpen) return;

        if (isIssued) {
            fetchIssuedCoupon();
        } else {
            fetchUnissuedCoupon();
        }


    }, [isOpen]);

    const fetchUnissuedCoupon = async () => {
        try {
            const res = await couponApi.getCouponById(couponId, 'v1');
            if (res.header.resultCode === '0000') {
                setCoupon(res.body);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchIssuedCoupon = async () => {
        try {
            const res = await couponApi.getCouponIssuedById(couponId, 'v1');
            if (res.header.resultCode === '0000') {
                setCoupon(res.body);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchCouponIssue = useDebounce(async () => {
        try {
            couponApi.postCoupon(coupon.cpnId, {cpnType: coupon.cpnType}, 'v1').then((res) => {
                if (res.header.resultCode === '0000') {
                    onCouponIssue(coupon.cpnId);
                    showSnackbar('쿠폰이 발급되었어요', "쿠폰함 바로가기", () => navigate('/my/coupon'));
                    onClose();
                } else {
                    showMessagePopup(res.header.message);
                }
            });
        } catch (e) {
            console.error(e);
        }
    });

    const handleButtonClick = () => {
        fetchCouponIssue();
    }

    return (
        <>
            <CommonBsBackground isOpen={isOpen} onClose={onClose}/>

            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading' divider={false}>
                        {coupon.ptnName} 쿠폰 상세
                        <Header.RightSlot>
                            <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>

                    <div style={{
                        paddingTop: '0px',
                        paddingBottom: '24px',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 10rem)'
                    }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "10px 0 18px 0",
                                borderRadius: "12px",
                                backgroundColor: "#fff"
                            }}
                        >
                            {/* 쿠폰 혜택 문구 */}
                            <List
                                type="view"
                                fill="none"
                                // isleftPadding={false}
                                items={[
                                    {
                                        leftSlot: (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }}
                                                >
                                                    <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                                        {getCouponTypeName(coupon.cpnType)}
                                                    </Text>
                                                    <Text typo="font_heading_3B" color="color_text_neutral_6">
                                                        {decodeHtmlEntities(coupon.summary)}
                                                    </Text>
                                                </div>
                                            </>
                                        ),
                                        rightSlot: [
                                            <Image
                                                src={coupon.ptnLogoImgUrl}
                                                ratio="1:1"
                                                width="72px"
                                                rounded={"50%"}
                                                srcFallback={defaultThumbnail}
                                            />
                                        ]
                                    }
                                ]}
                            />
                        </div>

                        <div style={{padding: '0 1.25rem'}}>
                            {/* coupon state button */}
                            <CouponButton couponState={isIssued ? '발급' : '미발급'} onClick={handleButtonClick}/>
                        </div>
                        {/* 유효기간, 사용처 */}
                        <List
                            fill='none'
                            items={[
                                {
                                    leftSlot: (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <TextSet text={{title: '유효기간'}} subComponent='Body_2'/>
                                        </div>
                                    ),
                                    rightSlot: (
                                        <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                            {formatDate(coupon.expStartDate)}<br/>~ {formatDate(coupon.expEndDate)}
                                        </Text>
                                    ),
                                },
                                {
                                    leftSlot: (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '0.5rem',
                                            }}
                                        >
                                            <TextSet text={{title: '제휴사'}} subComponent='Body_2'/>
                                        </div>
                                    ),

                                    rightSlot: (
                                        <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                            {coupon.ptnName}
                                        </Text>
                                    ),
                                },
                            ]}
                            type='view'
                        />
                        {/* 오류: List 컴포넌트 하단 라인 없애는 기능 필요 */}
                        <CommonDivider/>

                        {/* 꼭 확인하세요 */}
                        <UsageAccordion title={'꼭 확인하세요'} content={coupon.useGuide}/>

                    </div>
                </BottomSheet.TopSlot>

                {/* <BottomSheet.BottomSlot>
        <CTA gradation layout="row">
          <Button color="secondary" fill="solid">
            혜택 사용하러 가기
          </Button>
        </CTA>
      </BottomSheet.BottomSlot> */}
            </BottomSheet>
        </>

    );
};

export default BS_CouponDetail;
