import React, {useEffect, useState} from "react";
import {Badge, Box, FAB, Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import CommonAllIconHeader from "../../../components/Common/CommonAllIconHeader";
import CustomTab from "../../../components/Entire/Pop/CustomTab";
import SortFilter from "../../../components/Partner/SortFilter";
import {eventApi} from "../../../api/gatewayApi/attendanceAndEvent";
import {getItemByKey} from "../../../utils/indexedDB";
import {formatDate, toDate} from "../../../utils/dateUtiles";
import commonApi from "../../../api/gatewayApi/common";
import useLandingPage from "../../../hooks/useLandingPage";
import {useNavigate} from "react-router-dom";
import {getAgeCode} from "../../../utils/helper";
import BarcodeLoading from "../../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import defaultImage from "../../../images/default/default thumbnail.svg";
import {brazeEventApi} from "../../../api/brazeBridge";
import {gtmPageLoad} from "../../../api/gtm";
import otherApi from "../../../api/gatewayApi/other";

interface Events {
    evtType: string;
    pageNo: string;
    totalCnt: string;
    eventList: EventItem[];
}

interface EventItem {
    evtImg: string;
    evtThumbImg: string;
    evtTitle: string;
    evtNewYn: string;
    evtStartDate: string;   // YYYYMMDD
    evtEndDate: string;     // YYYYMMDD
    evtId: string;
    evtTargetType: string;
    evtTargetLink: string;
}

const initEvents: Events = {
    evtType: '',
    pageNo: '',
    totalCnt: '',
    eventList: []
}

const SORT_LIST1 = [
    {
        id: "1",
        label: "최신순"
    },
    {
        id: "2",
        label: "마감일순"
    }];

const SORT_LIST2 = [
    {
        id: "1",
        label: "최신순"
    },
    {
        id: "2",
        label: "오래된순"
    }];

const TAB = [
    {
        id: "1",
        label: "진행중",
        dataAttributes: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': 'LNB - 링크 클릭',
            'data-gtm-event-label': '컨텐츠 : 진행중',
            'data-gtm-click-url': '',
            'data-gtm-click-location': `LNB`,
            'data-gtm-click-direction': '내부',
            'data-gtm-click-text': '진행중',
        }
    },
    {
        id: "2",
        label: "당첨자 확인",
        dataAttributes: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': 'LNB - 링크 클릭',
            'data-gtm-event-label': '컨텐츠 : 당첨자 확인',
            'data-gtm-click-url': '',
            'data-gtm-click-location': `LNB`,
            'data-gtm-click-direction': '내부',
            'data-gtm-click-text': '당첨자 확인',
        }
    },
    {
        id: "3",
        label: "종료",
        dataAttributes: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': 'LNB - 링크 클릭',
            'data-gtm-event-label': '컨텐츠 : 종료',
            'data-gtm-click-url': '',
            'data-gtm-click-location': `LNB`,
            'data-gtm-click-direction': '내부',
            'data-gtm-click-text': '종료',
        }
    }];

const Event: React.FC = () => {
    const [today, setToday] = useState(new Date());
    const [selectedTabId, setSelectedTabId] = useState<string>('1');
    const [sortType, setSortType] = useState<string>('N');
    const [ongoingEvents, setOngoingEvents] = useState<Events>(initEvents);
    const [endEvents, setEndEvents] = useState<Events>(initEvents);
    const [prizeEvents, setPrizeEvents] = useState<Events>(initEvents);
    const [sortList, setSortList] = useState(SORT_LIST1);
    const {landingPage} = useLandingPage();
    const [showFab, setShowFab] = useState(false);
    const [divWidth, setDivWidth] = useState(260);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true); // 무한 스크롤에서 더 가져올 데이터가 있는지 여부
    const navigate = useNavigate();

    useEffect(() => {
        const func = async () => {
            await gtmPageLoad({
                url: '/event',
                contentGroup: `이벤트|진행 이벤트`,
                moduleName: '',
                pageTitle: '이벤트'
            });
        }
        func();

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setShowFab(true);
            } else {
                setShowFab(false);
            }
        };

        const handleResize = () => {
            setDivWidth(window.innerWidth - 110); // 패딩 등을 고려하여 약간의 여유 공간 제공
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        const brazeEvent = async () => {
            await brazeEventApi.event_list_enter();
        }
        brazeEvent();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setHasMore(true);
        fetchData(1);
    }, [selectedTabId, sortType]);

    const fetchData = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page

        try {
            const resToday = await commonApi.getToday('v1');
            const type = selectedTabId === '1' ? 'P' : selectedTabId === '2' ? 'W' : 'E';
            if (resToday.body) {
                const dateString = resToday.body.today;
                setToday(new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}T${dateString.slice(8, 10)}:${dateString.slice(10, 12)}:${dateString.slice(12, 14)}`));
            }
            const nowYear = new Date().getFullYear();
            let birth = (await getItemByKey('birthday'))?.slice(0, 4) || '';
            let genderCode = (await getItemByKey('gender')) || 'A';

            const requestParams = {
                evtType: type, //진행 이벤트: P, 당첨자 발표 이벤트:W, 종료 이벤트:E
                sortType: sortType === 'E' ? 'E' : 'N', // 최신순: N, 마감일순: E,
                bannerType: selectedTabId === '2' ? '141003' : '141001', //이벤트배너(공통): 141001, 출석이벤트 배너: 141002, 당첨자발표 : 141003
                targetAge: await getAgeCode(),   // 119001: 10대 미만, 119002: 10대, 119003: 20대, 119004: 30대, 119005: 40대, 119006: 50대 이상
                targetGender: genderCode,
                categoryId: '',
                searchType: '',
                searchText: '',
                pageNo: currentPage.toString(),
            }
            const res = await eventApi.getEvent(requestParams, 'v1');
            if (res.header.resultCode === '0000') {
                switch (type) {
                    case 'P':
                        if (currentPage === 1) {
                            setOngoingEvents(res.body);
                            setPage(2);
                        } else {
                            const newEventList = ongoingEvents.eventList.concat(res.body.eventList);
                            setOngoingEvents({
                                ...res.body,
                                eventList: newEventList
                            });
                            setPage(currentPage + 1);
                        }
                        break;
                    case 'W':
                        if (currentPage === 1) {
                            setPrizeEvents(res.body);
                            setPage(2);
                        } else {
                            const newEventList = prizeEvents.eventList.concat(res.body.eventList);
                            setPrizeEvents({
                                ...res.body,
                                eventList: newEventList
                            });
                            setPage(currentPage + 1);
                        }
                        break;
                    case 'E':
                        if (currentPage === 1) {
                            setEndEvents(res.body);
                            setPage(2);
                        } else {
                            const newEventList = endEvents.eventList.concat(res.body.eventList);
                            setEndEvents({
                                ...res.body,
                                eventList: newEventList
                            });
                            setPage(currentPage + 1);
                        }
                        break;
                }

                if (res.body.eventList.length === 0) {
                    setHasMore(false);
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    const handleTabChange = async (id: string) => {
        setSortList(id === '1' ? SORT_LIST1 : SORT_LIST2);
        setSortType('N')
        setSelectedTabId(id);
        const contentGroup = id === '1' ? '진행 이벤트' : id === '2' ? '당첨자 발표' : '종료 이벤트';
        await gtmPageLoad({
            url: '/event',
            contentGroup: `이벤트|${contentGroup}`,
            moduleName: '',
            pageTitle: '이벤트'
        });
    }

    const handleSortChange = (sortTypeId: string) => {
        setSortType(sortTypeId === '1' ? 'N' : 'E')
    }

    const handleItemClick = (event: EventItem) => {
        //TODO bannerType 141001인지 확인 필요
        const type = selectedTabId === '1' || selectedTabId === '3'  ? '141001' : '141003';

        otherApi.postStatHistory({historyType: '163003', historyId: event.evtId, historyName: '', bannerType: type, caller: ''}, 'v1')
        landingPage(event.evtTargetType, event.evtTargetLink, navigate, event.evtTitle);
    }

    const isNewEvent = (startDate: Date): boolean => {
        const todayTime = today.getTime();
        const startTime = startDate.getTime();

        const differenceInMilliseconds = Math.abs(todayTime - startTime);
        // 7일을 밀리초 단위로 변환합니다.
        const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
        return differenceInMilliseconds <= sevenDaysInMilliseconds;
    }

    const renderEventList = (eventList: EventItem[], count: string) => {
        return <Box type="1_b">
            {/* 정렬 */}
            <SortFilter totalCount={count} sortList={sortList} onSortChange={handleSortChange}
                        isUsableCheckBox={false}/>

            {/* 당첨자 발표 목록 */}
            <List
                type="view"
                fill="none"
                items={eventList.map(item => (
                    {
                        id: `${item.evtId}-${item.evtTitle}`,
                        onClick: () => handleItemClick(item),
                        leftSlot: [
                            <Image
                                src={item.evtImg}
                                srcFallback={defaultImage}
                                ratio="1:1"
                                width="48px"
                                rounded="0%"
                            />,
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    width: `${divWidth}px`, // 화면 너비에 맞춤
                                }}
                            >
                                <Text as="span" typo="font_body_1Sb" color="color_text_neutral_5" style={{
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}>
                                    {item.evtTitle}
                                    {isNewEvent(toDate(item.evtStartDate)) &&
                                        <Badge text='N' style={{marginLeft: '8px'}}></Badge>}
                                </Text>
                                <Text as="span" typo="font_detail_2M" color="color_text_neutral_5"
                                      style={{color: "#66707A"}}>
                                    {formatDate(item.evtStartDate)} ~ {formatDate(item.evtEndDate)}
                                </Text>
                            </div>
                        ],
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `${selectedTabId === '1' ? '진행중' : selectedTabId === '2' ? '당첨자 확인' : '종료'}|이벤트 리스트 - 링크 클릭`,
                            'data-gtm-event-label': `컨텐츠 : ${item.evtTitle}`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `${selectedTabId === '1' ? '진행중' : selectedTabId === '2' ? '당첨자 확인' : '종료'}|이벤트 리스트`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': item.evtTitle,
                        }
                    }
                ))}
            />
        </Box>
    }

    return (
        <>
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                <InfiniteScroll next={fetchData} hasMore={hasMore}
                                loader={<BarcodeLoading/>}
                                dataLength={Number(selectedTabId === '1' ? ongoingEvents.eventList.length : selectedTabId === '2' ? prizeEvents.eventList.length : endEvents.eventList.length)}
                                scrollableTarget={"window"}
                >

                    {/* header */}
                    <CommonAllIconHeader title={'이벤트'}/>

                    {/* 탭 메뉴 */}
                    <CustomTab items={TAB} onTabChange={handleTabChange} selectedItemId={selectedTabId}/>

                    {/* 당첨자 발표 목록 */}
                    {selectedTabId === '1' && renderEventList(ongoingEvents.eventList, ongoingEvents.totalCnt)}
                    {selectedTabId === '2' && renderEventList(prizeEvents.eventList, prizeEvents.totalCnt)}
                    {selectedTabId === '3' && renderEventList(endEvents.eventList, endEvents.totalCnt)}

                    {/* FAB */}
                    {showFab && <Box type="1_b" style={{bottom: "0"}}>
                        <FAB
                            icon="chevron_up_small_line"
                            label=""
                            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                            size="small"
                            style={{
                                position: "fixed",
                                right: "24px",
                                bottom: "47px"
                            }}
                        />
                    </Box>}
                </InfiniteScroll>
            </Box>
        </>
    );
};

export default Event;
