import {BottomSheet, Button, CTA, Header, IconButton, List, Text} from "@lguuxe/2024_designsystem_uni";
import React from "react";
import {useFullScreen} from "../../provider/FullScreenContext";
import AgreementsDetail from "../../pages/Agreements/AgreementsDetail";

interface BS_Location {
    isOpen: boolean;
    onClose: () => void;
    onAgree: () => void;
    termId: string;
}

const BS_Location: React.FC<BS_Location> = ({isOpen, onClose, onAgree, termId}) => {
    const [checkedTerm, setCheckedTerm] = React.useState(false);
    const {show} = useFullScreen();
    const {hide} = useFullScreen();

    const handleCheck = (e: any) => {
        console.log(e);
        if (e.value === '선택되었습니다') {
            setCheckedTerm(true);
        } else {
            setCheckedTerm(false);
        }
    }

    const handleDetailClick = (e: any) => {
        if (e.target.id === 'termDetail') {
            console.log(termId);
            show(<AgreementsDetail termId={termId} type='exist' selected={true} handleClose={() => {
                hide()
            }}/>)
            // show(<AdditionalAgreements/>)
        }
    }

    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align="left" variant="heading" divider={false}>
                        위치기반 서비스 약관
                        <Header.RightSlot>
                            <IconButton color="neutral" fill="icon" icon="close_line" noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>


                    {/*<BottomSheet.CenterSlot>*/}
                    <div style={{paddingTop: "0px", height: "auto"}}>
                        <Text
                            typo="font_detail_2M"
                            color="color_text_neutral_3"
                            textAlign="left"
                            style={{
                                width: "100%",
                                justifyContent: "flex-start",
                                padding: '0 1rem 0 1.25rem'
                            }}
                        >
                            가까운 제휴처을 알려드리기 위해 위치 정보가 필요합니다.
                            <br/>
                            위치 기반 서비스 약관에 동의해 주세요.
                        </Text>
                        <List
                            fill="none"
                            items={[
                                {
                                    leftSlot: [
                                        <div>
                                            <Text>
                                                개인위치정보 활용동의 및 위치기반서비스 이용약관
                                            </Text>
                                        </div>
                                    ],
                                    linkIconOnlyId: "termDetail",
                                    checked: checkedTerm,
                                }
                            ]}
                            name="list_name"
                            size="medium"
                            type="checkbox"
                            weight="default"
                            onChange={handleCheck}
                            onClick={handleDetailClick}
                        />
                    </div>
                    {/*</BottomSheet.CenterSlot>*/}
                </BottomSheet.TopSlot>

                <BottomSheet.BottomSlot>
                    <CTA gradation layout="row">
                        <Button color="primary" size="large" disabled={!checkedTerm} onClick={onAgree}>
                            동의하기
                        </Button>
                    </CTA>
                </BottomSheet.BottomSlot>
            </BottomSheet>
        </>
    );
}

export default BS_Location;