import React, {CSSProperties, useEffect, useState} from "react";
import {Box, Image, Tab, Text} from "@lguuxe/2024_designsystem_uni";
import CommonAllIconHeader from "../../components/Common/CommonAllIconHeader";
import SectionChip from "../../components/Home/SectionChip";
import nftApi from "../../api/gatewayApi/nft";
import useLoading from "../../hooks/useLoading";
import {Tag} from "../Home/types";
import emptyPartnerImg from "../../images/img/empty/empty_partner.svg";
import promotion1 from "../../images/badge/promotion1.webp"
import promotion2 from "../../images/badge/promotion2.webp"
import promotion3 from "../../images/badge/promotion3.webp"
import ExImg from "../../images/default/default thumbnail.svg";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SortItem, TotalPartners} from "../Partner/types";
import BadgePartnerList from "../../components/Badge/BadgePartnerList";
import SortFilter from "../../components/Partner/SortFilter";
import useDebounce from "../../hooks/useDebounce";
import BarcodeLoading from "../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";

const exampleCategory: Tag[] = [
    {
        tagId: '0',
        tagName: '나들이킹',
        tagType: '타입',
    },
    {
        tagId: '1',
        tagName: '쇼핑킹',
        tagType: '타입',
    },
    {
        tagId: '2',
        tagName: '푸드킹',
        tagType: '타입',
    },
    {
        tagId: '3',
        tagName: '콘텐츠킹',
        tagType: '타입',
    },
    {
        tagId: '4',
        tagName: '스터디킹',
        tagType: '타입',
    },
    {
        tagId: '5',
        tagName: '일상킹',
        tagType: '타입',
    },
    {
        tagId: '6',
        tagName: '뷰티킹',
        tagType: '타입',
    },
]

const sortList: SortItem[] = [
    {
        id: '1',
        label: '인기순',
    },
    {
        id: '2',
        label: '최신순',
    },
    {
        id: '3',
        label: '가나다순',
    },
];

interface SortState {
    sortType: string;
    isUsable: boolean;
}

const Promotion: React.FC = () => {
    const [event, setEvent] = useState<string>("Y");
    const [categories, setCategories] = useState<Tag[]>([]);
    const [partnerList, setPartnerList] = useState<TotalPartners>({partnerList: [], totalCount: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('1'); // State to track the selected tab
    const [categoryId, setCategoryId] = useState('0');
    const [sortState, setSortState] = useState<SortState>({sortType: '', isUsable: false});
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');  // 'tab' 파라미터의 값을 가져옴
    const urlCategoryId = searchParams.get('CategoryId');
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        window.scrollTo(0, 0.1);
        if (tab && tab === '2') {
            setSelectedTab('2');
        }
        if (urlCategoryId) {
            setCategoryId(urlCategoryId);
        }
    }, []);

    useEffect(() => {
        setHasMore(true)
        fetchData(1);
    }, [categoryId, sortState]);

    const fetchData = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page

        setCategories(exampleCategory)
        try {
            if (categoryId) {
                const params = {
                    sort: sortState.sortType,
                    pageNo: currentPage.toString()
                }
                const categoryDetailsResult = await nftApi.getCategoryById(categoryId, params, 'v1');
                if (categoryDetailsResult.header.resultCode === '0000') {
                    if (currentPage === 1) {
                        setPartnerList(categoryDetailsResult.body);
                        setPage(2)
                    } else {
                        const newPartnerList = partnerList.partnerList.concat(categoryDetailsResult.body.partnerList)
                        setPartnerList({
                            ...categoryDetailsResult.body,
                            partnerList: newPartnerList
                        })
                        setPage(currentPage + 1)
                    }

                    if (categoryDetailsResult.body.partnerList.length === 0) {
                        setHasMore(false);
                    }
                }

                console.log(categoryDetailsResult.body)
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error)
        } finally {
            setIsLoading(false); // 로딩 상태 해제
        }
    };

    const handleSortChange = useDebounce((sortId: string, isUsable: boolean) => {
        let sortType = '';
        switch (sortId) {
            case '1':
                sortType = '';
                break;
            case '2':
                sortType = 'L';
                break;
            case '3':
                sortType = 'A';
                break;
        }

        setSortState({sortType, isUsable});
    });

    const handleTabChange = (selectedItemId: string) => {
        setSelectedTab(selectedItemId);
    };

    const renderNoPartner = () => (
        <div>
            <div style={styles.noPartnerContainer}>
                <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={styles.noPartnerText}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        제휴사가 없습니다.
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderPromotionInfo = () => (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Image
                src={promotion1}
                width="100%"
                height="auto"
                srcFallback={ExImg}
                style={{paddingBottom: '0px'}}
            />
            <Image
                src={promotion2}
                width="auto"
                height="auto"
                srcFallback={ExImg}
                style={{paddingBottom: '0px'}}
            />
            <Image
                src={promotion3}
                width="auto"
                height="auto"
                srcFallback={ExImg}
                style={{paddingBottom: '80px'}}
            />

            <div style={{position: 'fixed', bottom: '0', left: '0', width: '100%', zIndex: 1000}}>
                {event === 'Y' ? (
                    <CommonCTAColumn
                        gradation={true}
                        handleSummit={() => navigate('/my/badge')}
                        buttonText={"내 배지 보기"}
                    />
                ) : (
                    <CommonCTAColumn
                        gradation={true}
                        handleSummit={() => console.log("click")}
                        buttonText={"당첨 확인"}
                        onClose={() => console.log("close")}
                        cancelText={"내 배지 보기"}
                    />
                )}
            </div>
        </div>
    );

    return (
        <>
            {useLoading(isLoading)}
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                <InfiniteScroll next={fetchData} hasMore={hasMore}
                                loader={<BarcodeLoading/>}
                                dataLength={partnerList.partnerList.length}
                                scrollableTarget={"window"}
                >

                    {/* header */}
                    <CommonAllIconHeader title={"킹배지"} isSearch={false}/>

                    {/* 탭 메뉴 */}
                    <Tab
                        items={[
                            {
                                id: "1",
                                label: "미션 안내"
                            },
                            {
                                id: "2",
                                label: "참여 제휴사"
                            }
                        ]}
                        layout="fullWidth"
                        selectedItemId={selectedTab}
                        onItemSelected={handleTabChange}
                        style={{
                            padding: "0 20px"
                        }}
                    />
                    {/* 컨텐츠 랜더링 */}
                    {selectedTab === '1' ? (
                        renderPromotionInfo()
                    ) : (
                        <>
                            {/* 서브탭 메뉴 */}
                            <div style={{
                                padding: "20px 0 0 20px"
                            }}>
                                {urlCategoryId ? <SectionChip Tags={categories} selectedCategoryId={urlCategoryId}
                                                              handleChipClick={setCategoryId}/> :
                                    <SectionChip Tags={categories} handleChipClick={setCategoryId}/>
                                }

                                <SortFilter isUsableCheckBox={false} sortList={sortList} onSortChange={handleSortChange}
                                            totalCount={partnerList.totalCount}/>
                            </div>
                            <BadgePartnerList partners={partnerList}></BadgePartnerList>
                        </>
                    )}
                </InfiniteScroll>
            </Box>
        </>
    );
};

const styles: { [key: string]: CSSProperties } = {
    noPartnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '88px 0',
    },
    noPartnerText: {
        paddingTop: '20px',
    },
};
export default Promotion;
