import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {Box, Button, CTA, List, TextSet} from "@lguuxe/2024_designsystem_uni";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import CommonDividerThin from "../../../components/Common/CommonDividerThin";
import {useNavigate} from "react-router-dom";
import {affiliateServiceApi} from "../../../api/gatewayApi/partnerAndAffiliate-service";
import {joinRequestParam, Term} from "./types";
import CommonPopup from "../../../components/Common/CommonPopup";
import AgreementsDetail from "../../Agreements/AgreementsDetail";
import {gtmPageLoad} from "../../../api/gtm";

export interface PopTermHandles {
    triggerKeypressBack: () => void;
}

const PopTerms = forwardRef<PopTermHandles>((props, ref) => {
    const navigate = useNavigate();
    const [allAgree, setAllAgree] = useState(false);
    const [isNextButtonActive, setIsNextButtonActive] = useState(false);
    const [termList, setTermList] = useState<Term[]>([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [showFailPopup, setShowFailPopup] = useState<boolean>(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState<Term>({termId: '', termName: '', termType: '', isAgree: false});

    useImperativeHandle(ref, () => ({
        triggerKeypressBack() {
            if (isDetailOpen) {
                setIsDetailOpen(false);
            } else {
                navigate(-1)
            }
        }
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                affiliateServiceApi.getAgreements({serviceId: '129001'}, 'v1').then((response) => {
                    setTermList(response.body.agreementList.map((item: any) => {
                        return {
                            termId: item.termId,
                            termName: item.termName,
                            termType: item.termType,
                            isAgree: false,
                        }
                    }));
                });
            } catch (error) {
                console.log('Failed to fetch :', error);
            } finally {
                await gtmPageLoad({
                    url: '/pop/terms',
                    contentGroup: '마이|POP 서비스|가입절차',
                    moduleName: '',
                    pageTitle: 'U+멤버십 GS 약관'
                });
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const isEssentialAllAgree = termList
            .filter(term => term.termType === 'Y')
            .every(term => term.isAgree);
        setIsNextButtonActive(isEssentialAllAgree);

        const isAllAgree = termList
            .every(term => term.isAgree)
        isAllAgree ? setAllAgree(true) : setAllAgree(false)
    }, [termList]);

    const changeAllTerm = (isChecked: boolean) => {
        setTermList(termList.map(term => ({...term, isAgree: isChecked})));
    }

    const fetchJoin = async () => {
        try {
            const requestParam = joinRequestParam(termList);
            affiliateServiceApi.postJoin(requestParam, 'v1').then((response) => {
                switch (response.header.resultCode) {
                    case '0000':
                        setShowSuccessPopup(true);
                        break;
                    case '1012': // 이메일 정보 없음
                        navigate('/pop/input', {state: termList});
                        break;
                    default:
                        setShowFailPopup(true);
                        break;
                }
            });
        } catch (error) {
            setShowFailPopup(true);
            console.log('Failed to fetch :', error);
        }
    }

    const generateTermText = (text: string, termType: string) => {

        const prefix = termType === 'Y' ? '[필수]' : '[선택]';
        return `${prefix} ${text}`;
    };

    const handleTermCheck = (e: any) => {
        const checkedState = e.checkedState; // {terms-0: true, terms-1: true, terms-2: false}
        const updatedTermList = termList.map((term, index) => {
            const key = `terms-${index}`;
            return {
                ...term,
                isAgree: checkedState[key],
            };
        });
        setTermList(updatedTermList);
    };

    const handleAllAgree = () => {
        changeAllTerm(!allAgree)
        setAllAgree(!allAgree);
    };

    const handleNext = () => {
        fetchJoin();
        // navigate('/pop/input', {state: termList});
    }

    const handleDetailTerm = (e: any) => {
        if (e.target.id.includes('link-')) {
            const id = e.target.id.split('-')[1];
            const selected = termList.find(term => term.termId === id);
            setSelectedTerm(selected!);
            setIsDetailOpen(true);
        }
    }

    const handleDetailTermSubmit = () => {
        const newTermList = termList.map((term) => {
            if (term.termId === selectedTerm.termId) {
                term.isAgree = !term.isAgree
                setSelectedTerm(term)
            }
            return term
        });
        setTermList(newTermList);
        setIsDetailOpen(false);
    }

    return (
        <>
            {!isDetailOpen && (
                <>
                    <CommonLeftIconHeader title={''}/>

                    <Box type="2_trbl">
                        <TextSet
                            subComponent="Heading_2"
                            text={{
                                title: 'U+멤버십 GS 약관'
                            }}
                            weight="bolder"
                        />
                    </Box>

                    <Box type="3_tb">
                        <List
                            fill="none"
                            size="large"
                            items={[
                                {
                                    text: {
                                        title:
                                            <TextSet
                                                subComponent="Body_1"
                                                text={{
                                                    title: '모든 이용약관에 동의'
                                                }}
                                                weight="bolder"
                                            />
                                    },
                                    checked: allAgree
                                }
                            ]}
                            type="checkbox"
                            onChange={handleAllAgree}
                        />
                        <Box style={{padding: "0 20px"}}>
                            <CommonDividerThin/>
                        </Box>
                        <Box style={{padding: "12px 0 0"}}>
                            <List
                                id='terms'
                                fill="none"
                                size="large"
                                isSlotClickable={true}
                                items={termList.map((term, index) => ({
                                    checked: termList[index].isAgree,
                                    text: {
                                        title: (
                                            <TextSet
                                                subComponent="Body_1"
                                                text={{
                                                    title: generateTermText(term.termName, term.termType)
                                                }}
                                            />
                                        )
                                    },
                                    linkIconOnlyId: `link-${term.termId}`,
                                }))}
                                type="checkbox"
                                onChange={handleTermCheck}
                                onClick={handleDetailTerm}
                            />
                        </Box>
                    </Box>

                    <Box className="fixedBtn" style={{padding: "0"}}>
                        <CTA
                            layout="row"
                        >
                            <Button color="neutral" onClick={() => {
                                navigate(-1)
                            }}>
                                취소
                            </Button>
                            <Button color="primary" disabled={!isNextButtonActive} onClick={handleNext}>
                                동의하기
                            </Button>
                        </CTA>
                    </Box>
                </>
            )}

            {isDetailOpen && (
                <AgreementsDetail
                    selected={selectedTerm.isAgree}
                    type={'pop'}
                    termId={selectedTerm.termId}
                    tempTermTitle={selectedTerm.termName}
                    handleSubmit={handleDetailTermSubmit}
                    handleClose={() => setIsDetailOpen(false)}
                />
            )}

            <CommonPopup isOpen={showSuccessPopup} description={'POP서비스 가입했어요.\nGS25에 휴대결제하고\n추가할인 받으세요.'}
                         submitText={'확인'} onSubmit={() => {
                navigate(-1)
            }}/>
            <CommonPopup isOpen={showFailPopup} description={'일시적인 오류로 POP서비스\n가입하지 못했어요.\n잠시 후 다시 시도해주세요.'}
                         submitText={'확인'}
                         onSubmit={() => {
                             navigate(-1)
                         }}/>
        </>
    )
});

export default PopTerms;