import React, {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionBadgeInfo from "../../components/Badge/SectionBadgeInfo";
import SectionTab from "../../components/Badge/SectionTab";
import SectionBadge from "../../components/Badge/SectionBadge";
import SectionSorting from "../../components/Badge/SectionSorting";
import SectionAccordion from "../../components/Badge/SectionArcodion";
import nftApi from "../../api/gatewayApi/nft";
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "../../components/Common/CommonPopup";
import {initialMyBadge, MyBadgeData} from "./types";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {generateMCP_TR_KEY} from "../../api/authFunctions";
import {gtmPageLoad} from "../../api/gtm";

interface BadgeCategory {
    count: string;
    categoryId: string;
    categoryName: string;
}

const MyBadge: FC = () => {
    const location = useLocation()
    const [badgeCategories, setBadgeCategories] = useState<BadgeCategory[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>('1'); // 기본값을 '1'로 설정
    const [openPopup, setOpenPopup] = useState(false)
    const [myBadge, setMyBadge] = useState<MyBadgeData>(initialMyBadge);
    const [history, setHistory] = useState([]);
    const [mcpTrKey, setMcpTrKey] = useState<string>("")
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState<string>(new Date().toString().slice(0, 6));
    const {showMessagePopup} = useMessagePopup();
    useEffect(() => {
        const fetchData = async () => {
            const mcpTrKey = generateMCP_TR_KEY();
            setMcpTrKey(mcpTrKey);

            try {
                const currentDate = await getItemByKey("currentDay");
                setCurrentDate(currentDate);
                const myBadgeResponse = await nftApi.getMyBadge('v1');
                const now = new Date(myBadgeResponse.header.timeStamp.replace(' ', 'T'));
                const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}`;
                const dateToUse = currentDate === null || currentDate === '' || currentDate === undefined ? formattedDate : currentDate;

                const history = await nftApi.getHistory({month: dateToUse.slice(0, 6)}, 'v1', mcpTrKey);

                let myBadge = myBadgeResponse.body
                let categoryList = myBadgeResponse.body.categoryList
                if (location.state && location.state.updatedBadges) {
                    const updatedData = updateBadgeCategories(categoryList, location.state.updatedBadges, myBadge.badgeCount);
                    categoryList = updatedData.updatedCategories;
                    myBadge.badgeCount = updatedData.updatedBadgeCount;
                }
                setMyBadge(myBadge || initialMyBadge);
                setBadgeCategories(categoryList);
                setHistory(history.body.nftHistoryList)
            } catch (error) {
                console.error("데이터 가져오는 중 오류 발생:", error);
            } finally {
                await gtmPageLoad({url: '/my/badge', contentGroup: '마이|내 배지', moduleName: '', pageTitle: '마이|내 배지'});
            }
        };
        fetchData()
        window.scrollTo(0, 0.1);

    }, []);

    const updateBadgeCategories = (categoryList: BadgeCategory[], updatedBadges: BadgeCategory[], badgeCount: string) => {
        let totalCountDeducted = 0;
        const updatedCategories = categoryList.map(category => {
            const updatedBadge = updatedBadges.find(badge => badge.categoryId === category.categoryId);
            if (updatedBadge) {
                const newCount = (parseInt(category.count) - parseInt(updatedBadge.count)).toString();
                totalCountDeducted += parseInt(updatedBadge.count);
                return {
                    ...category,
                    count: newCount,
                };
            }
            return category;
        });

        const updatedBadgeCount = (parseInt(badgeCount) - totalCountDeducted).toString(); // badgeCount 차감
        return {updatedCategories, updatedBadgeCount};
    };


    const onClickGift = async () => {
        const response = await nftApi.getCheckSending('v1')
        const isSend = response.body.isSend
        if (isSend === "Y") {
            navigate('/my/badge/gift')
        } else if (isSend === "N") {
            setOpenPopup(true)
        }
    }
    const handleSetFilter = async (filterValue: string) => {
        const mcpTrKey = generateMCP_TR_KEY();
        setMcpTrKey(mcpTrKey)
        const response = await nftApi.getHistory({month: filterValue}, 'v1', mcpTrKey)
        if (response.header.resultCode === '0000') {
            setHistory(response.body.nftHistoryList)
        } else {
            showMessagePopup(response.header.message)
        }
    }

    return (
        <>
            <CommonLeftIconHeader title={"내 배지"}/>

            <SectionBadgeInfo myBadge={myBadge} onClickGift={onClickGift}/>

            <div style={{marginBottom: "8px"}}>
                <CommonDivider/>
            </div>

            <SectionTab selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>

            {selectedTab === '1' && <SectionBadge badgeCategories={badgeCategories}/>}
            {selectedTab === '2' && (
                <>
                    <SectionSorting historyCount={history.length} setFilter={handleSetFilter}/>
                    <SectionAccordion mcpTrKey={mcpTrKey} historyList={history}/>
                </>
            )}
            {openPopup && <CommonPopup description={"배지는 하루 한 번 선물할 수 있어요.\n내일 다시 시도해 주세요."} submitText={"확인"}
                                       onSubmit={() => setOpenPopup(false)}/>}
        </>
    );
}

export default MyBadge;
