import React from 'react';
import {Button, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import EventItem from "../ListItem/EventItem";
import NoticeItem from "../ListItem/NoticeItem";
import QnAItem from "../ListItem/QnAItem";
import UPTPItem from "../ListItem/UPTPItem";
import {useNavigate} from 'react-router-dom';
import StoreItem from "../ListItem/StoreItem";
import {useMessagePopup} from "../../provider/MessagePopupProvider";


interface SearchListProps {
    title: string;
    items: any[];
    threshold: number;
    onCheckLogin: () => boolean;
    onIssueCoupon: (cpnId: string) => void;
}

const SearchList: React.FC<SearchListProps> = ({title, items, threshold, onCheckLogin, onIssueCoupon}) => {
    const navigate = useNavigate();
    const {showMessagePopup} = useMessagePopup();

    const handleMoreClick = () => {
        if (title === '공지사항' || title === '자주하는 질문') {
            navigate('/result-more', {state: {title, items}});
            return;
        }

        if (onCheckLogin()) {
            navigate('/result-more', {state: {title, items}});
        }
    };
    const renderItems = (items: any[]) => {
        switch (title) {
            // case '쿠폰':
            //     return <CouponItem itemList={items} threshold={threshold} onCheckLogin={onCheckLogin}
            //                        onCouponIssue={onIssueCoupon}/>;
            case '이벤트':
                return <EventItem itemList={items} threshold={threshold} onCheckLogin={onCheckLogin}/>;
            case '공지사항':
                return <NoticeItem itemList={items} threshold={threshold}/>;
            //TODO 리스트는 FAQ 등록일 기준 최근 2개까지의 검색결과만 표기
            case '자주하는 질문':
                return <QnAItem itemList={items} threshold={threshold}/>;
            case '제휴사':
                return <StoreItem itemList={items} threshold={threshold} onCheckLogin={onCheckLogin}/>;
            //TODO 진행 중 (어드민 설정 순) - 예정(시작일 순) - 종료(시작일 순) 순 최대 3개 표시
            case '유플투쁠':
                return <UPTPItem itemList={items} threshold={threshold} onCheckLogin={onCheckLogin}/>;
            default:
                return null;
        }
    };

    const getGtmLocation = (title: string) => {
        switch (title) {
            // case '쿠폰':
            //     return '할인 쿠폰';
            case '이벤트':
                return '이벤트';
            case '공지사항':
                return '공지사항';
            case '자주하는 질문':
                return '자주하는 질문';
            case '제휴사':
                return '추천 제휴사';
            case '유플투쁠':
                return '유플투쁠 혜택';
            default:
                return '';
        }
    }
    return (
        <div>
            {title !== '쿠폰' && (
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: (
                            <>
                                <Text typo="font_heading_4B">
                                    {title}
                                    <Text
                                        typo="font_heading_4B"
                                        color="color_text_primary"
                                    >
                                        &nbsp;{items.length}
                                    </Text>
                                </Text>
                            </>
                        ),
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            )}

            {renderItems(items)}

            {title !== '쿠폰' && items.length > threshold && (
                <div
                    style={{
                        textAlign: "center",
                        borderTop: "1px solid #E7EBEE",
                        marginTop: "12px",
                    }}
                >
                    <Button
                        color="neutral"
                        rightIcon="chevron_right_small_line"
                        size="large"
                        fill="text"
                        style={{
                            padding: "14px 0",
                            width: "100%"
                        }}
                        onClick={handleMoreClick}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${getGtmLocation(title)} - 링크 클릭`}
                        data-gtm-event-label={`컨텐츠 : ${getGtmLocation(title)} 더보기`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${getGtmLocation(title)}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`${getGtmLocation(title)} 더보기`}
                    >
                        더보기
                    </Button>
                </div>
            )}
        </div>
    );
}

export default SearchList;
