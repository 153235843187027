import {Button} from "@lguuxe/2024_designsystem_uni"
import {CouponInfo} from "../../../pages/My/types";

interface ButtonSectionProps {
    onClick: () => void
    couponInfo: CouponInfo
}

const ButtonSection: React.FC<ButtonSectionProps> = ({onClick, couponInfo}) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "12px",
                flexDirection: "column",
                padding: "0",
                width: "100%"
            }}
            // type="1_trbl"
        >
            {/* <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "center",
              padding: "4px 20px 0",
              alignSelf: "stretch"
            }}
          >
            <InfoBox />
          </div> */}

            <div
                style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "center",
                    padding: "4px 20px 0"
                }}
            >
                <Button
                    color="secondary"
                    fill="solid"
                    style={{
                        display: "flex",
                        flex: "1"
                    }}
                    onClick={onClick}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'마이|내 쿠폰|쿠폰 상세 - 링크 클릭'}
                    data-gtm-event-label={`컨텐츠 : 직원 확인 비밀번호 입력`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={'마이|내 쿠폰|쿠폰 상세'}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`직원 확인 비밀번호 입력`}
                    data-gtm-affiliate-id={couponInfo.ptnId}
                    data-gtm-affiliate-name={couponInfo.ptnName}
                >
                    직원 확인 비밀번호 입력
                </Button>
            </div>
        </div>
    )
}

export default ButtonSection