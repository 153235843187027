import React from 'react';
import {Box, Button, CTA, Image, Text} from "@lguuxe/2024_designsystem_uni";
import bage_24Icon from "../../images/icon/6_notification/badge_24.svg";
import CommonText2row from "../Common/CommonText2row";
import {useNavigate} from "react-router-dom";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import {MyBadgeData} from "../../pages/Badge/types";

interface SectionBadgeInfoProps {
    onClickGift: ()=> void;
    myBadge: MyBadgeData
}

const SectionBadgeInfo: React.FC<SectionBadgeInfoProps> = ({onClickGift, myBadge}) => {
    const badgeCount = Math.min(Number(myBadge.badgeCount), 200); // 200개로 제한
    const sendableCount = Math.min(Number(myBadge.sendableCount), 200); // 200개로 제한
    const remainingBadges = Math.max(200 - Number(myBadge.badgeCount), 0); // 0으로 제한
    return (
        <div>
            <Box type="2_trbl">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "12px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            gap: "4px"
                        }}
                    >
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={bage_24Icon}
                            width="24"
                        />
                        <Text typo="font_label_1Sb" color="color_text_neutral_6">
                            내가 모은 배지
                        </Text>
                    </div>
                    <Text typo="font_heading_5Sb" color="color_text_primary">총 {badgeCount}개</Text>
                </div>
            </Box>
            <Box
                type="3_trbl"
                style={{
                    paddingBottom: "0"
                }}
            >
                {/*<CommonText2row label={"선물 가능한 배지"} value={sendableCount + " 개"}/>한*/}
                {/*<CommonText2row label={"획득 가능한 배지"} value={remainingBadges + "/200 개"}/>*/}
            </Box>
            {/*<div*/}
            {/*    style={{*/}
            {/*        paddingTop: "12px"*/}
            {/*    }}*/}
            {/*>*/}
                {/*<CTA layout="column">*/}
                {/*    <Button color="secondary" onClick={onClickGift}*/}
                {/*            data-gtm-event-name={'all_click'}*/}
                {/*            data-gtm-event-category={'클릭'}*/}
                {/*            data-gtm-event-action={`${CLICK_LOCATION.BADGE_PROFILE} - ${EVENT_ACTION.LINK_CLICK}`}*/}
                {/*            data-gtm-event-label={`컨텐츠 : 배지 선물하기`}*/}
                {/*            data-gtm-click-url={''}*/}
                {/*            data-gtm-click-location={`${CLICK_LOCATION.BADGE_PROFILE}`}*/}
                {/*            data-gtm-click-direction={'내부'}*/}
                {/*            data-gtm-click-text={`배지 선물하기`}>*/}
                {/*        배지 선물하기*/}
                {/*    </Button>*/}
                {/*</CTA>*/}
            {/*</div>*/}
        </div>
    );
}

export default SectionBadgeInfo;
