import React, {useEffect, useRef, useState} from 'react';
import {FAB, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import ChipButtonGroup from "../../../components/Entire/Help/ChipButtonGroup";
import {notiApi} from "../../../api/gatewayApi/notiAndQna";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../../utils/dateUtiles";
import BarcodeLoading from "../../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import {decodeHtmlEntities} from "../../../utils/textUtils";
import {gtmPageLoad} from "../../../api/gtm";

interface Notice {
    noticeId: string;
    title: string;
    date: string;
}


const Announcement: React.FC = () => {
    const navigate = useNavigate();
    const [activeChipId, setActiveChipId] = useState<number>(1);
    const [noticeList, setNoticeList] = useState<Notice[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true); // 무한 스크롤에서 더 가져올 데이터가 있는지 여부
    const isFirstRender = useRef(true);
    const [showFab, setShowFab] = useState(false);

    const chipButtons = [
        {id: 1, label: '전체', gtmLocation: '카테고리'},
        {id: 116001, label: '서비스 안내', gtmLocation: '카테고리'},
        {id: 116002, label: 'U+멤버십', gtmLocation: '카테고리'},
        {id: 116005, label: '휴대폰결제', gtmLocation: '카테고리'},
        {id: 116003, label: '당첨자 발표', gtmLocation: '카테고리'},
    ];

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: '/help/announcement',
                contentGroup: `공지사항`,
                moduleName: '카테고리|전체|리스트',
                pageTitle: '공지사항'
            });
        }
        gtm();

        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 0) {
                setShowFab(true);
            } else {
                setShowFab(false);
            }
        };

        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    useEffect(() => {
        fetchData(1);
    }, [activeChipId]);

    const fetchData = async (pageParam?: number) => {
        if (pageParam !== 1 && window.scrollY <= 0) return;
        const currentPage = pageParam || page;

        try {
            const sortType = activeChipId.toString() === '1' ? '' : activeChipId.toString();
            const request = {
                svcType: sortType,  // - 116001: 서비스 안내, 116002:U+멤버십, 116004 : 공통, 116005 : 휴대폰 결제, 빈값 전달시 전체
                pageNo: currentPage.toString(), // 빈값시 전체
            }
            notiApi.getCategoriesList(request, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    if (currentPage === 1) {
                        setNoticeList(res.body.noticeList);
                        setPage(2);
                    } else {
                        const newNoticeList = noticeList.concat(res.body.noticeList);
                        setNoticeList(newNoticeList);
                        setPage(currentPage + 1);
                    }

                    if (res.body.noticeList.length === 0) {
                        setHasMore(false);
                    }
                }
            });
        } catch (error) {
            console.log('Failed to fetch :', error);
        }
    }

    const handleChipClick = (id: number) => {
        setPage(1);
        setHasMore(true);
        setActiveChipId(id);
        console.log(`Chip with id ${id} clicked`);
    };

    const handleClick = (event: any) => {
        console.log(event.target.id);
        navigate(`/help/announcement/${event.target.id}`);
    }

    return (
        <div style={{
            width: '100%'
        }}>
            <InfiniteScroll next={fetchData} hasMore={hasMore}
                            loader={<BarcodeLoading/>}
                            dataLength={noticeList.length}
            >

                <CommonLeftIconHeader title="공지사항"/>

                <ChipButtonGroup
                    chipButtons={chipButtons}
                    activeChipId={activeChipId}
                    onChipClick={handleChipClick}
                />


                <List
                    fill="none"
                    type="view"
                    size="large"
                    items={noticeList.map((item, index) => ({
                        id: item.noticeId,
                        leftSlot: (
                            <TextSet
                                key={index}
                                subComponent="Body_1"
                                text={{
                                    title: <Text
                                        style={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'normal',
                                            WebkitLineClamp: 2,
                                        }}
                                    >
                                        {decodeHtmlEntities(item.title)}
                                    </Text>,
                                    description: formatDate(item.date),
                                }}
                                weight="bolder"
                            />
                        ),
                        linkIconOnlyId: item.noticeId,
                        onClick: handleClick,
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': `${chipButtons.find((item) => item.id === activeChipId)?.label || ''}|리스트 - 링크 클릭`,
                            'data-gtm-event-label': `컨텐츠 : ${item.title}`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': `${chipButtons.find((item) => item.id === activeChipId)?.label || ''}|리스트`,
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': item.title
                        }
                    }))}
                />

                {showFab && <FAB
                    icon="chevron_up_small_line"
                    label=""
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    size="small"
                    style={{
                        position: "fixed",
                        right: "24px",
                        bottom: "47px"
                    }}
                />}
            </InfiniteScroll>
        </div>
    );
};

export default Announcement;