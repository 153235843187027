import React, {useEffect, useState} from 'react';
import CommonDivider from '../../components/Common/CommonDivider';
import CommonRightIconHeader from '../../components/Common/CommonRightIconHeader';

import EventList from '../../components/Mission/EventList';
import EventBlock from '../../components/Mission/EventBlock';
import {AttendanceInfo, EventItem, initialAttendance} from './types';
import {attendanceApi, eventApi} from '../../api/gatewayApi/attendanceAndEvent';
import {useNavigate} from 'react-router-dom';
import Calender from "../../components/Mission/Calender";
import {handlePromiseResult} from '../../api/api';
import {getItemByKey} from "../../utils/indexedDB";
import {getAgeCode} from "../../utils/helper";
import useLoading from "../../hooks/useLoading";
import NavigationBar from "../../components/Common/NavigationBar";
import {gtmPageLoad} from "../../api/gtm";

interface MissionAttendanceProps {
    navigationBarRef: React.Ref<any>;
}

const MissionAttendance: React.FC<MissionAttendanceProps> = ({navigationBarRef}) => {
    const [attendance, setAttendance] = useState<AttendanceInfo>(initialAttendance);
    const [eventList, setEventList] = useState<EventItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventParams = {
                    evtType: 'P', //진행 이벤트: P, 당첨자 발표 이벤트:W, 종료 이벤트:E
                    sortType: 'N', // 최신순: N, 마감일순: E,
                    bannerType: '141002', //이벤트배너(공통): 141001, 출석이벤트 배너: 141002
                    targetAge: await getAgeCode(),   // 119001: 10대 미만, 119002: 10대, 119003: 20대, 119004: 30대, 119005: 40대, 119006: 50대 이상
                    targetGender: (await getItemByKey('gender')) || 'A',
                    categoryId: '',
                    searchType: '',
                    searchText: '',
                    pageNo: '',
                }
                const [attendance, eventBanner] = await Promise.allSettled([
                    attendanceApi.get('v1'),
                    eventApi.getEvent(eventParams, 'v1'),
                ]);
                setAttendance(handlePromiseResult(attendance, initialAttendance));
                const eventResponse = handlePromiseResult(eventBanner, {evtType: '', eventList: []});
                setEventList(eventResponse.eventList);

            } catch (error) {
                console.error('Failed to fetch :', error);
            } finally {
                setIsLoading(false);
                await gtmPageLoad({
                    url: '/mission/attendance',
                    contentGroup: '미션|출석체크',
                    moduleName: '중간 배너|혜택',
                    pageTitle: `${Number(attendance.yearMonth.slice(4, 6))}월 출석체크 이벤트`
                });
            }
        };
        fetchData();
    }, []);

    const handleSetEventBanner = (eventItem: EventItem) => {
        // setEventList(e);
    }

    return (
        <>
            {useLoading(isLoading)}
            {/*TODO: api 파싱 필요*/}
            <div style={{paddingBottom: '70px'}}>
                <CommonRightIconHeader
                    title={`${Number(attendance.yearMonth.slice(4, 6))}월 출석체크 이벤트`}
                    icon='close_line'
                    onClick={() => {
                        navigate(-1);
                    }}
                />

                <Calender attendance={attendance} weekOrMonth={'month'}/>
                <CommonDivider/>

                <EventBlock winnerEventLink={attendance.winnerEventLink} infoEventLink={attendance.infoEventLink}/>
                <CommonDivider/>

                <EventList eventList={eventList}/>
            </div>

            <NavigationBar selectedItem={'mission'} ref={navigationBarRef}/>
        </>
    );
};

export default MissionAttendance;
