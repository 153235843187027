import React, {useEffect, useState} from 'react';
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionStepper from "../../components/Badge/SectionStepper";
import SectionCertified from "../../components/Badge/SectionCertified";
import SectionAccordion2 from "../../components/Badge/SectionAccordion2";
import {Button, CTA, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from "react-router-dom";
import nftApi from "../../api/gatewayApi/nft";
import {formatPhoneNumber} from "../../utils/helper";
import CommonPopup from "../../components/Common/CommonPopup";
import {gtmBadgeSendComp, gtmPageLoad} from "../../api/gtm";
import {isValidPhoneNumber} from "../IdentityVerification/validators";
import {generateMCP_TR_KEY} from "../../api/authFunctions";
import {getItemByKey} from "../../utils/indexedDB";

// 카테고리 ID와 이름 매핑
const categoryIdToName: { [key: string]: Badge['categoryName'] } = {
    '0': '나들이킹',
    '1': '쇼핑킹',
    '2': '푸드킹',
    '3': '콘텐츠킹',
    '4': '스터디킹',
    '5': '일상킹',
    '6': '뷰티킹',
};

// Badge 데이터 타입 정의
export interface Badge {
    categoryId: string;
    categoryName: '나들이킹' | '쇼핑킹' | '스터디킹' | '뷰티킹' | '콘텐츠킹' | '일상킹' | '푸드킹';
    count: number;
}

const GiftBadge: React.FC = () => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    const [phoneNumber, setPhoneNumber] = useState('');
    const [maxCounts, setMaxCounts] = useState<Partial<Record<Badge['categoryName'], number>>>({});
    const [badgeData, setBadgeData] = useState<Badge[]>([]);
    const [supportText, setSupportText] = useState<any[]>([]);
    const [receivable, setReceivable] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [activeAuthButton, setActiveAuthButton] = useState<boolean>(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await nftApi.getAvailableGiftList({}, "v1"); // 실제 API 호출 부분
                const nftUserSendList = response.body.nftUserSendList;
                console.log(nftUserSendList);

                // maxCounts 생성 및 badgeData 생성
                const fetchedMaxCounts: Partial<Record<Badge['categoryName'], number>> = {};
                const initialBadgeData: Badge[] = [];

                nftUserSendList.forEach((item: any) => {
                    const categoryId = item.categoryId;
                    const categoryName = categoryIdToName[categoryId] || item.categoryName;
                    const count = parseInt(item.count, 10);
                    fetchedMaxCounts[categoryName] = count;

                    initialBadgeData.push({
                        categoryId: categoryId,
                        categoryName: categoryName as Badge['categoryName'],
                        count: 0,  // 초기 카운트는 0으로 설정
                    });
                });

                setMaxCounts(fetchedMaxCounts);
                setBadgeData(initialBadgeData);
            } catch (e) {
                console.log(e);
            } finally {
                await gtmPageLoad({
                    url: '/my/badge/gift',
                    contentGroup: '마이|내 배지|배지 선물하기',
                    moduleName: '',
                    pageTitle: '배지 선물하기'
                });
            }
        };

        fetchData();
    }, []);

    const handleGift = async () => {
        const categoryIds: string[] = [];
        const counts: string[] = [];

        badgeData
            .filter(badge => badge.count > 0)  // count가 0이 아닌 것만 필터링
            .forEach(badge => {
                categoryIds.push(badge.categoryId);     // 실제 categoryId를 배열에 추가
                counts.push(badge.count.toString());    // count를 문자열로 변환하여 배열에 추가
            });

        const requestBody = {
            categoryId: categoryIds,  // categoryId 배열
            count: counts             // count 배열
        };
        const ctn = formatPhoneNumber(phoneNumber);

        try {
            const mcpTrKey = generateMCP_TR_KEY();
            const response = await nftApi.getSend(
                requestBody,
                "v1",
                mcpTrKey,
                {toCTN: {value: ctn, encrypt: true}}
            );
            if (response.header.resultCode === "0000") {
                gtmBadgeSendComp(badgeData);

                // navigate 시 변경된 badgeData와 함께 전달
                navigate('/my/badge', {
                    state: {
                        updatedBadges: badgeData  // 변경된 badge 데이터를 전달
                    }
                });
            }
            console.log('API 응답:', response);
        } catch (error) {
            console.error('API 호출 오류:', error);
        }
    };

    const isGiftButtonEnabled = () => {
        return badgeData.some(badge => badge.count > 0) && receivable
    };
    const handlePhoneNumberChange = (event: any) => {
        const newPhoneNumber = event.value;
        setPhoneNumber(newPhoneNumber);
        setSupportText([{}])
        setReceivable(false)
        if (isValidPhoneNumber(newPhoneNumber)) {
            setActiveAuthButton(true)
        } else {
            setActiveAuthButton(false)
        }
    };

    const handleCertification = async () => {
        console.log(phoneNumber)
        try {
            const mcpTrKey = generateMCP_TR_KEY()
            let myCtn = await getItemByKey("ctn")
            const ctn = formatPhoneNumber(phoneNumber)
            if (ctn === myCtn) {
                const supportTextProps = [{
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: `자신에게는 선물할 수 없습니다.`
                }]
                setReceivable(false)
                setSupportText(supportTextProps)
            } else {
                const response = await nftApi.getCheckReceiver({}, "v1", mcpTrKey, {
                    toCTN: {
                        value: ctn,
                        encrypt: true
                    },
                });
                const isUplus = response.body.isUplus
                const totalBadgeCount = badgeData.reduce((total, badge) => total + badge.count, 0);
                if (isUplus === "Y") {
                    if (totalBadgeCount > Number(response.body.count)) {
                        const supportTextProps = [{
                            icon: 'error_circle_filled',
                            status: 'error',
                            text: `받는 분이 획득 가능한 배지보다 보내는 배지가 많습니다. 배지 개수를 확인해주세요. ${response.body.count}개 전송 가능`
                        }]
                        setReceivable(false)
                        setSupportText(supportTextProps)
                    } else if (response.body.count === "0") {
                        const supportTextProps = [{
                            icon: 'error_circle_filled',
                            status: 'error',
                            text: `받는 분의 배지함이 가득 찼습니다.`
                        }]
                        setReceivable(false)
                        setSupportText(supportTextProps)
                    } else {
                        const supportTextProps = [{
                            icon: 'check_circle_filled',
                            status: 'success',
                            text: "배지를 선물할 수 있어요."
                        }]
                        setReceivable(true)
                        setSupportText(supportTextProps)
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }

    };

    return (
        <>
            <CommonRightIconHeader icon={"close_line"} title={"배지 선물하기"} onClick={handleClose}/>

            <div
                style={{
                    padding: "16px 20px",
                    backgroundColor: "#F3F5F6",
                }}
            >
                <Text typo="font_body_2M" color="color_text_neutral_3">
                    배지는 하루 한 번, U+ 고객에게만 보낼 수 있습니다.<br/>
                    배지 개수는 여러 개를 한꺼번에 보낼 수 있습니다.
                </Text>
            </div>

            <SectionStepper
                setTotalCount={setTotalCount}
                totalCount={totalCount}
                maxCounts={maxCounts}
                badgeData={badgeData}
                setBadgeData={setBadgeData}
                setReceivable={setReceivable}
            />

            <SectionCertified onClick={handleCertification} supportText={supportText}
                              onChange={handlePhoneNumberChange} isAuthActive={activeAuthButton}/>

            <div style={{margin: "16px 0 8px"}}>
                <CommonDivider/>
            </div>

            <SectionAccordion2/>

            <CTA layout="column">
                <Button
                    color={isGiftButtonEnabled() ? "primary" : "neutral"}
                    size="large"
                    disabled={!isGiftButtonEnabled()} // badgeData의 count가 전부 0이면 disable 처리
                    style={{marginTop: "12px"}}
                    onClick={() => setOpenPopup(true)}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'마이|내 배지|배지선물하기 - 링크 클릭'}
                    data-gtm-event-label={`컨텐츠 : 선물하기`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={'마이|내 배지|배지선물하기'}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`선물하기`}
                >
                    선물하기
                </Button>
            </CTA>
            <CommonPopup
                isOpen={openPopup}
                description={`${phoneNumber}님께\n배지 ${totalCount}개를 선물할까요?\n선물은 하루에 한번 만 가능해요.`}
                onSubmit={handleGift}
                submitText={"선물하기"}
                onClose={() => setOpenPopup(false)}
                cancelText={"취소"}
            />
        </>
    );
}

export default GiftBadge;
