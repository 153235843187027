import React, {useEffect, useRef, useState} from 'react';
import {IconButton, Tag, Text} from '@lguuxe/2024_designsystem_uni';
import Barcode from 'react-barcode';
import {BarcodeStatus, MembershipBarcode} from './types';
import BarcodeFailed from './BarcodeFailed';
import BarcodeTimeOut from './BarcodeTimeOut';
import {formatBarcode} from './utils';
import BarcodeLoading from "./BarcodeLoading";
import {getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../../provider/SnackbarProvider";
import BarcodeOther from "./BarcodeOther";

interface MembershipBarcodeSectionProps {
    barcode: MembershipBarcode;
    onChange: (status: BarcodeStatus) => void;
    popup?: boolean;
}

const MembershipBarcodeSection: React.FC<MembershipBarcodeSectionProps> = ({barcode, onChange, popup = false}) => {
    const [time, setTime] = useState(15);
    const [loadingFailed, setLoadingFailed] = useState(false);
    const [memberStatus, setMemberStatus] = useState('0');
    const [isFold, setIsFold] = useState(false);
    const [barcodeWidth, setBarcodeWidth] = useState(2.0);
    const barcodeContainerRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        const fetchIndexedDb = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchIndexedDb();
    }, []);


    useEffect(() => {
        const updateBarcodeWidth = () => {
            if (barcodeContainerRef.current) {
                const containerWidth = barcodeContainerRef.current.offsetWidth;
                setBarcodeWidth(containerWidth / 120); // 바코드에 맞는 적절한 비율로 너비 계산 (숫자는 비율에 맞게 조정)
            }
            setIsFold(window.innerWidth > 500);
        };
        updateBarcodeWidth();
        window.addEventListener('resize', updateBarcodeWidth);

        // 바코드가 ready일 경우 타이머를 시작한다.
        if (barcode.barcode !== '' && barcode.status === 'ready') {
            onChange('running');
            const nowTime = new Date(barcode.barcodeTime - new Date().getTime());
            setTime(nowTime.getMinutes() * 60 + nowTime.getSeconds());
            const timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 1) {
                        return prevTime - 1;
                    } else {
                        clearInterval(timer);
                        onChange('init');
                        return 0;
                    }
                });
            }, 1000);
            return () => {
                clearInterval(timer);
                window.removeEventListener('resize', updateBarcodeWidth);
            }
        }
        // TODO: 바코드가 running일 경우 타어머에 현재 time을 설정한다.
    }, [barcode]);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(barcode.barcode)
            .then(() => {
                showSnackbar('바코드 번호를 복사했어요.');
            })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    };

    const handleGenerateBarcode = () => {
        onChange('ready');
    }

    if (memberStatus === '5') {
        return <BarcodeOther/>
    }

    if (barcode.status === 'fail') {
        return <BarcodeFailed onGenerateBarcode={handleGenerateBarcode}/>;
    }

    if (barcode.status === 'init') {
        return <BarcodeTimeOut onGenerateBarcode={handleGenerateBarcode} tabType='membership'/>;
    }

    return (
        <>
            <div
                style={{
                    border: !popup ? '2px solid transparent' : "",
                    borderRadius: '12px',
                    backgroundImage: !popup ? 'linear-gradient(#fff, #fff), linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)' : "",
                    borderImageSlice: '1',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                    width: '100%',
                    height: '155px',
                    boxSizing: 'border-box',
                }}
            >
                <div
                    style={{
                        padding: '16px 20px',
                    }}
                >
                    <Tag
                        item={[
                            {
                                colorToken: 'gradient_primary',
                                label: '할인 바코드',
                                size: 'small',
                                style: 'solid',
                            },
                        ]}
                    />
                    <div
                        style={{
                            ...(isFold ? { // isFold가 false일 때만 스타일 적용
                                width: '280px', // 너비를 280px로 설정
                                margin: '0 auto', // 가운데 정렬
                                textAlign: 'center', // 텍스트 정렬
                            } : {}),
                        }}
                    >
                        <div ref={barcodeContainerRef}
                             style={{
                                 display: "flex",
                                 justifyContent: 'center',
                                 width: '100%',
                                 height: '56px',
                                 margin: '12px auto 0',
                                 boxSizing: "border-box",
                             }}
                             data-gtm-event-name={'all_click'}
                             data-gtm-event-category={'클릭'}
                             data-gtm-event-action={'바코드|멤버십 - 링크 클릭'}
                             data-gtm-event-label={'컨텐츠 : 멤버십 바코드'}
                             data-gtm-click-url={''}
                             data-gtm-click-location={'바코드|멤버십'}
                             data-gtm-click-direction={'내부'}
                             data-gtm-click-text={'멤버십 바코드'}
                        >
                            {barcode.status === 'loading' ? <BarcodeLoading/> :
                                <Barcode value={barcode.barcode} displayValue={false}
                                         height={popup ? 70 : 56}
                                         width={popup ? 3 : barcodeWidth}
                                         margin={0}
                                />}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                {formatBarcode(barcode.barcode).map((chunk, index) => (
                                    <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                        {chunk}
                                    </Text>
                                ))}
                                {!popup && <IconButton icon='copy_line' size='small' fill='icon' onClick={handleCopy}/>}
                            </div>

                            {/*<CommonDividerVertical/>*/}
                            {/*<Button color='primary' fill='text' onClick={handleGenerateBarcode}>*/}
                            {/*    {formatTime(time)}*/}
                            {/*    <Icon*/}
                            {/*        name='refresh_line'*/}
                            {/*        size='16px'*/}
                            {/*        color='#E6007E'*/}
                            {/*        style={{*/}
                            {/*            marginLeft: '2px',*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MembershipBarcodeSection;
