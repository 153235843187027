import React, {useEffect, useState} from "react";
import {TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import HeadingCard from "../../components/Vipkok/HeadingCard";
import BoxHeadingUserGrade from "../../components/Vipkok/BoxHeadingUserGrade";
import BottomInfoBox from "../../components/Vipkok/BottomInfoBox";
import {History, VipStatus} from "./types";
import useLoading from "../../hooks/useLoading";
import {handlePromiseResult} from "../../api/api";
import {brazeEventApi} from "../../api/brazeBridge";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import {CategoryItem, LikedPartners, SelectedPartnerCategory, TotalPartners} from "../Partner/types";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import CategoryTabs from "../../components/Partner/CategoryTabs";
import PartnerList from "../../components/Partner/PartnerList";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import MembershipsService from "../../api/gatewayApi/memberships-service";
import BarcodeLoading from "../../components/Barcode/BarcodeLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import {gtmPageLoad} from "../../api/gtm";


const initialHistory: History = {
    totalCnt: '',
    pointUseList: [],
};

const initPartners: TotalPartners = {
    totalCount: '0',
    partnerList: [],
};

const initialLikedPartners: LikedPartners = {
    ptnList: [],
};

const VipKok: React.FC = () => {
    const [history, setHistory] = useState<History>(initialHistory);
    const [likedPartners, setLikedPartners] = useState<LikedPartners>(initialLikedPartners);
    const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
    const [partners, setPartners] = useState<TotalPartners>(initPartners);
    const [selectedCategory, setSelectedCategory] = useState<SelectedPartnerCategory>({
        categoryName: '전체',
        depth1: '',
        depth2: '',
        depth3: ''
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [useVIPkok, setUseVIPkok] = useState<string>();
    const [cardLevel, setCardLevel] = useState<string>();
    const [userStatus, setUserStatus] = useState<string>();
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            const currentYear = new Date().getFullYear();
            let list;
            try {
                setCardLevel(await getItemByKey('cardLevel'));
                setUseVIPkok(await getItemByKey('useVipkokYn'));
                setUserStatus(await getItemByKey('memberStatus'));

                const thisMonthRes = await MembershipsService.getVipBenefitThisMonth('v1');
                if (thisMonthRes.body.useVipkok && thisMonthRes.body.useVipkok.length > 0) {
                    await addItem('useVipkokYn', thisMonthRes.body.useVipkok[0].isUsed);
                    setUseVIPkok(thisMonthRes.body.useVipkok[0].isUsed);
                    await addItem('vipkok', {
                        ptnId: thisMonthRes.body.useVipkok[0].ptnId,
                        ptnName: thisMonthRes.body.useVipkok[0].ptnName,
                        isUsed: thisMonthRes.body.useVipkok[0].isUsed
                    })
                } else {
                    await addItem('useVipkokYn', 'N');
                    setUseVIPkok('N');
                }

                const promises = [partnerApi.getCategories('v1')];
                const memberId = await getItemByKey('memberId');
                const shouldFetchHistory = memberId !== null && memberId !== undefined && memberId !== "";

                if (shouldFetchHistory) {
                    promises.push(membershipsServiceApi.vipKok.getUseHistory({year: currentYear}, 'v1'));
                }

                const [categoryListResult, historyResult] = await Promise.allSettled(promises);

                // categoryList 설정
                list = handlePromiseResult(categoryListResult).categoryList;
                list.unshift({categoryId: '', categoryName: '전체', categoryType: ''});
                setCategoryList(list);

                // 조건을 만족하여 호출된 경우에만 history 설정
                if (shouldFetchHistory && historyResult) {
                    setHistory(handlePromiseResult(historyResult, initialHistory));
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false); // 로딩 상태 해제
                await gtmPageLoad({url: '/vip-kok', contentGroup: '마이|VIP콕', moduleName: '', pageTitle: 'VIP콕'});
            }
        };

        fetchData();
        brazeEventApi.vipKok_info_enter();
    }, []);


    useEffect(() => {
        fetchPartners(1);
    }, [selectedCategory]);

    // 좋아요 버튼이 있을때 사용
    // const handleLike = (ptnId: string, nowIsLike: boolean) => {
    //     if (nowIsLike) {
    //         const newPartnerList = partners.partnerList.map((item) => {
    //             if (item.ptnId === ptnId) {
    //                 return {...item, isLike: 'N', likeNum: (Number(item.likeNum) - 1).toString()};
    //             }
    //             return item;
    //         });
    //         setPartners({...partners, partnerList: newPartnerList});
    //
    //         // 좋아요 제휴처 목록에서 해당 제휴처 삭제, 제휴처 목록에서 좋아요 상태 변경
    //         const newLikedPartnerList = likedPartners.ptnList = likedPartners.ptnList.filter((item) => item.ptnId !== ptnId);
    //         setLikedPartners({ptnList: newLikedPartnerList});
    //     } else { // 좋아요 제휴처 목록에 해당 제휴처 추가, 제휴처 목록에서 좋아요 상태 변경
    //         const newPartnerList = partners.partnerList.map((item) => {
    //             if (item.ptnId === ptnId) {
    //                 return {...item, isLike: 'Y', likeNum: (Number(item.likeNum) + 1).toString()};
    //             }
    //             return item;
    //         });
    //         setPartners({...partners, partnerList: newPartnerList});
    //
    //         partners.partnerList.filter((item) => item.ptnId === ptnId).map((item) => {
    //             setLikedPartners({ptnList: [...likedPartners.ptnList, item]});
    //         });
    //     }
    // }

    const fetchPartners = async (pageParam?: number) => {
        const currentPage = pageParam || page;

        try {
            const request = {
                cateDepth1: selectedCategory.depth1,
                vipKockYn: "Y",
                pageNo: currentPage.toString()
            }
            const response = await partnerApi.getPartners(request, 'v1')
            if (response.header.resultCode === '0000' && response.body) {
                if (currentPage === 1) {
                    setPartners(response.body)
                    setPage(2)
                } else {
                    const newPartnerList = partners.partnerList.concat(response.body.partnerList)
                    setPartners({...response.body, partnerList: newPartnerList})
                    setPage(currentPage + 1)
                }

                if (response.body.partnerList.length === 0) {
                    setHasMore(false)
                }
            }
        } catch (error) {
            console.error('Failed to fetch :', error)
        }
    }

    const getVipStatus = () => {
        switch (userStatus) {
            case "0":
                return VipStatus.NOT_LOGGED_IN
            case "1":
                return useVIPkok === "Y" ? VipStatus.VIP_USED : VipStatus.VIP_NOT_USED
            case "2":
                return VipStatus.EXCELLENT
            case "3":
                return useVIPkok === "Y" ? VipStatus.VIP_USED : VipStatus.VIP_NOT_USED
            case "4":
                return VipStatus.EXCELLENT
            case "5":
                return VipStatus.GENERAL
            default:
                return VipStatus.GENERAL
        }
    }
    const handleCategoryChange = async (category: SelectedPartnerCategory) => {
        setSelectedCategory(category);
        setHasMore(true);
    };

    return (
        <>
            {useLoading(isLoading)}

            <InfiniteScroll next={fetchPartners} hasMore={hasMore}
                            loader={<BarcodeLoading/>}
                            dataLength={Number(partners.partnerList.length)}
                            scrollableTarget={"window"}
            >
                <CommonLeftIconHeader title={"VIP콕"}/>

                <TextSet
                    subComponent="Heading_2"
                    weight="bolder"
                    text={{
                        title: (
                            <>
                            <span className="qaz">
                                <span style={{color: "#E6007E"}}>VVIP/VIP</span> 고객을 위한 <br/>
                                <span style={{display: 'inline-block'}}>특별한 혜택!</span>
                            </span>
                            </>
                        ),
                        description: (
                            <span>
                            내가 선택한 제휴사에서 더 큰 할인 혜택을 받아보세요.<br/>매달 1회 혜택을 받을 수 있어요.
                        </span>
                        ),

                    }}
                    blockSpacing={{top: "24px", bottom: "12px"}}
                    isIndent={true}
                />

                {/* 내 VIP콕 */}
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: (
                            <>
                                <span className="qaz">내 VIP콕</span>
                            </>
                        ),
                    }}
                    blockSpacing={{top: "24px", bottom: "12px"}}
                    isIndent={true}
                />
                <BoxHeadingUserGrade status={getVipStatus()}/>

                {/* 최근 사용 */}
                {history !== initialHistory && (
                    <HeadingCard history={history}/>
                )}
                {/* N월의 VIP 콕 혜택 */}
                <CategoryTabs title={`${new Date().getMonth() + 1}월의 VIP콕`} itemList={categoryList}
                              onItemChange={handleCategoryChange}/>
                <PartnerList partners={partners} type={"VIP"} categoryName={selectedCategory.categoryName}/>

            </InfiniteScroll>
            {/*하단 Infobox*/}
            <div style={{paddingTop: "20px"}}/>
            <BottomInfoBox/>

        </>
    );
};

export default VipKok;
