// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력()
// ****************************************************
import React, {useEffect, useState} from 'react';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import CommonDivider from '../../components/Common/CommonDivider';
import BenefitGraphText from '../../components/My/BenefitGraphText';
import BenefitUseList from '../../components/My/BenefitUseList';
import BenefitGraph from '../../components/My/BenefitGraph';
import membershipsServiceApi from '../../api/gatewayApi/memberships-service';
import {BenefitSummery, initialBenefitSummary, initialMonthUseHistory, MonthUseHistory} from './types';
import {getItemByKey} from "../../utils/indexedDB";
import useLoading from "../../hooks/useLoading";
import {gtmPageLoad} from "../../api/gtm";


const MyBenefit = () => {
    const [benefitSummary, setBenefitSummary] = useState<BenefitSummery>(initialBenefitSummary);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monthUseHistory, setMonthUseHistory] = useState<MonthUseHistory>(initialMonthUseHistory);

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        fetchData(`${year}${month < 10 ? '0' + month : month}`);
        window.scrollTo(0, 0);
    }, []);

    const fetchData = async (yearMonth: string) => {
        setIsLoading(true);
        const birthday = await getItemByKey('birthday');
        try {
            const historyRes = await membershipsServiceApi.getUseHistory(yearMonth, 'v1');
            if (historyRes.header.resultCode === '0000' && historyRes.body) {
                const monthUseHistory: MonthUseHistory = historyRes.body;
                setMonthUseHistory(monthUseHistory);
                const benefitSummary = await membershipsServiceApi.getVipBenefitSummary({
                    userBirth: birthday,
                    totPoint: monthUseHistory.monthUsePoint,
                    yearMonth: yearMonth
                }, 'v1');
                if (benefitSummary.header.resultCode === '0000' && benefitSummary.body) {
                    setBenefitSummary(benefitSummary.body);
                }
            }
        } catch (error) {
            console.log('Failed to fetch :', error);
        } finally {
            setIsLoading(false);
            await gtmPageLoad({
                url: '/my/benefit',
                contentGroup: '마이|내 혜택 이력',
                moduleName: '',
                pageTitle: '내가 받은 헤택'
            });
        }
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title='내가 받은 혜택'/>

            <BenefitGraph benefitSummary={benefitSummary} onDateChange={fetchData}/>

            <BenefitGraphText benefitSummary={benefitSummary}/>

            <div style={{marginBottom: '8px'}}>
                <CommonDivider/>
            </div>
            <BenefitUseList monthUseHistory={monthUseHistory}/>
        </>
    );
};

export default MyBenefit;
