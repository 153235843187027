import React from 'react';
import {Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {RecommendPartner} from "../../pages/My/types";
import defaultThumbnail from "../../images/default/default thumbnail.svg";
import {useNavigate} from "react-router-dom";

interface RecommendPartnersProps {
    recommendList: RecommendPartner[];
    ptnName: string;
}

const RecommendPartners: React.FC<RecommendPartnersProps> = ({recommendList, ptnName}) => {
    const navigate = useNavigate();

    const handleClick = (item: RecommendPartner) => {
        navigate(`/partners/${item.ptnId}`);
    }

    return (
        <div style={{
            display: 'flex',
            gap: '0px',
            // alignItems: 'center',
            flexDirection: 'column',
            padding: '0',
        }}>
            <div style={{
                width: '100%',
                backgroundColor: '#FFFFFF',
            }}>
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: `[${ptnName}]에 관심있는 고객님을 위한 추천`,
                        // description: "등급별 혜택정보 설명 설명 설명 설명 설명 설명 등급별 혜택정보 설명 설명 설명 설명 설명 설명"
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            </div>

            <List
                horizontalItemWidth='84px'
                style={{
                    gap: '0.75rem',
                    padding: "1rem 1.25rem 1rem 1.25rem",
                    width: "calc(100% - 2.5rem)",
                }}
                fill='none'
                name='list_name'
                size='large'
                type='view'
                weight='default'
                items={recommendList.slice(0, 3).map((item, index) => ({
                    onClick: () => handleClick(item),
                    leftSlot: [
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '6px',
                                // marginRight: '-10px', // 각 아이템 사이에 오른쪽 간격 추가
                                // marginLeft: '-10px', // 각 아이템 사이에 왼쪽 간격 추가
                            }}
                        >
                            <Image
                                src={item.ptnLogoImgUrl}
                                alt='제휴이미지'
                                width='56'
                                height='auto'
                                rounded={54.86}
                                srcFallback={defaultThumbnail}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: '84px',
                                }}
                            >
                                <Text typo='font_body_1B' color='color_text_neutral_6' style={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1, // 최대 2줄로 설정
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal",
                                }}>
                                    {item.ptnName}
                                </Text>
                            </div>
                        </div>

                    ],
                    dataAttributes: {
                        'data-gtm-event-name': 'all_click',
                        'data-gtm-event-category': '클릭',
                        'data-gtm-event-action': `제휴사 추천 - 링크 클릭`,
                        'data-gtm-event-label': `컨텐츠 : ${item.ptnName}`,
                        'data-gtm-click-url': '',
                        'data-gtm-click-location': `제휴사 추천`,
                        'data-gtm-click-direction': '내부',
                        'data-gtm-click-text': item.ptnName,
                    }
                }))}
            />


            {/*<div*/}
            {/*    style={{*/}
            {/*        textAlign: 'center',*/}
            {/*        borderTop: '1px solid #E7EBEE',*/}
            {/*        marginTop: '12px',*/}
            {/*        width: '100%',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        color='neutral'*/}
            {/*        rightIcon='chevron_right_small_line'*/}
            {/*        size='large'*/}
            {/*        fill='text'*/}
            {/*        style={{*/}
            {/*            padding: '14px 0',*/}
            {/*            width: '100%',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        더보기*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
};

export default RecommendPartners;
