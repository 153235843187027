import {Box, Button, Checkbox, CTA, Image, Popup, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
// import ExImg from "../../images/default/default_Upltwopl_full.svg";
import ExxImg from "../../images/default/default_uplus_thumb.svg";

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {sendEventReturn} from "../../api/nativeBridge";
import {useSwipeable} from 'react-swipeable';
import otherApi from "../../api/gatewayApi/other";


interface CommonPopupWithImageProps {
    popupList?: Array<{ imageUrl: string; url: string; [key: string]: any }>;
    setLandingUrl?: Dispatch<SetStateAction<string[]>>;
    onSubmit: () => void;
    onClose?: () => void;
    submitText: string;
    cancelText?: string;
    showCheckbox?: boolean;
    imageUrl: string;
    title?: string;
    description?: string;
    onImageClick?: () => void;
    onCheckbox?: () => void;
    isOpen?: boolean;
    adLogo?: string;
    logoLanding?: string;
    gtmType?: string; // ga 태깅을 위한 식별 타입
    bottomTitle?: string;
}

const CommonPopupWithImage: React.FC<CommonPopupWithImageProps> = ({
                                                                       popupList,
                                                                       setLandingUrl,
                                                                       onClose,
                                                                       onSubmit,
                                                                       submitText,
                                                                       cancelText,
                                                                       showCheckbox = false,
                                                                       imageUrl,
                                                                       title,
                                                                       description,
                                                                       onImageClick,
                                                                       onCheckbox,
                                                                       isOpen,
                                                                       adLogo,
                                                                       gtmType,
                                                                       logoLanding,
                                                                       bottomTitle
                                                                   }) => {
    const [isChecked, setIsChecked] = useState(false)
    const hasText = title || description;
    const [isFold, setIsFold] = useState(window.innerWidth > 650);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleResize = () => {
        setIsFold(window.innerWidth > 650);
    };

    useEffect(() => {
        console.log("popupList", popupList)
        console.log("setLandingUrl", setLandingUrl)
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmitButton = () => {
        if (isChecked) {
            if (onCheckbox) {
                onCheckbox();
            }
        }
        if (onSubmit) {
            onSubmit();
        }
    };

    const handleImageClick = () => {
        if (popupList && popupList[currentIndex]) {
            const currentPopup = popupList[currentIndex];
            otherApi.postStatHistory({historyType: '163001', historyId: currentPopup.popupId, historyName: '', bannerType: '', caller: ''}, 'v1')
            // onImageClick 콜백 실행
            if (onImageClick) {
                onImageClick();
            }
        }
    };

    const handleCheckBox = () => setIsChecked((prev) => !prev);

    const handleNext = () => {
        setCurrentIndex((prev) => {
            const nextIndex = popupList && prev < popupList.length - 1 ? prev + 1 : prev;
            if (popupList && setLandingUrl) {
                const {imgUrl, linkType, linkUrl, popupTitle} = popupList[nextIndex];
                setLandingUrl([linkType, linkUrl, popupTitle || imgUrl]);
            }
            return nextIndex;
        });
    };

    const handlePrev = () => {
        setCurrentIndex((prev) => {
            const prevIndex = prev > 0 ? prev - 1 : prev;
            if (popupList && setLandingUrl) {
                const {imgUrl, linkType, linkUrl, popupTitle} = popupList[prevIndex];
                setLandingUrl([linkType, linkUrl, popupTitle || imgUrl]);
            }
            return prevIndex;
        });
    };
    const handleLogoClick = () => {
        sendEventReturn('EXTERNAL_CALL', {type: '1', url: logoLanding});
    }

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventScrollOnSwipe: true,
        trackMouse: true
    });


    return (
        <Popup isOpen={isOpen === undefined ? true : isOpen} onClose={onClose ?? undefined} padding={false}
               width={isFold ? "calc(50%)" : undefined} style={{zIndex: '1001'}}>
            <Popup.CenterSlot>
                <Box
                    style={{
                        padding: hasText ? '20px 0 0 0' : '0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {hasText ? (
                        <>
                            <TextSet
                                subComponent="Heading_3"
                                text={{
                                    title: title,
                                }}
                                weight="bolder"
                            />
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <>
                                            <span style={{paddingTop: '12px', textAlign: 'center'}}>
                                                {description?.split('\\n').map((line, index) => (
                                                    <>
                                                        {line}
                                                        <br/>
                                                    </>
                                                ))}
                                            </span>
                                        </>
                                    ),
                                }}
                                weight="default"
                            />
                            <div style={{marginTop: '20px', position: 'relative'}}>
                                <Image
                                    height="auto"
                                    srcFallback={ExxImg}
                                    src={imageUrl}
                                    width="100%"
                                    onClick={onImageClick}
                                />
                                {adLogo && (
                                    <img
                                        src={adLogo}
                                        alt="Logo"
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            width: '20px',
                                            height: '20px',
                                        }}
                                        onClick={handleLogoClick}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            {...handlers}
                            style={{
                                position: 'relative',
                                width: '100%',
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: "column",
                                touchAction: 'pan-y', // 수평 스크롤 방지
                                minWidth: '320px',
                                minHeight: '250px',
                                justifyContent: 'center',
                            }}
                            onTouchStart={(e) => e.stopPropagation()}
                            onTouchMove={(e) => e.stopPropagation()}
                            onTouchEnd={(e) => e.stopPropagation()}
                        >
                            {gtmType === 'ad' && popupList && popupList.length > 0 ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        transform: `translateX(-${currentIndex * 100}%)`,
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                >
                                    {popupList.map((item, index) => (
                                        <div key={index} style={{flex: '0 0 100%'}}>
                                            <Image
                                                src={item.imgUrl}
                                                srcFallback={ExxImg}
                                                alt={`popup image ${index + 1}`}
                                                width="100%"
                                                onClick={handleImageClick}
                                                dataAttributes={{
                                                    'data-gtm-event-name': 'all_click',
                                                    'data-gtm-event-category': '클릭',
                                                    'data-gtm-event-action': '마케팅 팝업 - 링크 클릭',
                                                    'data-gtm-event-label': `컨텐츠 : 이미지`,
                                                    'data-gtm-click-url': '',
                                                    'data-gtm-click-location': '마케팅 팝업',
                                                    'data-gtm-click-direction': '외부',
                                                    'data-gtm-click-text': '이미지',
                                                    'data-gtm-mkt-event-id': item.popupId,
                                                    'data-gtm-mkt-event-name': item.popupTitle,
                                                    'data-gtm-mkt_event_period': `${(() => {
                                                        try {
                                                            const startDate = item.dpStartDate?.split(' ')[0];
                                                            const endDate = item.dpEndDate?.split(' ')[0];

                                                            if (!startDate || !endDate) return '';

                                                            // 두 날짜의 차이 계산 (일 단위)
                                                            return `${startDate} ~ ${endDate}`
                                                        } catch (error) {
                                                            console.error('Error calculating date difference:', error);
                                                            return '';
                                                        }
                                                    })()}`,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <Image
                                    src={imageUrl}
                                    srcFallback={ExxImg}
                                    alt="popup image"
                                    width="100%"
                                    ratio="30:25"
                                    onClick={onImageClick}
                                />
                            )}
                            {/* 점(dot) 내비게이션 */}
                            {popupList && popupList.length > 1 && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {popupList.map((_, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                width: index === currentIndex ? '18px' : '6px',
                                                height: '6px',
                                                backgroundColor: '#66707A',
                                                borderRadius: index === currentIndex ? '24px' : '50%',
                                                margin: '0 3px',
                                                opacity: index === currentIndex ? '1' : '0.4',
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {adLogo && (
                                <img
                                    src={adLogo}
                                    alt="Logo"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '30px',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    onClick={handleLogoClick}
                                />
                            )}
                        </div>
                    )}
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                {bottomTitle &&
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px 0 0 0"
                    }}>
                        <Text typo="font_label_1Sb" color="color_text_neutral_6">
                            U+멤버십을 정말 종료하시겠어요?
                        </Text>
                    </Box>}
                {showCheckbox && (
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 0 0 0'
                    }}>
                        <Checkbox
                            label="다시보지 않기"
                            size="medium"
                            width="auto"
                            onChange={handleCheckBox}
                            data-gtm-event-name={gtmType === 'ad' ? 'all_click' : undefined}
                            data-gtm-event-category={gtmType === 'ad' ? '클릭' : undefined}
                            data-gtm-event-action={gtmType === 'ad' ? '마케팅 팝업 - 링크 클릭' : undefined}
                            data-gtm-event-label={gtmType === 'ad' ? `컨텐츠 : 다시보지 않기` : undefined}
                            data-gtm-click-url={gtmType === 'ad' ? '' : undefined}
                            data-gtm-click-location={gtmType === 'ad' ? '마케팅 팝업' : undefined}
                            data-gtm-click-direction={gtmType === 'ad' ? '내부' : undefined}
                            data-gtm-click-text={gtmType === 'ad' ? `다시보지 않기` : undefined}
                            data-gtm-mkt-event-id={gtmType === 'ad' ? '' : undefined}
                            data-gtm-mkt-event-name={gtmType === 'ad' ? '' : undefined}
                            data-gtm-mkt_event_period={gtmType === 'ad' ? '' : undefined}
                        />
                    </Box>
                )}
                <CTA gradation layout="row">
                    {cancelText && (
                        <Button color="neutral" size="large" onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                    <Button color="primary" size="large" onClick={handleSubmitButton}>
                        {submitText}
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default CommonPopupWithImage;
