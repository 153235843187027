import React from 'react';
import {Button, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import CommonDividerThin from '../Common/CommonDividerThin';

import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";
import {useNavigate} from "react-router-dom";
import {EventItem} from "../../pages/Mission/types";
import useLandingPage from "../../hooks/useLandingPage";
import otherApi from "../../api/gatewayApi/other";

interface EventListProps {
    eventList: EventItem[]
}

const EventList: React.FC<EventListProps> = ({eventList}) => {
    const {landingPage} = useLandingPage();
    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    const handleClick = (event: EventItem) => {
        otherApi.postStatHistory({
            historyType: '163003',
            historyId: event.evtId,
            historyName: '',
            bannerType: '141002',
            caller: ''
        }, 'v1')
        landingPage(event.evtTargetType, event.evtTargetLink, navigate, event.evtTitle);
    }

    const handleMore = () => {
        navigate('/event');
    };
    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>더 많은 선물을 받아가세요</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div style={{paddingBottom: '12px'}}>
                <List
                    fill='none'
                    name='list_name'
                    size='medium'
                    type='view'
                    weight='default'
                    items={eventList.map((item, index) => ({
                        id: item.evtId,
                        onClick: () => handleClick(item),
                        leftSlot: [
                            <>

                                {/*// <React.Fragment key={index}*/}
                                {/*//                 data-gtm-event-name={'all_click'}*/}
                                {/*//                 data-gtm-event-category={'클릭'}*/}
                                {/*//                 data-gtm-event-action={`${CLICK_LOCATION.BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}*/}
                                {/*//                 data-gtm-event-label={`컨텐츠 : ${item.evtTitle}`}*/}
                                {/*//                 data-gtm-click-url={''}*/}
                                {/*//                 data-gtm-click-location={`${CLICK_LOCATION.BENEFIT}`}*/}
                                {/*//                 data-gtm-click-direction={'내부'}*/}
                                {/*//                 data-gtm-click-text={`${item.evtTitle}`}>*/}
                                <Image customClass='customClass' ratio='1:1' rounded='4' src={item.evtImg}
                                       width='48' srcFallback={defaultThumbnail}/>
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        // eyebrow: (
                                        //     <>
                                        //         <Text typo='font_label_3Sb' color='color_text_neutral_3'>
                                        //             {item.evtTitle}
                                        //         </Text>
                                        //     </>
                                        // ),
                                        title: (
                                            <>
                                                <Text
                                                    typo='font_heading_3B'
                                                    color='color_text_neutral_5'
                                                    style={{fontSize: '16px', color: '#3B4044'}}
                                                >
                                                    {item.evtTitle}
                                                </Text>
                                            </>
                                        ),
                                    }}
                                />
                                {/*</React.Fragment>,*/}
                            </>
                        ],
                        linkIconOnlyId: item.evtId,
                        dataAttributes: {
                            'data-gtm-event-name': 'all_click',
                            'data-gtm-event-category': '클릭',
                            'data-gtm-event-action': '혜택 - 링크 클릭',
                            'data-gtm-event-label': `컨텐츠 : ${item.evtTitle}`,
                            'data-gtm-click-url': '',
                            'data-gtm-click-location': '혜택',
                            'data-gtm-click-direction': '내부',
                            'data-gtm-click-text': `${item.evtTitle}`,
                        }
                    }))}

                />
            </div>

            <div style={{padding: '0 0 4px'}}>
                <CommonDividerThin/>
            </div>

            <div
                style={{
                    padding: '0px 20px',
                }}
            >
                <Button
                    as='button'
                    color='secondary'
                    fill='text'
                    size='medium'
                    state='default'
                    rightIcon='chevron_right_small_line'
                    style={{width: '100%', padding: '15px 0'}}
                    onClick={handleMore}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.BENEFIT} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.BENEFIT_MORE}`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`${CLICK_LOCATION.BENEFIT}`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`혜택 더보기`}>
                    <Text typo='font_label_2Sb' color='color_text_neutral_3'>
                        더보기
                    </Text>
                </Button>
            </div>
        </>
    );
};

export default EventList;
