import React, {useEffect, useState} from 'react';
import {Accordion, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonDividerVertical from "../Common/CommonDividerVertical";
import CommonNodata from "../../components/Common/CommonNodata";
import dayjs from 'dayjs';
import {decrypt} from "../../api/authFunctions";
import {formatAndMaskPhoneNumber} from "../../utils/helper";

interface CategoryItem {
    categoryName: string;
    count: number;
}

interface HistoryItem {
    updateTime: string;
    type: string;
    categoryName?: string;
    serialNumber?: string;
    oppenent?: string;
    categoryList?: CategoryItem[];
    phoneNo?: string;
    decryptedPhoneNo?: string | null;
    titleText?: string;
}

interface SectionAccordionProps {
    historyList: HistoryItem[];
    mcpTrKey: string;
}

const SectionAccordion: React.FC<SectionAccordionProps> = ({historyList, mcpTrKey}) => {
    const [processedHistoryList, setProcessedHistoryList] = useState<HistoryItem[]>([]);

    useEffect(() => {
        const processHistoryList = async () => {
            const processedList = await Promise.all(
                historyList.map(async (history) => {
                    const decryptedResult = history.phoneNo ? await decrypt(mcpTrKey, history.phoneNo) : '';
                    const decryptedPhoneNo = decryptedResult ? formatAndMaskPhoneNumber(decryptedResult) : '';

                    // titleText 미리 설정
                    let titleText = "";
                    if (history.type === "발급" || history.type === "발급 취소") {
                        titleText = `${history.categoryName} 1개`;
                    } else if (history.type === "선물 보냄" || history.type === "선물 받음") {
                        const categoryList = history.categoryList || [];
                        if (categoryList.length === 1) {
                            const cat = categoryList[0];
                            titleText = `${cat.categoryName} ${cat.count}개`;
                        } else if (categoryList.length > 1) {
                            const firstCategoryName = categoryList[0].categoryName;
                            const remainingCount = categoryList.length - 1;
                            titleText = `${firstCategoryName} 외 ${remainingCount}종`;
                        }
                    }

                    return {
                        ...history,
                        decryptedPhoneNo,
                        titleText,
                    };
                })
            );

            setProcessedHistoryList(processedList);
        };

        processHistoryList();
    }, [historyList, mcpTrKey]);

    return (
        <>
            <div style={{paddingBottom: "12px"}}>
                {processedHistoryList.length === 0 ? (
                    <div style={{padding: "40px 0 40px 0"}}>
                        <CommonNodata type={"nftHistory"} text={"획득한 배지가 없어요"}/>
                    </div>
                ) : (
                    processedHistoryList.map((history, index) => {
                        let formattedDate = dayjs(history.updateTime, "YYYYMMDD").format("YYYY.MM.DD");
                        console.log(formattedDate)
                        if(formattedDate === "2024.12.12") {
                            formattedDate = "2024.12.11"
                        }
                        const description = (
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: "",
                                    description: (
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <span>{formattedDate}</span>
                                            <CommonDividerVertical/>
                                            <span>{history.type}</span>
                                        </div>
                                    ),
                                }}
                            />
                        );

                        let accordionBody;
                        if (history.type === "발급" || history.type === "발급 취소") {
                            accordionBody = (
                                <Accordion.Body>
                                    <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                        <Text style={{flex: "1"}} typo="font_body_2M" color="color_text_neutral_4">
                                            시리얼번호
                                        </Text>
                                        <Text typo="font_body_2M" color="color_text_neutral_6">
                                            {history.serialNumber}
                                        </Text>
                                    </div>
                                </Accordion.Body>
                            );
                        } else if (history.type === "선물 보냄" || history.type === "선물 받음") {
                            accordionBody = (
                                <Accordion.Body>
                                    <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                            <Text style={{flex: "1"}} typo="font_body_2M" color="color_text_neutral_4">
                                                {history.type === "선물 보냄" ? "받은 사람" : "보낸 사람"}
                                            </Text>
                                            <Text typo="font_body_2M" color="color_text_neutral_6">
                                                {history.decryptedPhoneNo}
                                            </Text>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                            <Text style={{flex: "1"}} typo="font_body_2M" color="color_text_neutral_4">
                                                배지 상세
                                            </Text>
                                            <Text
                                                typo="font_body_2M"
                                                color="color_text_neutral_6"
                                                style={{width: "180px", textAlign: "right"}}
                                            >
                                                {history.categoryList
                                                    ?.map((cat) => `${cat.categoryName} ${cat.count}개`)
                                                    .join(", ")}
                                            </Text>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            );
                        }

                        return (
                            <Accordion
                                key={index}
                                description={description}
                                title={
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{
                                            title: history.titleText,
                                        }}
                                        weight="bolder"
                                    />
                                }
                                weight="bolder"
                            >
                                {accordionBody}
                            </Accordion>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default SectionAccordion;
