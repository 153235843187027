import React, {useEffect, useState} from 'react'
import {Box, Button, CTA, Icon, Text, TextArea} from '@lguuxe/2024_designsystem_uni'
import CommonRightIconHeader from "../../../components/Common/CommonRightIconHeader";
import {useNavigate} from "react-router-dom";
import CommonPopup from "../../../components/Common/CommonPopup";
import {faqApi} from "../../../api/gatewayApi/notiAndQna";
import BS_List from "../../../components/Common/BS_List";
import useDebounce from "../../../hooks/useDebounce";
import {ASK_TYPE_LIST} from "./types";
import {gtmInquiryComplete, gtmPageLoad} from "../../../api/gtm";

const CustomerAskWrite = () => {
    const navigate = useNavigate();
    const [showAskTypeBS, setShowAskTypeBS] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedType, setSelectedType] = useState(0);
    const [isActiveSubmit, setIsActiveSubmit] = useState(false);
    const [supportText, setSupportText] = useState<any>(undefined);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: '/help/ask/write',
                contentGroup: `도움말|1:1 문의|1:1 문의 작성`,
                moduleName: '',
                pageTitle: '앱 이용 문의'
            });
        }
        gtm();
    }, []);

    useEffect(() => {
        setIsActiveSubmit(title.length > 0 && content.length > 0 && selectedType > 0);
    }, [title, content, selectedType]);

    const fetchSubmit = async () => {
        try {
            const requestBody = {
                title: title,
                type: '114003',
                contents: content,
                categoryCode: ASK_TYPE_LIST[selectedType - 1].code
            }
            faqApi.postInquiryReg(requestBody, 'v1').then((res) => {
                console.log('res:', res);
                navigate(-1);
            });
        } catch (e) {
            console.log('error fetchData:', e);
        }
    }

    const handleTypeSelected = (id: string) => {
        setSelectedType(Number(id));
    }
    const handleTitleChange = (event: any) => {
        setTitle(event.value);
        if (event.value.length > 19) {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '제목은 20자까지 적을 수 있어요.'
                }]);
        } else {
            setSupportText(undefined);
        }
    }
    const handleContentChange = (event: any) => {
        if (event.value.length <= 1000) {
            setContent(event.value);
        }
    }

    const handleSubmit = useDebounce(() => {
        fetchSubmit();
        gtmInquiryComplete(ASK_TYPE_LIST[selectedType - 1].label);
    });

    return (
        <>
            <CommonRightIconHeader title="앱 이용문의" icon="close_line" onClick={() => {
                navigate(-1)
            }}/>

            <Box
                type="2_trbl"
                style={{
                    backgroundColor: "#F3F5F6"
                }}
            >
                <Text typo="font_label_3Sb" color="color_text_neutral_3">문의유형</Text>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "13px 12px",
                        backgroundColor: "#fff",
                        border: "1px solid #E7EBEE",
                        borderRadius: "12px",
                        marginTop: "8px"
                    }}
                    onClick={() => {
                        setShowAskTypeBS(!showAskTypeBS)
                    }}
                >
                    <Text typo="font_heading_5Sb"
                          color="color_text_neutral_6">{selectedType === 0 ? '유형을 선택해 주세요.' : ASK_TYPE_LIST[selectedType - 1].label}</Text>
                    <Icon
                        color="#7f8a94"
                        name="chevron_down_small_line"
                        size="24px"
                    />
                </div>
            </Box>

            <Box
                type="2_trbl"
            >
                <TextArea
                    placeholder="제목을 입력해 주세요"
                    maxLength={20}
                    rows={1}
                    status="default"
                    onChange={handleTitleChange}
                    supportText={supportText}
                    showCounter={false}
                />
                {/*<TextField placeholder="제목을 입력해 주세요" onChange={handleTitleChange}/>*/}
            </Box>
            <Box style={{padding: "0 20px"}}>
                <TextArea
                    maxLength={1000}
                    placeholder="궁금한 점을 자세히 작성해 주세요"
                    rows={10}
                    status="default"
                    onChange={handleContentChange}
                />
            </Box>

            <Box
                style={{
                    // position: "fixed",
                    bottom: "0",
                    width: "100%",
                    padding: "0"
                }}
            >
                <CTA
                    gradation
                    layout="row"
                >
                    <Button color="neutral" onClick={() => {
                        setShowCancelPopup(true)
                    }}>
                        취소
                    </Button>
                    <Button color="primary" disabled={!isActiveSubmit} onClick={() => {
                        setShowSubmitPopup(true)
                    }}>
                        등록
                    </Button>
                </CTA>
            </Box>
            <BS_List isOpen={showAskTypeBS} title={'문의 유형'} dataList={ASK_TYPE_LIST.map((item => ({
                id: item.id.toString(),
                label: item.label
            })))} onClose={() => {
                setShowAskTypeBS(false)
            }} onSelected={handleTypeSelected}/>


            {showCancelPopup &&
                <CommonPopup description={'작성중인 내용은 저장되지 않습니다.\n정말로 취소하시겠습니까?'} cancelText={'나가기'} onClose={() => {
                    navigate(-1)
                }} submitText={'계속 작성'} onSubmit={() => {
                    setShowCancelPopup(false)
                }}/>}
            {showSubmitPopup && <CommonPopup description={'입력하신 문의 내용을\n등록할까요?'} cancelText={'취소'} onClose={() => {
                setShowSubmitPopup(false)
            }} submitText={'등록'} onSubmit={handleSubmit}/>
            }
        </>
    )
}

export default CustomerAskWrite
