import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionPaymentInfo3 from "../../components/MobilePayment/SectionPaymentInfo3";
import SectionHeadingListToggle, {
    SectionHeadingListToggleHandles
} from "../../components/MobilePayment/SectionHeadingListToggle";
import SectionMembershipDiscount from "../../components/MobilePayment/SectionMembershipDiscount";
import SectionHeadingList from "../../components/MobilePayment/SectionHeadingList";
import paymentApi from "../../api/gatewayApi/payment";
import {handlePromiseResult} from "../../api/api";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import useLoading from "../../hooks/useLoading";
import {preparePaymentApi} from "../../utils/helper";
import {initLimit, LimitInfo, PaymentBlock} from "./types";
import {useSnackbar} from "../../provider/SnackbarProvider";
import {sendEventReturn} from "../../api/nativeBridge";
import {gtmCommonComp, gtmPageLoad} from "../../api/gtm";


export interface PaymentSettingHandles {
    handleCheckPassword: (isSuccess: boolean, target: string) => void;
    getPassword: (password: string) => void;
    handleCheckPasswordTerm: (isSuccess: boolean, target: string) => void;
}

const MobilePaymentSetting = forwardRef<PaymentSettingHandles>((props, ref) => {
    const [setting, setSetting] = useState<PaymentBlock>({payBlckYn: "N", autoPayBlckYn: "N"});
    const [isTermAgree, setIsTermAgree] = useState<boolean>(false);
    const [limit, setLimit] = useState<LimitInfo>(initLimit);
    const [userStatus, setUserStatus] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [autoDiscount, setAutoDiscount] = useState("")
    const [password, setPassword] = useState<string>('')
    const {showSnackbar} = useSnackbar()
    const toggleRef = useRef<SectionHeadingListToggleHandles>(null);
    let isCarrier = false

    useImperativeHandle(ref, () => ({
        handleCheckPassword: (isSuccess, target) => {
            console.log("handleCheckPassword", isSuccess, target)
            if (target === 'payBlock') { // 차단 해제시
                if (isSuccess) {
                    fetchPaymentSetting(setting)
                    showSnackbar("일반 휴대폰결제를 이용할 수 있어요.")
                    gtmCommonComp('일반 휴대폰결제 설정', '해제')
                } else {
                    setSetting({...setting, payBlckYn: "Y"})
                }
            }
            if (target === 'autoPayBlock') {
                if (isSuccess) {
                    fetchPaymentSetting(setting)
                    showSnackbar("월 정기 결제를 이용할 수 있어요.")
                    gtmCommonComp('월 정기 자동결제 설정', '해제')
                } else {
                    setSetting({...setting, autoPayBlckYn: "Y"})
                }
            }
        },
        getPassword: (password) => {
            setPassword(password)
        },
        handleCheckPasswordTerm: async (isSuccess, target) => {
            if (target === 'payBlock') {
                if (isSuccess) {
                    // 성공하면 약관 동의 api 를 쏘고 원래 하던 함수를 호출
                    const res = await preparePaymentApi('12', password, {}, paymentApi.postServiceUseTerm);
                    if (res.header.resultCode === '0000') {
                        addItem('useTermYn', 'Y');
                        setIsTermAgree(true);
                        toggleRef.current?.handleClickToggle('payBlock');
                    }
                } else {
                    setSetting({...setting, payBlckYn: setting.payBlckYn === "Y" ? "N" : "Y"})
                }
            }
            if (target === 'autoPayBlock') {
                if (isSuccess) {
                    // 성공하면 약관 동의 api 를 쏘고 원래 하던 함수를 호출
                    const res = await preparePaymentApi('12', password, {}, paymentApi.postServiceUseTerm);
                    if (res.header.resultCode === '0000') {
                        setIsTermAgree(true);
                        addItem('useTermYn', 'Y');
                        toggleRef.current?.handleClickToggle('autoPayBlock');
                    }
                } else {
                    setSetting({...setting, autoPayBlckYn: setting.autoPayBlckYn === "Y" ? "N" : "Y"})
                }
            }
        }
    }))

    useEffect(() => {
        sendEventReturn('PASSWORD', {});
        fetchData();
    }, []);

    const fetchData = async () => {
        const telcoCode = await getItemByKey("telcoCode")
        isCarrier = telcoCode === "L";
        const useTermYn = await getItemByKey('useTermYn');
        setIsTermAgree(useTermYn === 'Y');
        try {
            const [setting, limit, autoDiscount] = await Promise.allSettled([
                paymentApi.getSetting('v1'),
                paymentApi.getLimit({}, 'v1'),
                paymentApi.getMembershipDiscount({reqType: "G"}, "v1")
            ]);
            setSetting(handlePromiseResult(setting, {payBlckYn: "N", autoPayBlckYn: "N"}));
            setLimit(handlePromiseResult(limit, initLimit));
            setAutoDiscount(handlePromiseResult(autoDiscount, {discountSetStus: ''}).discountSetStus)
            setUserStatus(await getItemByKey("userStatus"));
        } catch (error) {
            console.log("Error fetchData:", error);
        } finally {
            setIsLoading(false); // 데이터가 성공적으로 가져오거나 오류가 발생한 후 로딩 상태 변경
            await gtmPageLoad({
                url: '/mobile-pay/setting',
                contentGroup: '마이|휴대폰 결제|휴대폰 결제 설정',
                moduleName: '',
                pageTitle: '휴대폰결제 설정'
            });
        }
    };

    const fetchPaymentSetting = async (newSetting: PaymentBlock) => {
        try {
            const res = await preparePaymentApi('12', password, {
                payBlckYn: newSetting.payBlckYn,
                autoPayBlckYn: newSetting.autoPayBlckYn
            }, paymentApi.putSetting);
            if (res.header.resultCode === '0000') {
                setSetting(newSetting);
            }
        } catch (error) {
            console.log("Error fetchPaymentSetting:", error);
        }
    }

    const handleSettingChange = (setting: PaymentBlock, isFetch: boolean) => {
        setSetting(setting);
        if (isFetch) {
            fetchPaymentSetting(setting);
        }
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={"휴대폰결제 설정"}/>

            {/* 월 결제 한도 */}
            <SectionPaymentInfo3 limit={limit}/>
            <CommonDivider/>

            {/* 차단 설정 */}
            <SectionHeadingListToggle ref={toggleRef} setting={setting} onSettingChange={handleSettingChange}
                                      userStatus={userStatus}
                                      isTermAgree={isTermAgree}/>
            <CommonDivider/>

            {/* 멤버십 설정 */}
            <SectionMembershipDiscount autoDiscount={autoDiscount} setAutoDiscount={setAutoDiscount}/>
            <CommonDivider/>

            {/* 비밀번호 설정 */}
            <SectionHeadingList/>

            {/*<BS_PaymentTerm isOpen={showPaymentTermBs} onClose={() => setShowPaymentTermBs(false)} onAgree={}/>*/}
        </>
    );
});

export default MobilePaymentSetting;
