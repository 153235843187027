import React from 'react';
import {Image, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonDividerVertical from "../../components/Common/CommonDividerVertical";
import benefitIcon from "../../images/icon/6_notification/benefit_24.svg";
import paymentIcon from "../../images/icon/6_notification/payment_24.svg";
import eventIcon from "../../images/icon/6_notification/event_24.svg";
import badgeIcon from "../../images/icon/6_notification/badge_24.svg";
import noticeIcon from "../../images/icon/6_notification/notice_24.svg";
import {AlarmItem} from "../../pages/Notice/types";
import {useNavigate} from "react-router-dom";
import {formatTimeAgo} from "../../utils/helper"; // 타입 가져오기
import CommonNodata from "../Common/CommonNodata";
import useLandingPage from "../../hooks/useLandingPage";

interface SectionListProps {
    alarmList: AlarmItem[];
    onCheckState: (type: string) => boolean;
}

const SectionList: React.FC<SectionListProps> = ({alarmList, onCheckState}) => {
    const {landingPage} = useLandingPage();
    const navigate = useNavigate();

    const handleItemClick = (alarmItem: AlarmItem) => {
        if (!onCheckState('login')) {
            return;
        }

        // 영화예매는 vip 회원만 접근가능
        if (alarmItem.linkType === '117005') {
            if (!onCheckState('vip')) {
                return;
            }
        }

        switch (alarmItem.itemType) {
            case '160001': // 공지사항 알림
            case '160002': // 마케팅 알림
                // landingPage 함수로 이동
                landingPage(alarmItem.linkType, alarmItem.linkUrl, navigate, alarmItem.itemTitle);
                break;

            case '160003': // 멤버십 - 사용 안내
            case '160004': // 멤버십 - 취소 안내
                // 내가 받은 혜택 페이지로 이동
                navigate('/my/benefit');
                break;

            case '160005': // 멤버십 - 쿠폰 안내
                // 내 쿠폰 쿠폰 상세 페이지로 이동
                navigate('/my/coupon');
                break;

            case '160006': // 소액결제 - 완료 안내
            case '160007': // 소액결제 - 취소 안내
                // 휴대폰결제 페이지로 이동
                navigate('/mobile-pay');
                break;

            case '160008': // NFT - 발급 안내
            case '160009': // NFT - 참여 안내
            case '160010': // NFT - 발급 취소 안내(본인 소유)
            case '160011': // NFT - 발급 취소 안내(타인 소유)
            case '160012': // NFT - 선물 수신 안내
            case '160013': // NFT - 선물 발신 안내
                // 배지함 페이지로 이동
                navigate('/my/badge');
                break;

            default:
                // 기본적으로 linkUrl이 있는 경우 해당 URL로 이동
                if (alarmItem.linkUrl) {
                    if (alarmItem.linkType) {
                        landingPage(alarmItem.linkType, alarmItem.linkUrl, navigate, alarmItem.itemTitle);
                    } else {
                        navigate(alarmItem.linkUrl);
                    }
                }
                break;
        }
    };

    if (alarmList.length === 0) {
        return (
            <div
                style={{
                    paddingTop: "176px"
                }}
            >
                <CommonNodata type={"notice"} text={"받은 알림이 없어요."}/>
            </div>
        )
    }

    return (
        <>
            <div>
                <List
                    isSlotClickable={true}
                    items={alarmList.map((alarm, index) => ({
                        onClick: () => handleItemClick(alarm),
                        leftSlot: [
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                }}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'알림 - 링크 클릭'}
                                data-gtm-event-label={`컨텐츠 : ${alarm.itemTitle}`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'알림'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`${alarm.itemTitle}`}>
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        eyebrow: (
                                            <div
                                                style={{
                                                    paddingBottom: "2px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "4px",
                                                }}
                                            >
                                                <Image
                                                    customClass="customClass"
                                                    ratio="1:1"
                                                    src={getIcon(alarm.itemType)}
                                                    width="16"
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Text typo="font_detail_3Sb"
                                                          color="color_text_neutral_2">{getFilterBySearchAlarmType(alarm.itemType)}</Text>
                                                    <CommonDividerVertical/>
                                                    <Text typo="font_detail_3M"
                                                          color="color_text_neutral_2">{formatTimeAgo(alarm.itemDate)}</Text>
                                                </div>
                                            </div>
                                        ),
                                        title: alarm.itemTitle,
                                        description: alarm.itemSummary
                                    }}
                                />
                            </div>
                        ],
                        linkIconOnlyId: "empty"
                    }))}
                    name="list_name"
                    fill="none"
                    type="view"
                />
            </div>
        </>
    );
};

const getFilterBySearchAlarmType = (searchAlarmType: string): string => {
    const benefitCodes = [
        '160003', '160004', '160005', '160008', '160009',
        '160010', '160011', '160012', '160013'
    ];

    const phonePaymentCodes = ['160006', '160007'];
    const noticeCode = '160001';
    const eventCode = '160002';

    if (searchAlarmType === 'ALL') {
        return '전체';
    } else if (benefitCodes.some(code => searchAlarmType.includes(code))) {
        return '멤버십 혜택';
    } else if (phonePaymentCodes.some(code => searchAlarmType.includes(code))) {
        return '휴대폰결제';
    } else if (searchAlarmType.includes(noticeCode)) {
        return '공지사항';
    } else if (searchAlarmType.includes(eventCode)) {
        return '이벤트';
    } else {
        return '전체';
    }
};

// 아이템 타입에 따라 다른 아이콘을 반환하는 함수
const nftItemTypes = ['160008', '160009', '160010', '160011', '160012', '160013'];

const getIcon = (itemType: string) => {
    if (nftItemTypes.includes(itemType)) {
        return badgeIcon;
    }

    switch (itemType) {
        case '160001':
            return noticeIcon;
        case '160002': // 이벤트
            return eventIcon;
        case '160006': // 휴대폰 결제
        case '160007':
            return paymentIcon;
        default:
            return benefitIcon;
    }
};

export default SectionList;
