import {addItem, getItemByKey} from '../utils/indexedDB';
import {handleSendEvent} from "../utils/helper";
import {Header} from "./types";
import {encrypt, generateEssentialHeader, generateMCP_TR_KEY} from "./authFunctions";

export const ENVIROMENT: 'prod' | 'stg' | 'dev' = process.env.REACT_APP_NODE_ENV === 'production' ? 'prod' : process.env.REACT_APP_NODE_ENV === 'stage' ? 'stg' : 'dev';
export const BASE_URL =
    ENVIROMENT === 'prod' ? 'https://api-service.members.lguplatform.com' :
        ENVIROMENT === 'stg'
            ? 'https://api-services.members.lgudevplatform.com'
            : 'https://api-serviced.members.lgudevplatform.com'


//42fa3a779889ee927280c18add38422d5e2b0b65
interface ApiEndpointsType {
    [key: string]: any;
}

type AdditionalProp = {
    value: string;
    encrypt?: boolean;
};

const handlePromiseResult = <T, H>(result: PromiseSettledResult<{
    body: T;
    header: Header
}>, defaultValue?: T): T | undefined => {
    if (result.status === 'fulfilled' && result.value.header.resultCode === '0000') {
        return result.value.body;
    } else {
        console.error(`Failed to fetch :`, result);
        return defaultValue;
    }
};
const ApiEndpoints: ApiEndpointsType = Object.freeze({
    JOIN: '/join',
    BENEFIT: {
        PRS_MSG: '/benefit/prs-msg',
        UPTP: '/benefit/uptp',
        QUICK_MENU: '/benefit/quick-menu',
        RECENT_USED_BENEFIT: '/benefit/recent-used-benefit',
        BENEFIT_TAG: '/benefit/benefit-tag',
        BENEFIT_TAG_ID: (tagId: string) => `/benefit/benefit-tag/${tagId}`,
        MOVIE_RANKING: '/benefit/movie-ranking',
        EVENT_TAG: '/benefit/event-tag',
        EVENT_TAG_ID: (tagId: string) => `/benefit/event-tag/${tagId}`,
        RANDOM: {
            CATEGORIES: '/benefit/random/categories',
            CATEGORIES_ID: (categoryId: string) => `/benefit/random/categories/${categoryId}`,
        },
    },
    MEMBERSHIP: {
        UPTP: {
            MONTH_EVENT: '/membership-service/uptp/month-event',
            MONTH_EVENT_BY_DATE: (evtDate: string) => `/membership-service/uptp/month-event/${evtDate}`,
            BASIC_BENEFIT: '/membership-service/uptp/basic-benefit',
            VIP_BENEFIT: '/membership-service/uptp/vip-benefit',
            LOYAL_MEMBER_BENEFIT: '/membership-service/uptp/loyal-member-benefit',
            EVENT_DETAIL: (eventId: string) => `/membership-service/uptp/event/${eventId}`,
            APPLY: (eventId: string) => `/membership-service/uptp/event/${eventId}/apply`,
            ISSUE_COUPON: `/membership-service/uptp/event/cp-issue`,
            CHECK_APPLICANT: '/membership-service/uptp/event/checkApplicant',
        },
        CARD_INFO: '/membership-service/card-info',
        CARD_BARCODE: '/membership-service/card-barcode',
        VIP_BENEFIT_SUMMARY: '/membership-service/vip-benefit-summary',
        VIP_BENEFIT_THIS_MONTH: '/membership-service/vip-benefit-this-month',
        ALARM: '/membership-service/alarm',
        RANK_INFO: '/membership-service/rank-info',
        RANK_UP_TIPS: '/membership-service/rank-up-tips',
        VIP_KOK: {
            INFO: '/membership-service/vip-kok/info',
            USE_HISTORY: '/membership-service/vip-kok/use-history',
            PARTNER_CATEGORIES: '/membership-service/vip-kok/partner-categories',
            PARTNERS: '/membership-service/vip-kok/partners',
            CHECK: '/membership-service/vip-kok/please-check',
            CATEGORIES: '/membership-service/vip-kok/categories',
            CATEGORIES_BY_ID: (categoryId: string) => `/membership-service/vip-kok/categories/${categoryId}`,
            PLEASE_CHECK: '/membership-service/vip-kok/please-check',
            PARTNER_BY_ID: (ptnId: string) => `/membership-service/vip-kok/partners/${ptnId}`,
        },
        DATA_GIFTS: {
            LIMIT: '/membership-service/data-gifts/limit',
            AUTH: {INDEX: '/membership-service/data-gifts/auth', SMS: '/membership-service/data-gifts/auth/sms'},
            SEND: '/membership-service/data-gifts/send',
            HISTORY: '/membership-service/data-gifts/history',
        },
        MOVIE: {
            THEATER: '/membership-service/movie/theater',
            THEATER_BY_CODE: (theaterCode: string) => `/membership-service/movie/theater/${theaterCode}`,
            THEATER_LIST: (theaterCode: string, locationCode: string) =>
                `/membership-service/movie/theater-list/${theaterCode}/${locationCode}`,
            MOVIE_LIST: (theaterCode: string, locationCode: string) =>
                `/membership-service/movie/movie-list/${theaterCode}/${locationCode}`,
        },
        SIMPLE_CARD_INFO: '/membership-service/simple-card-info',
        USE_HISTORY: (yearMonth: string) => `/membership-service/use-history/${yearMonth}`,
    },
    PARTNER: {
        PARTNERS: {
            LIKE: '/partner/partners/like',
            LIKE_BY_ID: (ptnId: string) => `/partner/partners/like/${ptnId}`,
            INDEX: '/partner/partners',
            BY_ID: (ptnId: string) => `/partner/partners/${ptnId}`,
            RECOMM_BY_ID: (ptnId: string) => `/partner/partners/${ptnId}/recomm`,
            VIP_COK_BY_ID: (ptnId: string) => `/partner/ay/${ptnId}/vip-kok`,
            COUPON_BY_ID: (ptnId: string) => `/partner/partners/${ptnId}/coupon`,
            UMEM_BY_COOPCODE: (coopCode: string) => `/partner/partners/umem/${coopCode}`,
        },
        CATEGORIES: '/partner/categories',
        CATEGORIES_SUB: (categoryId: string) => `/partner/categories/${categoryId}/sub`,
    },
    AFFILIATE_SERVICE: {
        JOIN_STATUS: '/affiliate-service/join-status',
        INFO: '/affiliate-service/info',
        AGREEMENTS: '/affiliate-service/agreements',
        AGREEMENTS_BY_ID: (agrId: string) => `/affiliate-service/agreements/${agrId}`,
        JOIN: '/affiliate-service/join',
    },
    ATTENDANCE: {
        INDEX: '/attendance',
        CHECK: '/attendance/check',
        EVENT_INFO: '/attendance/event-info',
        LAST_MONTH_RESULT: '/attendance/last-month-result',
    },
    EVENT: {
        INDEX: '/event',
        BY_ID: (evtId: string) => `/event/${evtId}`,
        WINNER_INFO: (eventId: string) => `/event/winner-info/${eventId}`,
    },
    EVENT_BANNER: '/event-banner',
    MISSION: {
        REWARD_POINT: '/mission/reward-point',
    },
    MONEYME: '/money/benefit',
    COUPON: {
        ISSUED: {
            INDEX: '/coupon/issued',
            BY_ID: (cpnId: string) => `/coupon/issued/${cpnId}`,
            CREW_CONFIRM: (cpnKey: string) => `/coupon/issued/${cpnKey}/crew-confirm`,
        },
        BY_ID: (cpnId: string) => `/coupon/${cpnId}`,
        VIPKOK: {
            By_ID: (ptnId: string) => `/coupon/vipkok/${ptnId}`,
        }
    },
    PAYMENT: {
        SERVICE_USE_TERM: '/payment/service-use-term',
        STATUS: '/payment/status',
        MEMBERSHIP_DISCOUNT: '/payment/membership-discount',
        CARD_BARCODE: '/payment/card-barcode',
        SETTING: '/payment/setting',
        LIMIT: '/payment/limit',
        USE_HISTORY: '/payment/use-history',
        PASSWORD: '/payment/password',
        PAY_REQUEST: '/payment/pay-request',
        PAY_CONFIRM: '/payment/pay-confirm',
        PAY_OTP: '/payment/pay-otp',
        PAY_SEQ: '/payment/pay-seq',
        LOCK_DISABLE: '/payment/lock-disable',
    },
    NOTI: {PAY_RESULT: '/noti/pay-result'},
    SEARCH: {
        INDEX: '/search',
        RECOMM: '/search/recomm',
    },
    ALARM: '/alarm',
    MAP: {
        PARTNERS: '/map/partners',
        PARTNERS_BY_ID: (ptnId: string) => `/map/partners/${ptnId}`,
    },
    COMMON: {
        SESSION: '/common/session',
        TERMS: {
            AGREEMENTS: '/common/terms/agreements',
            AGREEMENTS_BY_ID: (termId: string) => `/common/terms/agreements/${termId}`,
            AGREEMENTS_CONFIRM: '/common/terms/agreements-confirm',
        },
        SERVICE_NOTI: '/common/service-noti',
        MARKETING_NOTI: '/common/marketing-noti',
        TUTORIAL_INFO: '/common/tutorial-info',
        BLOCK_INFO: '/common/block-info',
        SLASH_INFO: '/common/slash-info',
        TODAY: '/common/today',
    },
    EXTERNAL_SERVICE: {
        MONEYME: '/external-service/moneyme',
        KOKSHOP: '/external-service/kokshop',
    },
    GUIDE: {BENEFIT: '/guide/benefit'},
    SETTING: {
        COUPON: {
            ALARM: '/setting/coupon/alarm',
        },
        TERMS: {
            BNF_AD: '/setting/terms/bnf-ad',
            PERSONAL_INFO: '/setting/terms/personal-info',
        },
        MY_INFO: '/setting/myinfo',
        PUSH_REG: '/setting/pushRegId'
    },
    TERMS: {
        AGREEMENTS: '/terms/agreements',
        AGREEMENTS_BY_ID: (termId: string) => `/terms/agreements/${termId}`,
        PRIVACY_POLICY: '/terms/privacy-policy',
        JOIN: {
            INDEX: '/terms/join',
            AGREEMENTS: '/terms/join/agreements',
            AGREEMENTS_BY_ID: (termId: string) => `/terms/join/agreements/${termId}`,
        },
    },
    VERSION: '/version/app',
    NOTICE: {
        CATEGORIES: {
            LIST: '/notice/categories/list',
        },
        CATEGORIES_BY_ID: (categoryId: string) => `/notice/categories/${categoryId}`,
        CONTENT: (noticedId: string) => `/notice/content/${noticedId}`,
    },
    FAQ: {
        CATEGORIES: '/faq/categories',
        CATEGORIES_BY_ID: (categoryId: string) => `/faq/categories/${categoryId}`,
        CONTENT: (faqId: string) => `/faq/content/${faqId}`,
    },
    INQUIRY: {
        INDEX: '/inquiry',
        REG: '/inquiry/reg',
        BY_ID: (inqId: string) => `/inquiry/${inqId}`,
    },
    AUTH: {
        SMS: '/auth/sms',
        SMS_RESEND: '/auth/sms-resend',
        CONFIRM: '/auth/confirm',
        PARENT_CHECK: '/auth/parent-check',
    },
    DAS: '/das/user-info',
    NFT: {
        MY_BADGE: '/nft/my-badge',
        EVENT: '/nft/event',
        PROMOTION: '/nft/promotion',
        CHECK_WINNING: '/nft/check-winning',
        CATEGORIES: '/nft/categories',
        CATEGORY_BY_ID: (categoryId: string) => `/nft/categories/${categoryId}`,
        TERMS: '/nft/terms',
        TERMS_AGREEMENTS: '/nft/terms/agreements',
        RECEIPT_INFO: '/nft/receipt-info',
        CHECK_SENDING: '/nft/check-sending',
        HISTORY: '/nft/history',
        SEND: '/nft/send',
        CHECK_RECEIVER: '/nft/check-receiver',
        AVAILABLE_GIFTLIST: '/nft/available-giftList',
        BADGE_BY_CategoryId: (categoryId: string) => `/nft/${categoryId}`,
    },
    USER: {
        WITHDRAWAL: '/withdrawal',
    },
    EMERGENCY: {
        POPUP: '/emergency/popup',
    },
    IPIN: {
        REDIRECT: '/ipin/redirect',
    },
    STAT: {
        HISTORY: '/stat/click-history',
    },
});

export const fetchAuth = async <T>(mcpTrKey: string,): Promise<any> => {
    const url = `${BASE_URL}/api-service/v1/auth/sign-in`;
    const customHeader = await generateEssentialHeader(mcpTrKey);

    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const options: RequestInit = {
        method: 'GET',
    }
    const headers = {
        ...defaultHeaders,
        ...customHeader
    };

    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

const fetchJson = async <T>(
    url: string,
    options: RequestInit = {},
    mcpTrKey?: string,
    additionalProps?: { [key: string]: AdditionalProp },
    timeout: number = 15 * 1000 // 타임아웃 시간 (기본값: 15000ms = 15초)
): Promise<T> => {
    const controller = new AbortController();
    const {signal} = controller;

    // 타임아웃 설정
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
        mcpTrKey = mcpTrKey || generateMCP_TR_KEY();
        const customHeader = await generateEssentialHeader(mcpTrKey);

        const defaultHeaders = {
            'Content-Type': 'application/json',
        };

        if (additionalProps) {
            for (const [key, prop] of Object.entries(additionalProps)) {
                const {value, encrypt: shouldEncrypt} = prop;
                if (shouldEncrypt) {
                    customHeader[key] = await encrypt(mcpTrKey, value);
                } else {
                    customHeader[key] = value;
                }
            }
        }

        const headers = {
            ...defaultHeaders,
            ...customHeader,
        };

        let response = await fetch(url, {...options, headers, signal});
        if (!response.ok) {
            if (response.status === 401) {
                //통계형 API 요청은 401처리 제외
                if(url.endsWith('/stat/click-history')) return response.json()

                const responseAuth = await fetchAuth(mcpTrKey);
                const token = responseAuth.body.token;
                await addItem('sessionKey', token);
                const platform = await getItemByKey('platform');
                handleSendEvent(platform, 'SET_AUTHORIZATION', {
                    authorization: `${token}`,
                });

                // api 재호출
                const newCustomHeader = await generateEssentialHeader(mcpTrKey);
                const headers = {
                    ...defaultHeaders,
                    ...newCustomHeader,
                };
                response = await fetch(url, {...options, headers, signal});
                console.log("again call api response", response);
            }
        }
        return response.json();
    } catch (error: any) {
        if (error.name === 'AbortError') {
            console.error(`Request to ${url} timed out after ${timeout} ms`);
            throw new Error(`Request timed out after ${timeout} ms`);
        }
        throw error;
    } finally {
        clearTimeout(timeoutId);
    }
};

// test에만 사용됨
export const fetchAuthConfirm = async <T>(token: string) => {
    const url = `${BASE_URL}/api-service/v1/auth/test`;
    const customHeader = await generateEssentialHeader();

    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const options: RequestInit = {
        method: 'GET',
    }
    const headers = {
        ...defaultHeaders,
        ...customHeader
    };
    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

interface Api {
    get: (url: string, params?: Record<string, any>, version?: string, mcpTrKey?: string, additionalProps?: {
        [key: string]: AdditionalProp
    }) => Promise<any>;
    post: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
    put: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
    delete: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
}

const api: Api = {
    get: (url: string, params: Record<string, any> = {}, version: string = 'v1', mcpTrKey, additionalProps?: {
        [key: string]: AdditionalProp
    }): Promise<any> => {
        const queryString = new URLSearchParams(params).toString();
        return fetchJson(`${BASE_URL}/api-service/${version}${url}?${queryString}`, {}, mcpTrKey, additionalProps);
    },
    post: (url: string, body: Record<string, any>, version: string = 'v1', mcpTrKey): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
        }, mcpTrKey);
    },
    put: (url: string, body: Record<string, any>, version: string = 'v1'): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });
    },
    delete: (url: string, body: Record<string, any>, version: string = 'v1'): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'DELETE',
            body: JSON.stringify(body),
        });
    },
};

export {ApiEndpoints, api, handlePromiseResult};
