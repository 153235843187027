import React, {useEffect, useState} from "react";
import {Button, Icon, Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {Benefit, Tag} from "../../pages/Home/types";
import BS_MainRegionBS from "../Partner/BS_MainRegionBS";
import BS_SubRegionBS from "../Partner/BS_SubRegionBS";
import {MainRegion, Region} from "../../pages/Partner/types";
import {partnerApi} from "../../api/gatewayApi/partnerAndAffiliate-service";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import CommonNodata from "../Common/CommonNodata";
import CommonLocation from "../Partner/CommonLocation";
import defaultThumbnail from "../../images/default/default thumbnail.svg";
import {ENVIROMENT} from "../../api/api";


interface BenefitInfoProps {
    benefits: Benefit[];
    selectedBenefitTag: Tag;
    onLocationClick: (depth2: string, depth3: string) => void;
}

const initRegion: Region = {
    categoryId: "",
    categoryName: "",
    categoryType: "",
};

const initMainRegion: MainRegion = {
    categoryId: "",
    categoryName: "",
    categoryType: "",
    subRegionList: [],
};

const BenefitInfo: React.FC<BenefitInfoProps> = ({benefits, selectedBenefitTag, onLocationClick}) => {
    const navigate = useNavigate();
    const [selectedMainRegion, setSelectedMainRegion] = useState<MainRegion>(initMainRegion);
    const [selectedSubRegion, setSelectedSubRegion] = useState<Region>(initRegion);
    const [interestLocation, setInterestLocation] = useState<String>();
    const [subCategoryList, setSubCategoryList] = useState<MainRegion[]>([]);
    const [showMainRegionBS, setShowMainRegionBS] = useState(false);
    const [showSubRegionBS, setShowSubRegionBS] = useState(false);
    const [showLocationProcess, setLocationProcess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const location = await getItemByKey("interestLocation");
            setInterestLocation(location);
        };
        fetchData();
    }, []);

    const fetchSubCategory = async (categoryId: string) => {
        try {
            const res = await partnerApi.getCategoriesSub(ENVIROMENT === 'prod' ? '10' : '180', {cateDepth: "2"}, "v1");
            if (res.header.resultCode !== "0000") {
                console.log("fetchSubCategory error");
            }

            const mainRegionList = res.body.categoryList;
            mainRegionList.map((item: MainRegion) => {
                item.subRegionList = [];
                item.subRegionList.push({categoryId: "", categoryName: "전체", categoryType: ""});
                try {
                    partnerApi.getCategoriesSub(item.categoryId, {cateDepth: "3"}, "v1").then((res) => {
                        if (res.header.resultCode !== "0000") {
                            console.log("fetchSubCategory error");
                        }
                        item.subRegionList = item.subRegionList.concat(res.body.categoryList);
                    });
                } catch (e) {
                    console.log("fetchSubCategory error");
                }
            });
            setSubCategoryList(mainRegionList);
        } catch (e) {
            console.log("fetchSubCategory error");
        }
    };

    const handleMainRegionSelect = (mainRegion: MainRegion) => {
        setSelectedMainRegion(mainRegion);
        // setSelectedSubRegion(initRegion);
        if (mainRegion.subRegionList.length > 1) {
            setShowSubRegionBS(true);
        }
    };

    const handleSubRegionSelect = async (subRegion: Region) => {
        setSelectedSubRegion(subRegion);
        const location = `${selectedMainRegion.categoryName} ${subRegion.categoryName}`;
        setInterestLocation(location);
        await addItem("interestLocation", location);
        await addItem("interestLocationId", `${selectedMainRegion.categoryId}|${subRegion.categoryId}`);
        onLocationClick(selectedMainRegion.categoryId, subRegion.categoryId);
    };

    const handleLegionClick = async () => {
        await fetchSubCategory("test");
        setShowMainRegionBS(true);
    };

    // List 컴포넌트에 사용할 아이템 배열 생성
    const items = benefits.map((benefit) => ({
        onClick: () => navigate(`/partners/${benefit.ptnId}`),
        leftSlot: [
            <Image key={benefit.ptnId} src={benefit.ptnLogoImgUrl} srcFallback={defaultThumbnail} ratio="1:1"
                   width="48px" rounded="50%"/>,
            <div
                key={`${benefit.ptnId}-details`}
                style={{
                    gap: "2px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Text typo="font_body_1M" color="color_text_neutral_3" style={{fontSize: "12px"}}>
                    {benefit.ptnName}
                </Text>
                <Text typo="font_detail_2M" color="color_text_neutral_5" style={{
                    fontSize: "16px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                }}>
                    {benefit.benefitSummary}
                </Text>
            </div>,
        ],
        linkIconOnlyId: "empty",
        dataAttributes: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': `추천 제휴사 블록|${selectedBenefitTag.tagType === "158002" && interestLocation ? `내 주변 혜택|${interestLocation}` : `${selectedBenefitTag.tagName}`} - 링크 클릭`,
            'data-gtm-event-label': `컨텐츠 : ${benefit.benefitSummary}`,
            'data-gtm-click-url': '',
            'data-gtm-click-location': `추천 제휴사 블록|${selectedBenefitTag.tagType === "158002" && interestLocation ? `내 주변 혜택|${interestLocation}` : `${selectedBenefitTag.tagName}`}`,
            'data-gtm-click-direction': '내부',
            'data-gtm-click-text': `${benefit.benefitSummary}`,
        },
    }));

    return (
        <>
            <div style={{padding: "0"}}>
                <div
                    style={{
                        paddingTop: "12px",
                        width: "100%",
                        borderRadius: "12px",
                        border: "1px solid #DCE0E5",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    {/* 특정 benefitType이 158002일 때 디자인 노출 */}
                    {selectedBenefitTag.tagType === "158002" && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "3px",
                            }}
                        >
                            <div style={{display: "flex", justifyContent: "row", padding: "0px 20px"}}>
                                <Icon
                                    style={{paddingRight: "10px"}}
                                    color={"#7F8A94"}
                                    name="location_pin_filled"
                                    size="24px"
                                    onClick={() => setLocationProcess(true)}
                                    data-gtm-event-name={'all_click'}
                                    data-gtm-event-category={'클릭'}
                                    data-gtm-event-action={'추천 제휴사 블록|내 주변 혜택 - 링크 클릭'}
                                    data-gtm-event-label={`컨텐츠 : location_pin_filled`}
                                    data-gtm-click-url={''}
                                    data-gtm-click-location={'추천 제휴사 블록|내 주변 혜택'}
                                    data-gtm-click-direction={'내부'}
                                    data-gtm-click-text={`location_pin_filled`}
                                />
                                <Text typo="font_body_1M" color={"#7F8A94"} style={{fontSize: "16px", fontWeight: 600}}>
                                    {interestLocation ? interestLocation : "관심지역을 설정해 보세요."}
                                </Text>
                                <div style={{display: "flex", marginLeft: "auto"}}>
                                    <Button color="neutral" fill="solid" key="location-button" size="xSmall"
                                            onClick={handleLegionClick}
                                            data-gtm-event-name={'all_click'}
                                            data-gtm-event-category={'클릭'}
                                            data-gtm-event-action={'추천 제휴사 블록|내 주변 혜택 - 링크 클릭'}
                                            data-gtm-event-label={`컨텐츠 : 설정하기`}
                                            data-gtm-click-url={''}
                                            data-gtm-click-location={'추천 제휴사 블록|내 주변 혜택'}
                                            data-gtm-click-direction={'내부'}
                                            data-gtm-click-text={`설정하기`}>
                                        설정 하기
                                    </Button>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", paddingTop: "9px"}}></div>
                            <div
                                style={{
                                    width: "calc(100%)",
                                    height: "1px",
                                    backgroundColor: "#E7EBEE",
                                }}
                            ></div>
                        </div>
                    )}

                    {/* 조건부 렌더링: tagType이 158002이고 interestLocation이 설정된 경우 */}
                    {selectedBenefitTag.tagType === "158002" && !interestLocation ? (
                        <div style={{padding: "20px 0px"}}>
                            <CommonNodata type={"location"} text={"관심지역을 설정하고\n근처의 U+멤버십 혜택을 확인해보세요!"}/>
                        </div>
                    ) : (
                        <>
                            <List type="view" fill="none" highlightColor="#FF0000" items={items}/>
                        </>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "9px",
                        }}
                    >
                        <div
                            style={{
                                width: "calc(100% - 40px)",
                                height: "1px",
                                backgroundColor: "#E7EBEE",
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "46px",
                        }}
                    >
                        <Button fill="ghost" color="neutral" rightIcon="chevron_right_small_line"
                                onClick={() => navigate("/partners")}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={`추천 제휴사 블록|${selectedBenefitTag.tagType === "158002" && interestLocation ? `내 주변 혜택|${interestLocation}` : `${selectedBenefitTag.tagName}`} - 링크 클릭`}
                                data-gtm-event-label={`컨텐츠 : 추천 제휴사 더보기`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={`추천 제휴사 블록|${selectedBenefitTag.tagType === "158002" && interestLocation ? `내 주변 혜택|${interestLocation}` : `${selectedBenefitTag.tagName}`}`}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`추천 제휴사 더보기`}>
                            <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                더보기
                            </Text>
                        </Button>
                    </div>

                    <BS_MainRegionBS
                        regionList={subCategoryList}
                        selectedRegionId={selectedMainRegion.categoryId}
                        isOpen={showMainRegionBS}
                        onClose={() => setShowMainRegionBS(false)}
                        onSelect={handleMainRegionSelect}
                        bottomSpace={"3.5rem"}
                    />
                    <BS_SubRegionBS
                        mainRegion={selectedMainRegion}
                        selectedRegion={selectedSubRegion}
                        isOpen={showSubRegionBS}
                        onClose={() => setShowSubRegionBS(false)}
                        onSelect={handleSubRegionSelect}
                        onBack={() => {
                            setShowSubRegionBS(false)
                            setShowMainRegionBS(true)
                        }}
                    />

                    <CommonLocation isOpen={showLocationProcess} onClose={() => setLocationProcess(false)}/>
                </div>
            </div>

        </>
    );
};

export default BenefitInfo;
