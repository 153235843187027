import React, {useState} from 'react';
import {Icon, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import ExImg from "../../images/default/default thumbnail.svg";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import {SwipeableHandlers, useSwipeable} from 'react-swipeable';
import useLandingPage from "../../hooks/useLandingPage";
import {useNavigate} from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";

interface BannerInfoProps {
    bannerList: BannerItem[];
}

export interface BannerItem {
    bannerId: string;
    bannerTitle?: string;
    bannerImgUrl: string;
    targetInfo: {
        targetType: string;
        targetLink: string;
    }[];
}

const SectionBannerInfo: React.FC<BannerInfoProps> = ({bannerList}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {landingPage} = useLandingPage();
    const navigate = useNavigate();

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === bannerList.length - 1 ? prevIndex : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? prevIndex : prevIndex - 1
        );
    };

    const handleClick = useDebounce((item: BannerItem) => {
        if (item?.targetInfo.length === 0) return;
        landingPage(
            item.targetInfo[0].targetType,
            item.targetInfo[0].targetLink,
            navigate,
            item.bannerTitle,
        )
    });

    const handlers: SwipeableHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <div style={{padding: "20px 0 24px"}}>
            <div
                {...handlers}
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    // width: '100%',
                    padding: "0 20px", // 컨테이너 양쪽 여백 설정
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        transition: 'transform 0.3s ease',
                        transform: `translateX(-${currentIndex * (100)}%)`,
                    }}
                >
                    {bannerList.map((banner) => (
                        <div
                            key={banner.bannerId}
                            style={{
                                minWidth: 'calc(100% - 10px)', // 슬라이드 양쪽에 20px씩 보이도록 설정
                                margin: '0 5px', // 각 슬라이드에 좌우 마진 추가
                                display: 'inline-block',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <div
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={`${CLICK_LOCATION.MIDDLE_BANNER} - ${EVENT_ACTION.LINK_CLICK}`}
                                data-gtm-event-label={`컨텐츠 : 배너 ID: ${banner.bannerId} | 배너 타이틀: ${banner.bannerTitle}`}
                                data-gtm-click-url={banner.targetInfo?.[0]?.targetLink || ''}
                                data-gtm-click-location={`${CLICK_LOCATION.MIDDLE_BANNER}`}
                                data-gtm-click-direction={
                                    banner.targetInfo?.[0]?.targetType === '117001' ? '외부' : '내부'
                                }
                                data-gtm-click-text={banner.bannerTitle || ''}
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    verticalAlign: 'top',
                                }}
                                onClick={() => handleClick(banner)}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        backgroundColor: '#F3F5F6',
                                        borderRadius: '12px',
                                        marginBottom: '2px',
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        gap: '8px',
                                        width: 'calc(100vw - 100px)',
                                        alignItems: 'center',
                                    }}>
                                        <Image
                                            customClass="customClass"
                                            ratio="1:1"
                                            src={banner.bannerImgUrl || ExImg}
                                            width="40"
                                            rounded="8px"
                                            srcFallback={ExImg}
                                        />
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}>
                                            <TextSet
                                                subComponent="Body_1"
                                                text={{
                                                    title: (
                                                        <>
                                                            <Text style={{
                                                                display: "inline-block",
                                                                WebkitLineClamp: 1,
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "normal",
                                                            }}
                                                                  typo="font_body_1B">
                                                                {banner.bannerTitle || '제목 없음'}
                                                            </Text>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Icon
                                            color="#7F8A94"
                                            name="chevron_right_small_line"
                                            size="24px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    <Text typo="font_label_3M" color="color_text_neutral_5"
                          style={{fontSize: "12px", fontWeight: "500"}}>
                        {currentIndex + 1}
                    </Text>
                    <Text typo="font_label_3M" color="color_text_neutral_1"
                          style={{fontSize: "12px", fontWeight: "500"}}>
                        /{bannerList.length}
                    </Text>
                </div>
            </div>
        </div>
    );
}

export default SectionBannerInfo;