import React, {useEffect, useState} from 'react'
import {Box, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import CommonDividerThin from "../../../components/Common/CommonDividerThin";
import {useParams} from "react-router-dom";
import {notiApi} from "../../../api/gatewayApi/notiAndQna";
import {formatDate} from "../../../utils/dateUtiles";
import {decodeHtmlEntities} from "../../../utils/textUtils";
import {gtmPageLoad} from "../../../api/gtm";

interface Announcement {
    date: string;
    title: string;
    contents: string;
}

const initAnnouncement: Announcement = {
    date: '',
    title: '',
    contents: ''
}

const AnnouncementDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [announcement, setAnnouncement] = useState<Announcement>(initAnnouncement);

    useEffect(() => {
        const gtm = async () => {
            await gtmPageLoad({
                url: `/help/announcement/${id}`,
                contentGroup: `공지사항|공지사항 상세`,
                moduleName: '',
                pageTitle: '공지사항 상세'
            });
        }
        gtm();
    }, [])


    useEffect(() => {
        if (!id) return;
        const fetchData = async () => {
            if (!id) {
                console.error("id is undefined");
                setIsLoading(false);
                return;
            }
            try {
                notiApi.getContent(id, 'v1').then((res) => {
                    console.log(res);
                    setAnnouncement(res.body);
                });
            } catch (error) {
                console.log("Error fetchData:", error);
            }
        }
        fetchData();
    }, [id])

    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <CommonLeftIconHeader title={'공지사항 상세'}/>

            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        description: formatDate(announcement.date),
                        title: announcement.title
                    }}
                    weight="bolder"
                />
            </Box>

            <Box style={{padding: "8px 20px"}}>
                <CommonDividerThin/>
            </Box>

            <Box
                type="3_trbl"
            >
                <Text typo="font_body_1M" color="color_text_neutral_3">
                    {decodeHtmlEntities(announcement.contents)}
                </Text>
            </Box>
        </>
    )
}

export default AnnouncementDetail
