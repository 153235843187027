import React, {CSSProperties, useState} from 'react';
import {Button, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import DotIndicator from './DotIndicator';
import {CouponItem} from "../../pages/My/types";
import {formatDate} from "../../utils/dateUtiles";
import {couponApi} from "../../api/gatewayApi/other";
import Snackbar from "../Common/Snackbar";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import useDebounce from "../../hooks/useDebounce";
import {decodeHtmlEntities} from "../../utils/textUtils";
import {gtmAffiliateCouponDownloadComp} from "../../api/gtm";

interface CouponGroupProps {
    couponList: CouponItem[];
    onCouponIssue: (cpnId: string) => void;
    onCouponBsOpen: (coupon: CouponItem) => void;
}

const CouponList: React.FC<CouponGroupProps> = ({couponList, onCouponIssue, onCouponBsOpen}) => {
    const [showIssueSnackbar, setShowIssueSnackbar] = useState(false);
    const {showMessagePopup} = useMessagePopup();
    const [currentIndex, setCurrentIndex] = useState(0);

    const fetchIssueCoupon = async (coupon: CouponItem) => {
        try {
            couponApi.postCoupon(coupon.cpnId, {cpnType: coupon.cpnType}, 'v1').then((res) => {
                if (res.header.resultCode === '0000') {
                    setShowIssueSnackbar(true);
                    setTimeout(() => {
                        setShowIssueSnackbar(false);
                    }, 1500);
                    onCouponIssue(coupon.cpnId);
                    gtmAffiliateCouponDownloadComp(coupon.summary, coupon.ptnId, coupon.ptnName, '', `${formatDate(coupon.expStartDate)} - ${formatDate(coupon.expEndDate)}`, '', '');
                } else {
                    showMessagePopup(res.header.message);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleCouponIssue = useDebounce((coupon: CouponItem) => {
        fetchIssueCoupon(coupon);
    });

    const handleCouponClick = (coupon: CouponItem) => {
        onCouponBsOpen(coupon);
    };

    // 쿠폰 리스트를 4개씩 나눠서 배열로 저장
    const chunkedCouponList = [];
    for (let i = 0; i < couponList.length; i += 4) {
        chunkedCouponList.push(couponList.slice(i, i + 4));
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const scrollLeft = e.currentTarget.scrollLeft;
        const groupWidth = e.currentTarget.clientWidth;
        const newIndex = Math.round(scrollLeft / groupWidth);
        setCurrentIndex(newIndex);
    };


    return (
        <div style={styles.container}>
            <div style={styles.textSetContainer}>
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '쿠폰',
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            </div>

            {/* 각 4개씩의 쿠폰 그룹을 스크롤 가능한 영역에 표시 */}
            <div style={styles.scrollContainer} onScroll={handleScroll}>
                {chunkedCouponList.map((couponGroup, groupIndex) => (
                    <>
                        <div
                            key={groupIndex}
                            style={styles.couponGroup}
                        >
                            <List
                                type="view"
                                fill="none"
                                id={`couponGroup-${groupIndex}`}
                                isSlotClickable={true}
                                items={couponGroup.map((coupon, index) => ({
                                    leftSlot: [
                                        <Image
                                            key={`image-${index}`}
                                            src={coupon.ptnLogoImgUrl}
                                            srcFallback="defaultThumbnail"
                                            ratio="1:1"
                                            width="48px"
                                            rounded="50%"
                                            onClick={() => handleCouponClick(coupon)}
                                        />,
                                        <div
                                            key={`text-${index}`}
                                            style={styles.couponTextContainer}
                                            onClick={() => handleCouponClick(coupon)}
                                        >
                                            <TextSet
                                                subComponent="Body_1"
                                                text={{
                                                    eyebrow: <span>{coupon.ptnName}</span>,
                                                    title:
                                                        <Text
                                                            style={{
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'normal',
                                                                WebkitLineClamp: 1,
                                                            }}
                                                        >
                                                            {decodeHtmlEntities(coupon.summary)}
                                                        </Text>,

                                                    description: `사용기간: ${formatDate(coupon.expStartDate)} ~ ${formatDate(coupon.expEndDate)}`,
                                                }}
                                            />
                                        </div>,
                                    ],
                                    rightSlot: [
                                        coupon.isIssuedCoupon === 'N' ? (
                                            <Button onClick={() => handleCouponIssue(coupon)}>
                                                다운로드
                                            </Button>
                                        ) : (
                                            <Button disabled>
                                                다운 완료
                                            </Button>
                                        ),
                                    ],
                                    dataAttributes: {
                                        'data-gtm-event-name': 'all_click',
                                        'data-gtm-event-category': '클릭',
                                        'data-gtm-event-action': `쿠폰 - 링크 클릭`,
                                        'data-gtm-event-label': `컨텐츠 : ${coupon.ptnName}`,
                                        'data-gtm-click-url': '',
                                        'data-gtm-click-location': `쿠폰`,
                                        'data-gtm-click-direction': '내부',
                                        'data-gtm-click-text': `${coupon.ptnName}`,
                                    }
                                }))}
                            />
                        </div>

                    </>
                ))}
            </div>

            {chunkedCouponList.length > 1 && (
                <DotIndicator total={chunkedCouponList.length} currentIndex={currentIndex}/>
            )}

            {showIssueSnackbar && <Snackbar text="쿠폰이 발급되었습니다."/>}
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        gap: '0px',
        flexDirection: 'column',
        padding: '0',
    },
    textSetContainer: {
        width: '360px',
        backgroundColor: '#FFFFFF',
    },
    scrollContainer: {
        display: 'flex',
        gap: '16px', // 각 쿠폰 그룹 간의 간격
        overflowX: 'auto', // 좌우 스크롤 활성화
        // padding: '12px 0',
    },
    couponGroup: {
        minWidth: '100%', // 각 그룹이 4개씩 화면에 표시되도록 너비를 설정
        display: 'flex',
        // gap: '8px',
        // padding: '12px',
        borderRadius: '8px',
        // backgroundColor: '#f5f5f5', // 그룹 배경색 설정
    },
    couponTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
};

export default CouponList;