import React, {useEffect, useState} from 'react';
import {BottomSheet, Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {formatBarcode} from './utils';
import {initialMembershipBarcode, MembershipBarcode} from './types';
import Snackbar from '../Common/Snackbar';
import BottomNavigationComponent from './BottomNavigationComponent';
import BarcodeFailed from './BarcodeFailed';

import Barcode from 'react-barcode';

const BS_CouponBarcode = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [barcode, setBarcode] = useState<MembershipBarcode>(initialMembershipBarcode);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isLoadingFailed, setIsLoadingFailed] = useState(false);
    const handleOpen = () => setIsOpen(true);

    useEffect(() => {
        try {
            // TODO: API 호출
        } catch {
            setIsLoadingFailed(true);
        }
    }, []);

    const openBottomSheet = () => {
        handleOpen();
    };

    const handleCopy = () => {
        console.log('handleCopy');
        navigator.clipboard
            .writeText(barcode.barcode)
            .then(() => {
                setShowSnackbar(true);
                setTimeout(() => {
                    setShowSnackbar(false);
                }, 1000);
            })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    };

    const handleGenerateBarcode = () => {
    };

    return (
        <div>
            <BottomSheet isOpen onClose={() => {
            }}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading'>
                        쿠폰명
                        <Header.RightSlot>
                            <IconButton
                                color='neutral'
                                fill='icon'
                                icon='close_line'
                                noStates
                                onClick={function noRefCheck() {
                                }}
                            />
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>
                {/*<BottomSheet.CenterSlot>*/}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px 20px 30px',
                        gap: '8px',
                    }}
                >
                    {/* 여기부터 */}
                    {isLoadingFailed ? (
                        <BarcodeFailed onGenerateBarcode={handleGenerateBarcode}/>
                    ) : (
                        <div
                            style={{
                                border: '1px solid #E7EBEE',
                                borderRadius: '12px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            <div
                                style={{
                                    padding: '16px 20px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '280px',
                                        height: '56px',
                                        backgroundColor: '#2B292E',
                                        margin: '12px auto 0',
                                    }}
                                >
                                    <Barcode
                                        value={barcode.barcode}
                                        displayValue={false}
                                        height={56}
                                        width={2.4}
                                        margin={0}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '12px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                        {formatBarcode(barcode.barcode).map((chunk, index) => (
                                            <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                                {chunk}
                                            </Text>
                                        ))}
                                        <IconButton
                                            icon='copy_line'
                                            size='small'
                                            fill='icon'
                                            onClick={handleCopy}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {showSnackbar && <Snackbar text='바코드 번호가 복사되었습니다.'/>}
                {/*</BottomSheet.CenterSlot>*/}
                <BottomSheet.BottomSlot>
                    <BottomNavigationComponent openBottomSheet={openBottomSheet}/>
                </BottomSheet.BottomSlot>
            </BottomSheet>
        </div>
    );
};

export default BS_CouponBarcode;
