// ****************************************************
// [배포] 1.혜택 > 전체제휴처 > 헤택브랜드 > 상세
// ****************************************************
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import CommonDivider from '../../components/Common/CommonDivider';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';

import PartnerProfile from '../../components/Partner/PartnerProfile';
import BenefitInfoSection from '../../components/Partner/BenefitInfoSection';
import SectionCouponGroup from '../../components/Partner/CouponGroup';
import UsageAccordion from '../../components/Partner/UsageAccordion';
import QuickLink from '../../components/Partner/QuickLink';
import {partnerApi} from '../../api/gatewayApi/partnerAndAffiliate-service';
import '../../css/style.css';
import {initialPartnerDetailData, PartnerDetailData, ProfileInfo, VipKokInfo} from './types';
import {brazeEventApi} from "../../api/brazeBridge";
import VipKokBenefit from "../../components/Partner/VipKokBenefit";
import BS_VipKokDetail from "../../components/Partner/BS_VipKokDetail";
import {CouponItem, initCouponItem, RecommendPartner} from "../My/types";
import {handlePromiseResult} from "../../api/api";
import BS_CouponDetail from "../../components/Partner/BS_CouponDetail";
import useLoading from "../../hooks/useLoading";
import RecommendPartners from "../../components/Partner/RecommendPartners";
import {getItemByKey} from "../../utils/indexedDB";
import {gtmPageLoad} from "../../api/gtm";

const initialProfileInfo: ProfileInfo = {
    ptnId: '',
    ptnName: '',
    ptnImgUrl: '',
    isLike: '',
    likeNum: '0',
    isMembershipAliance: '',
    metaTag: '',
    barcodeYn: '',
};

const initialVipKokInfo: VipKokInfo = {
    ptnId: '',
    ptnName: '',
    vipSummary: '',
    ptnLogoImgUrl: '',
    vipKokUsed: '',
    vipBnf: '',
    vipBnfLimit: '',
    vipNotice: '',
    vipGuide: '',
};

const PartnersDetails = () => {
    const {ptnId} = useParams<{ ptnId: string }>();
    const [partnerInfo, setPartnerInfo] = useState<PartnerDetailData>(initialPartnerDetailData);
    const [couponList, setCouponList] = useState<CouponItem[]>([]);
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>(initialProfileInfo);
    const [vipKokInfo, setVipKokInfo] = useState(initialVipKokInfo);
    const [recommendList, setRecommendList] = useState<RecommendPartner[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCoupon, setSelectedCoupon] = useState<CouponItem>(initCouponItem);
    const [showVipkokPopup, setShowVipkokPopup] = useState(false);
    const [showCouponPopup, setShowCouponPopup] = useState(false);

    useEffect(() => {
        console.log('ptnId:', ptnId);
        setIsLoading(true);
        fetchPartnerDetailData();
    }, [ptnId]);

    const fetchPartnerDetailData = async () => {
        if (!ptnId) {
            console.error('ptnId is empty');
            return;
        }
        try {
            const response = await partnerApi.getPartnerById(ptnId, 'v1');
            if (response.header.resultCode === '0000') {
                setPartnerInfo(response.body);
                setProfileInfo({
                    ptnId: response.body.ptnId,
                    ptnName: response.body.ptnName,
                    ptnImgUrl: response.body.ptnLogoImgUrl,
                    isLike: response.body.isLike,
                    likeNum: response.body.likeNum,
                    isMembershipAliance: response.body.isMembershipAliance,
                    metaTag: response.body.metaTag,
                    barcodeYn: response.body.barcodeYn,
                });
                setVipKokInfo({
                    ptnId: response.body.ptnId,
                    ptnName: response.body.ptnName,
                    vipSummary: response.body.vipSummary,
                    ptnLogoImgUrl: response.body.ptnLogoImgUrl,
                    vipKokUsed: response.body.isVIPKok,
                    vipBnf: response.body.vipBnf,
                    vipBnfLimit: response.body.vipBnfLimit,
                    vipNotice: response.body.vipNotice,
                    vipGuide: response.body.vipGuide,
                });

                await gtmPageLoad({
                    url: `/Partners/${ptnId}`,
                    contentGroup: '혜택|제휴사|제휴사 상세|' + response.body.ptnName,
                    moduleName: 'VIP콕 안내|쿠폰|제휴사 추천|이용방법 안내',
                    pageTitle: '제휴사 상세'
                });
                brazeEventApi.affiliateInfo_detail_page_enter(response.body.ptnName, response.body.ptnId, response.body.mainCateName);
            }

            const memberStatus = await getItemByKey('memberStatus');
            const request = {
                cardLevel: memberStatus !== '5' ? await getItemByKey('cardLevel') : '',
                longTermCode: memberStatus !== '5' ? await getItemByKey('longTermCode') : '',
                youthYn: memberStatus !== '5' ? await getItemByKey('youthYn') : ''
            }
            const [couponRes, recommendRes] = await Promise.allSettled([
                partnerApi.getCoupon(ptnId, request, 'v1'),
                partnerApi.getPartnerRecomm(ptnId, 'v1'),]);
            setCouponList(handlePromiseResult(couponRes, {couponList: []}).couponList);
            setRecommendList(handlePromiseResult(recommendRes, {recommPtnList: []}).recommPtnList);

        } catch (error) {
            console.log('Failed to fetch partner detail data:', error);
            // setShowErrorPopup(true);
        } finally {
            setIsLoading(false);
            window.scrollTo(0, 0.1);

        }
    }

    const handleVipKokPopup = () => {
        setShowVipkokPopup(true);
    }

    const handleCouponIssue = (couponId: string) => {
        setCouponList(couponList.map((item) => {
            if (item.cpnId === couponId) {
                return {...item, isIssuedCoupon: 'Y'};
            }
            return item;
        }));
    };

    const handleCouponBsOpen = (coupon: CouponItem) => {
        setSelectedCoupon(coupon);
        setShowCouponPopup(true);
    };

    return (
        <>
            {useLoading(isLoading)}
            <div style={{position: 'fixed', width: '100%', zIndex: 300}}>
                {/* header */}
                <CommonLeftIconHeader title={'제휴사 상세'}/>
            </div>

            <div style={{marginTop: '3.5rem'}}>
                {/* 제휴사 소개 */}
                <PartnerProfile profileInfo={profileInfo}/>
                <CommonDivider/>

                {/* 혜택정보 */}
                <BenefitInfoSection benefitContent={partnerInfo.summary}
                                    benefitInfoList={partnerInfo.benefitInfoList}/>
                <CommonDivider/>

                {/*VIP콕 혜택*/}
                {partnerInfo.isVIPKok === 'Y' && (
                    <>
                        <VipKokBenefit vipKokInfo={vipKokInfo} onVipKokPopup={handleVipKokPopup}/>
                        <CommonDivider/>
                    </>
                )}

                {/* 쿠폰 */}
                {couponList.length !== 0 && partnerInfo.couponAvailable === 'Y' && <>
                    <SectionCouponGroup couponList={couponList} onCouponIssue={handleCouponIssue}
                                        onCouponBsOpen={handleCouponBsOpen}/>
                    <CommonDivider/>
                </>}

                {/* [제휴사]에 관심있는 고객님을 위한 추천 */}
                {recommendList.length !== 0 && <>
                    <RecommendPartners recommendList={recommendList} ptnName={partnerInfo.ptnName}/>
                    <CommonDivider/>
                </>}

                {/* 이용방법 및 유의사항 */}
                {partnerInfo.usageNoticeList &&
                    <UsageAccordion title={'이용방법 및 유의사항'}
                                    content={partnerInfo.usageNoticeList.length === 0 ? '' : partnerInfo.usageNoticeList[0].content}/>}

                {/* 퀵링크 */}
                <QuickLink partnerInfo={partnerInfo}/>

                <BS_VipKokDetail isOpen={showVipkokPopup} onClose={() => setShowVipkokPopup(false)}
                                 partnerInfo={partnerInfo} couponList={couponList}/>

                <BS_CouponDetail isOpen={showCouponPopup} onClose={() => setShowCouponPopup(false)}
                                 couponId={selectedCoupon.cpnId} isIssued={selectedCoupon.isIssuedCoupon === 'Y'}
                                 onCouponIssue={handleCouponIssue}/>
            </div>
        </>
    );
};

export default PartnersDetails;
