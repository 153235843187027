// API Response Types

export type MonthEvent = {
    displayMonth?: string; // TODO: 미사용
    today?: string; // title에 사용
    thisMonthImgUrl: string;
    totalCnt: string;
    eventDateList?: {
        evtDate: string;
    }[];
};

export type MonthEventByDate = {
    totalCount: string;
    eventList?: Event[];
};

export type Event = {
    evtId: string;
    evtSummary: string;
    evtTumbImgUrl: string;
    targetUrl: string;
    ptnId?: string; // TODO: 미사용
    ptnName: string;
    today?: string; // TODO: 미사용
    startDate: string;
    endDate: string;
    categoryId?: string; // TODO: 미사용
    categoryName?: string;
    tagId?: string; // TODO: 미사용
    tagName?: string;
}

export type BenefitPtn = {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    benefitSummary: string;
    categoryName: string;
};

export type VipPtn = {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    benefitSummary: string;
    categoryName: string;
};

export type LongTerm = {
    serviceName: string;
    serviceImgUrl: string;
    benefitSummary: string;
    serviceLink?: string;
};

export type CouponInfo = {
    joinNoCode: string;
    cpnId: string;
    cpnName: string;
    proCpnId: string
    expStartDate: string;
    expEndDate: string;
    memLevel: string;
    longMemLevel: string;
    ageStartDate: string | null;
    ageEndDate: string | null;
};

export type GiftInfo = {
    joinNoCode: string;
    cpnId: number;
    cpnName: string;
    giftTitle: string;
    giftQt: number;
    benefitsAmt: number;
};

export type PrizeInfo = {
    prizeDate: string;
    reasonsYn?: string;
    nfoCollectAgreeYn: string;
    infoHandlingAgreeYn: string;
    infoCollectAgree?: string;
    infoHandlingAgree?: string;
};

export type ProInfo = {
    proId: string;
    proType: '138001' | '138002' | '138003'; // 프로모션 구분
    proTitle: string;
    proStartDate: string;
    proEndDate: string;
    proTarget: '137001' | '137002' | '137003' | '137004'; // 프로모션 대상
    status: 'Y' | 'N'; // 프로모션 상태
    introImgUrl: string;
    cpnUseTerms?: string; // 쿠폰형 이용 조건
    cpnUseMethod?: string; // 쿠폰형 이용 방법
    cpnPrecautions?: string; // 쿠폰형 유의 사항
    entBenefits?: string; // 응모형/바코드형 혜택
    entUseTerms?: string; // 응모형 응모 조건
    entUseMethod?: string; // 응모형 : 응모형 혜택 사용 안내, 바코드형 : 이용안내
    entPrecautions?: string; // 응모형 : 응모형 결과발표 및 유의사항, 바코드형 : 꼭확인하세요
    cpnInfoList?: CouponInfo[]; // 쿠폰형인 경우 쿠폰정보 필수
    entGiftInfoList?: GiftInfo[]; // 응모형인 경우 경품정보 필수
    entPrizeInfo?: PrizeInfo; // 응모형인 경우 당첨 정보 필수
};

export type ProInfoResponse = {
    proInfo: ProInfo;
};

export const initialProInfo: ProInfo = {
    proId: '',
    proType: '138001', // 기본적으로 '쿠폰형'으로 설정
    proTitle: '',
    proStartDate: '',
    proEndDate: '',
    proTarget: '137001', // 기본적으로 '맴버십'으로 설정
    status: 'Y', // 기본적으로 '진행 중'으로 설정
    introImgUrl: '',
    cpnUseTerms: '',
    cpnUseMethod: '',
    cpnPrecautions: '',
    entBenefits: '',
    entUseTerms: '',
    entUseMethod: '',
    entPrecautions: '',
    cpnInfoList: [],
    entGiftInfoList: [],
    entPrizeInfo: undefined,
};


// MonthEvent 초기 데이터
export const initialMonthEvent: MonthEvent = {
    displayMonth: '', // TODO: 미사용
    today: '', // TODO: 미사용
    thisMonthImgUrl: '',
    totalCnt: '0',
    eventDateList: []
};

// MonthEventByDate 초기 데이터
export const initialMonthEventByDate: MonthEventByDate = {
    totalCount: '0',
    eventList: [
        {
            evtId: '',
            evtSummary: '',
            evtTumbImgUrl: '',
            targetUrl: '',
            ptnId: '', // TODO: 미사용
            ptnName: '',
            today: '', // TODO: 미사용
            startDate: '',
            endDate: '',
            categoryId: '', // TODO: 미사용
            categoryName: '',
            tagId: '', // TODO: 미사용
            tagName: ''
        }
    ]
};