import React, {CSSProperties, forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Box, TextSet, Toggle} from "@lguuxe/2024_designsystem_uni";
import CommonPopup from "../Common/CommonPopup";
import {callCheckPassword} from "../../utils/helper";
import {PaymentBlock} from "../../pages/MobilePayment/types";
import {useSnackbar} from "../../provider/SnackbarProvider";
import BS_PaymentTerm from "../Barcode/BS_PaymentTerm";
import {getItemByKey, removeItemByKey} from "../../utils/indexedDB";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {gtmCommonComp} from "../../api/gtm";

interface SectionHeadingListToggleProps {
    setting: PaymentBlock
    onSettingChange: (setting: PaymentBlock, isFetch: boolean) => void
    userStatus: string;
    isTermAgree: boolean;
}


export interface SectionHeadingListToggleHandles {
    handleClickToggle: (type: string) => void
}

const SectionHeadingListToggle =
    forwardRef<SectionHeadingListToggleHandles, SectionHeadingListToggleProps>(({
                                                                                    setting,
                                                                                    onSettingChange,
                                                                                    userStatus,
                                                                                    isTermAgree,
                                                                                }, ref) => {
        const [openPayBlockPopup, setOpenPayBlockPopup] = useState<boolean>()
        const [openPayOpenPopup, setOpenPayOpenPopup] = useState<boolean>()
        const [openMonthBlockPopup, setOpenMonthBlockPopup] = useState<boolean>()
        const [openMonthOpenPopup, setOpenMonthOpenPopup] = useState<boolean>()
        const [showPaymentTermBs, setShowPaymentTermBs] = useState<boolean>(false);
        const [prevSetting, setPrevSetting] = useState<PaymentBlock>(setting)
        const [prevClickToggle, setPrevClickToggle] = useState<string>('')
        const {showSnackbar} = useSnackbar()
        const {showMessagePopup} = useMessagePopup()
        const [UserStatus, setUserStatus] = useState<string>("")

        useImperativeHandle(ref, () => ({
            handleClickToggle: (type: string) => {
                if (type === "payBlock") {
                    handleGeneralPaymentToggle(true)
                }
                if (type === "autoPayBlock") {
                    handleAutoPaymentToggle(true)
                }
            }
        }));

        useEffect(() => {
            const fetchUserStatus = async () => {
                const userStatus = await getItemByKey("userStatus")
                setUserStatus(userStatus)
            }
            fetchUserStatus()
        }, []);

        // 토글은 누르면 무조건 켜지기 떄문에 상태를 무조건 변경해준다....
        // 그리고 실패하면 롤백한다.
        const handleGeneralPaymentToggle = (retry: boolean) => {
            let newState = ""
            if (retry) {
                newState = prevSetting.payBlckYn === "Y" ? "N" : "Y"
            } else {
                newState = setting.payBlckYn === "Y" ? "N" : "Y"
                onSettingChange({...setting, payBlckYn: newState}, false)

                if (userStatus === '44' && newState === 'N') {// 19세 미만은
                    showMessagePopup('만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 차단을 해제할 수 있어요.')
                    return;
                }

                if (!isTermAgree) {
                    setPrevSetting(setting)
                    setShowPaymentTermBs(true);
                    setPrevClickToggle("payBlock")
                    return
                }
            }

            if (newState === "Y") { // off -> on : 바로 차단하면됨
                setOpenPayBlockPopup(true)
            } else { // on -> off : 비밀번호를 입력한다 -> 해제한다
                setOpenPayOpenPopup(true)
            }
        };

        const handleAutoPaymentToggle = (retry: boolean) => {
            let newState = ""
            if (retry) {
                newState = prevSetting.autoPayBlckYn === "Y" ? "N" : "Y"
            } else {
                newState = setting.autoPayBlckYn === "Y" ? "N" : "Y"
                onSettingChange({...setting, autoPayBlckYn: newState}, false)
                if (!isTermAgree) {
                    setPrevSetting(setting)
                    setShowPaymentTermBs(true);
                    setPrevClickToggle("autoPayBlock")
                    return
                }
            }

            if (newState === "Y") { // off -> on : 바로 차단하면됨
                setOpenMonthBlockPopup(true)
            } else {
                setOpenMonthOpenPopup(true)
            }
        };

        const handlePayBlockPopupClose = () => {
            onSettingChange({...setting, payBlckYn: "N"}, false)
            setOpenPayBlockPopup(false)
        }

        const handleMonthBlockPopupClose = () => {
            onSettingChange({...setting, autoPayBlckYn: "N"}, false)
            setOpenMonthBlockPopup(false)
        }

        const handlePayOpenPopup = () => {
            onSettingChange({...setting, payBlckYn: "N"}, false)
            setOpenPayOpenPopup(false)
        }

        const handleMonthOpenPopup = () => {
            onSettingChange({...setting, autoPayBlckYn: "N"}, false)
            setOpenMonthOpenPopup(false)
        }

        const handleTermAgree = () => {
            if (prevClickToggle === "payBlock") {
                callCheckPassword('paymentSetting/payBlockTerm')
            }
            if (prevClickToggle === "autoPayBlock") {
                callCheckPassword('paymentSetting/autoPayBlockTerm')
            }
        }

        return (
            <div
                style={{
                    paddingBottom: "12px",
                }}
            >
                <Box type="2_trbl">
                    <TextSet
                        subComponent="Heading_4"
                        text={{
                            title: '차단 설정',
                        }}
                        weight="bolder"
                    />
                </Box>
                <div>
                    {/* 앱 푸시 설정 */}
                    <div style={sectionStyle}>
                        <TextSet
                            subComponent="Body_1"
                            text={{title: '일반 휴대폰결제'}}
                        />
                        <Toggle
                            id="appPushYn"
                            checked={setting.payBlckYn === 'Y'}
                            value={setting.payBlckYn}
                            onChange={() => handleGeneralPaymentToggle(false)}
                            size="medium"
                            trueValue="value"
                            weight="default"
                            width="auto"
                        />
                    </div>

                    {/* 멤버십 할인 승인 */}
                    <div style={sectionStyle}>
                        <TextSet
                            subComponent="Body_1"
                            text={{title: '월 정기 자동결제'}}
                        />
                        <Toggle
                            id="membershipDiscount"
                            checked={setting.autoPayBlckYn === 'Y'}
                            value={setting.autoPayBlckYn}
                            onChange={() => handleAutoPaymentToggle(false)}
                            size="medium"
                            trueValue="value"
                            weight="default"
                            width="auto"
                        />
                    </div>
                </div>
                {openPayBlockPopup && (
                    <CommonPopup
                        description='일반 휴대폰 결제를 차단하시겠어요?'
                        submitText='차단'
                        onSubmit={async () => {
                            showSnackbar("일반 휴대폰결제를 차단했어요")
                            onSettingChange({...setting, payBlckYn: "Y"}, true)
                            await removeItemByKey('paymentBarcodeTime')
                            gtmCommonComp('일반 휴대폰결제 설정', '설정')
                            setOpenPayBlockPopup(false)
                        }}
                        cancelText='취소'
                        onClose={handlePayBlockPopupClose}
                    />
                )}
                {(openPayOpenPopup && userStatus === "44") && (
                    <CommonPopup
                        description='만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 차단을 해제할 수 있어요.'
                        submitText='확인'
                        onSubmit={handlePayOpenPopup}
                    />
                )}
                {(openPayOpenPopup && userStatus !== "44") && (
                    <CommonPopup
                        description='차단을 해제하려면 비밀번호를 입력해주세요.'
                        submitText='비밀번호 입력'
                        onSubmit={() => {
                            callCheckPassword('mobile-pay/setting|payBlock')
                            setOpenPayOpenPopup(false)
                        }}
                        cancelText='취소'
                        onClose={() => {
                            onSettingChange({...setting, payBlckYn: 'Y'}, false)
                            setOpenPayOpenPopup(false)
                        }}
                    />
                )}
                {openMonthBlockPopup && (
                    <CommonPopup
                        description='월 정기 자동 결제를 차단하시겠어요?'
                        submitText='차단'
                        onSubmit={() => {
                            showSnackbar("월 정기 결제를 차단했어요.")
                            onSettingChange({...setting, autoPayBlckYn: 'Y'}, true)
                            gtmCommonComp('월 정기 자동결제 설정', '설정')
                            setOpenMonthBlockPopup(false)
                        }}
                        cancelText='취소'
                        onClose={() => {
                            onSettingChange({...setting, autoPayBlckYn: 'N'}, false)
                            setOpenMonthBlockPopup(false)
                        }}
                    />
                )}
                {(openMonthOpenPopup && userStatus === "44") && (
                    <CommonPopup
                        description='만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 차단을 해제할 수 있어요.'
                        submitText='확인'
                        onSubmit={handleMonthOpenPopup}
                    />
                )}
                {(openMonthOpenPopup && userStatus !== "44") && (
                    <CommonPopup
                        description='차단을 해제하려면 비밀번호를 입력해주세요.'
                        submitText='비밀번호 입력'
                        onSubmit={() => {
                            callCheckPassword('mobile-pay/setting|autoPayBlock')
                            setOpenMonthOpenPopup(false)
                        }}
                        cancelText='취소'
                        onClose={() => {
                            onSettingChange({...setting, autoPayBlckYn: 'Y'}, false)
                            setOpenMonthOpenPopup(false)
                        }}
                    />
                )}
                <BS_PaymentTerm isOpen={showPaymentTermBs} onClose={() => {
                    setShowPaymentTermBs(false);
                    onSettingChange(prevSetting, false);
                }} onAgree={handleTermAgree}/>
            </div>
        );
    });

const sectionStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 20px",
};

const toggleContainerStyle: CSSProperties = {
    borderRadius: "12px",
    backgroundColor: "#F3F5F6",
    margin: "0 20px",
};


export default SectionHeadingListToggle;