import React, {useEffect, useRef, useState} from 'react';
import '../../css/style.css';
import {BottomSheet, Segment} from '@lguuxe/2024_designsystem_uni';
import {
    BarcodeStatus,
    initialMembershipBarcode,
    initialPaymentBarcode,
    initialUserInfo,
    MembershipBarcode,
    PaymentBarcode,
    TabType,
    UserInfo
} from './types';
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import {defaultMembershipInfo, MembershipInfo} from "../../pages/Home/types";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import paymentApi from "../../api/gatewayApi/payment";
import MembershipBarcodeSection from "./MembershipBarcodeSection";
import PaymentBarcodeSection from "./PaymentBarcodeSection";
import CardInfo from "./CardInfo";
import NoLogin from "./NoLogin";
import CommonPopup from "../Common/CommonPopup";
import BS_PaymentTerm from "./BS_PaymentTerm";
import useDebounce from "../../hooks/useDebounce";
import {callCheckPassword, preparePaymentApi} from "../../utils/helper";
import {handlePromiseResult} from "../../api/api";
import {initLimit, LimitInfo} from "../../pages/MobilePayment/types";

interface BarcodeProps {
    onToggle: (isOpen: boolean) => void;
    initialTab?: TabType;
    isOpen: boolean;
    bottomSpace?: string;
    noCardInfo?: boolean;
}

export const BARCODE_TIMER = 1000 * 60 * 15 + 1;
export const INFINITE_BARCODE_TIMER = 1000 * 60 * 60 * 24 * 365;
export const PAYMENT_BARCODE_TIMER = 1000 * 60 * 2;

const BS_Barcode: React.FC<BarcodeProps> = ({onToggle, initialTab, isOpen, bottomSpace, noCardInfo}) => {
    const [selectedTab, setSelectedTab] = React.useState<TabType>(initialTab || 'membership');
    const [cardInfo, setCardInfo] = useState<MembershipInfo>(defaultMembershipInfo);
    const [membershipBarcode, setMembershipBarcode] = useState<MembershipBarcode>(initialMembershipBarcode);

    const [paymentBarcode, setPaymentBarcode] = useState<PaymentBarcode>(initialPaymentBarcode);
    const [paymentLimit, setPaymentLimit] = useState<LimitInfo>(initLimit);
    const [showPaymentUseTermBS, setShowPaymentUseTermBS] = useState(false);

    const [showRepresentativePopup, setShowRepresentativePopup] = useState(false);
    const hasMounted = useRef(false);
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);

    useEffect(() => {
        const handleResume = (event: CustomEvent) => {
            const getPaymentBarcode = async () => {
                const paymentBarcodeTime = await getItemByKey('paymentBarcodeTime');
                if (paymentBarcodeTime) {
                    console.log('get paymentBarcodeTime from indexedDB : ', new Date(paymentBarcodeTime));
                    const now = new Date();
                    if (paymentBarcodeTime - now.getTime() > 0) {
                        setPaymentBarcode({
                            barcode: await getItemByKey('paymentBarcode'),
                            expTime: new Date(paymentBarcodeTime),
                            status: 'ready',
                            barcodeTime: paymentBarcodeTime,
                            payBlckYn: 'N',
                            autoPayBlckYn: 'N',
                        });
                    } else {
                        setPaymentBarcode({
                            ...paymentBarcode,
                            status: 'init'
                        })
                    }
                }
                // } else {
                //     setPaymentBarcode({
                //         ...paymentBarcode,
                //         status: 'init'
                //     })
                // }
            }
            getPaymentBarcode();
        }

        window.addEventListener('resume', handleResume as EventListener);

        return () => {
            window.removeEventListener('resume', handleResume as EventListener);
        };
    }, []);

    useEffect(() => {
        const fetchInit = async () => {
            setUserInfo({
                memberStatus: await getItemByKey('memberStatus'),
                userStatus: await getItemByKey('userStatus'),
                payExistYn: await getItemByKey('payExistYn')
            });

            const memberStatus = await getItemByKey('memberStatus');
            if (memberStatus === '0' || memberStatus === '5') {
                console.log('fetchInit no action')
                return;
            }

            if (isOpen) {
                // const barcodeTime = await getItemByKey('barcodeTime');
                // if (barcodeTime) {
                //     const now = new Date();
                //     if (barcodeTime - now.getTime() > 0) {
                //         // TODO: 바코드값 가져와서 보여주기
                //         setMembershipBarcode({
                //             barcode: await getItemByKey('barcode'),
                //             createDate: new Date(barcodeTime),
                //             status: 'ready',
                //             barcodeTime: barcodeTime,
                //         });
                //     } else { // TIME OUT
                //         fetchMembershipBarcode();
                //     }
                // } else { // 바코드가 없을 경우
                //     fetchMembershipBarcode();
                // }

                // 고정바코드 불러오기 코드
                const barcode = await getItemByKey('barcode');
                if (barcode) {
                    setMembershipBarcode({
                        barcode: barcode,
                        createDate: new Date(),
                        status: 'ready',
                        barcodeTime: INFINITE_BARCODE_TIMER,
                    });
                }

                const paymentBarcodeTime = await getItemByKey('paymentBarcodeTime');
                if (paymentBarcodeTime) {
                    console.log('get paymentBarcodeTime from indexedDB : ', new Date(paymentBarcodeTime));
                    console.log('paymentBarcodeTime : ', paymentBarcodeTime);
                    const now = new Date();
                    console.log('now : ', now.getTime());
                    if (paymentBarcodeTime - now.getTime() > 0) {
                        setPaymentBarcode({
                            barcode: await getItemByKey('paymentBarcode'),
                            expTime: new Date(paymentBarcodeTime),
                            status: 'ready',
                            barcodeTime: paymentBarcodeTime,
                            payBlckYn: 'N',
                            autoPayBlckYn: 'N',
                        });
                    }
                }
                if (await getItemByKey('memberId')) {
                    fetchPaymentStatus();
                }
            }
        }
        const fetchCardInfo = async () => {
            const memberId = await getItemByKey('memberId');
            if (!memberId) return;
            try {
                if (noCardInfo) { // 첫 진입시 cardInfo를 두번 호출 방지
                    const barcode = await getItemByKey('barcode');
                    setCardInfo({
                        cardLevel: await getItemByKey('cardLevel'),
                        cardLevelName: '',
                        isVIP: await getItemByKey('isVIP'),
                        longTermCode: await getItemByKey('longTermCode'),
                        longTermName: '',
                        youthYn: await getItemByKey('youthYn'),
                        youthName: '유쓰',
                        totalDiscount: await getItemByKey('totalDiscount'),
                        thisMonthDiscount: '',
                        barcode: barcode,
                        token: '',
                        encKey: '',
                    });
                    setMembershipBarcode({
                        barcode: barcode,
                        createDate: new Date(),
                        status: 'ready',
                        barcodeTime: INFINITE_BARCODE_TIMER,
                    });

                    const limitRes = await paymentApi.getLimit({}, 'v1');
                    if (limitRes.header.resultCode === '0000') {
                        setPaymentLimit(limitRes.body);
                    }

                } else {
                    const [cardInfo, paymentLimit] = await Promise.allSettled([
                        membershipsServiceApi.getCardInfo({}, 'v1'),
                        paymentApi.getLimit({}, 'v1')
                    ]);

                    const cardInfoRes = handlePromiseResult(cardInfo, defaultMembershipInfo);
                    setCardInfo(cardInfoRes);
                    setMembershipBarcode({
                        barcode: cardInfoRes.barcode,
                        createDate: new Date(),
                        status: 'ready',
                        barcodeTime: INFINITE_BARCODE_TIMER,
                    });
                    addItem('barcodeTime', INFINITE_BARCODE_TIMER);
                    addItem('barcode', cardInfoRes.barcode);
                    setPaymentLimit(handlePromiseResult(paymentLimit, initLimit));
                }
            } catch (error) {
                console.error('Failed to fetch :', error);
            }
        }

        fetchInit();
        if (hasMounted.current && isOpen) {

            fetchCardInfo();
        } else {
            hasMounted.current = true;
        }
    }, [isOpen]);

    // const fetchMembershipBarcode = async () => {
    //     try {
    //         membershipsServiceApi.getCardBarcode('v1').then((response) => {
    //             if (response.header.resultCode === '0000' && response.body) {
    //                 const body = response.body;
    //                 const createDate = new Date(body.createDate.replace(' ', 'T'))
    //                 const barcode: MembershipBarcode = {
    //                     barcode: body.barcode,
    //                     createDate: createDate,
    //                     status: 'ready',
    //                     barcodeTime: createDate.getTime() + BARCODE_TIMER,
    //                 }
    //                 setMembershipBarcode(barcode);
    //                 addItem('barcodeTime', createDate.getTime() + BARCODE_TIMER);
    //                 addItem('barcode', response.body.barcode);
    //             } else {
    //                 setMembershipBarcode({
    //                     ...membershipBarcode, status: 'fail'
    //                 })
    //             }
    //         });
    //     } catch (error) {
    //         console.log('An unexpected error occurred:', error);
    //         setMembershipBarcode({
    //             ...membershipBarcode, status: 'fail'
    //         })
    //     }
    // }

    const fetchPaymentBarcode = async (passwordSha: string) => {
        const res = await preparePaymentApi('20', passwordSha, {}, paymentApi.getCardBarcode);
        if (res.header.resultCode === '0000' && res.body) {
            const expTime = new Date(res.body.expTime.replace(' ', 'T'))
            console.log('expTime : ', expTime);
            setPaymentBarcode({
                ...paymentBarcode,
                barcode: res.body.barcode,
                expTime: expTime,
                status: 'ready',
                barcodeTime: expTime.getTime(),
            });
            addItem('paymentBarcodeTime', expTime.getTime());
            addItem('paymentBarcode', res.body.barcode);
        } else {
            setPaymentBarcode({
                ...paymentBarcode,
                status: 'fail'
            })
        }
    }

    const handleTabChange = async (event: any) => {
        const match = event.id.match(/\d+$/);
        if (match[0] === '0') {
            setSelectedTab('membership');

        } else if (match[0] === '1') {
            const useTermYn = await getItemByKey('useTermYn');
            if (userInfo.userStatus === '44' && useTermYn !== 'Y') {
                setShowRepresentativePopup(true);
                return;
            }
            setSelectedTab('payment');
        }
    };

    const handleBarcodeStatus = useDebounce((status: BarcodeStatus) => {
        if (status === 'ready') {
            // fetchMembershipBarcode();

            // 고정바코드 불러오기 코드
            addItem('barcode', cardInfo.barcode);
            setMembershipBarcode({
                ...membershipBarcode,
                barcode: cardInfo.barcode,
                createDate: new Date(),
                status: 'ready',
                barcodeTime: INFINITE_BARCODE_TIMER,
            });
        }

        if (status === 'init') {
            setMembershipBarcode({
                ...membershipBarcode,
                status: 'init'
            })
        }
    }, 500);

    const handlePaymentBarcode = useDebounce((status: BarcodeStatus, password?: string) => {
        console.log('handlePaymentBarcode ... status : ', status, 'password : ', password);
        switch (status) {
            case 'ready':
                if (!password) {
                    setPaymentBarcode({
                        ...paymentBarcode,
                        status: 'fail'
                    })
                } else {
                    fetchPaymentBarcode(password);
                }
                break;
            case 'fail':
                const fetchServiceUseTerm = async () => {
                    const termRes = await paymentApi.getServiceUseTerm('v1');
                    if (termRes.header.resultCode === '0000') {
                        addItem('useTermYn', termRes.body.useTermYn)
                    }
                }
                fetchServiceUseTerm();
                setPaymentBarcode({
                    ...paymentBarcode,
                    status: status
                })
                break;
            default:
                setPaymentBarcode({
                    ...paymentBarcode,
                    status: status
                })
        }
    });

    const fetchPaymentStatus = async () => {
        try {
            const res = await paymentApi.getSetting('v1');
            if (res.header.resultCode === '0000') {
                if (res.body.payBlckYn === 'Y') {
                    setPaymentBarcode({
                        ...paymentBarcode,
                        payBlckYn: res.body.payBlckYn,
                        autoPayBlckYn: res.body.autoPayBlckYn,
                    });
                }
            } else {
                setPaymentBarcode({
                    ...paymentBarcode,
                    status: 'fail'
                });
            }

        } catch (error) {
            console.log('An unexpected error occurred:', error);
            setPaymentBarcode({
                ...paymentBarcode,
                status: 'fail'
            });
        }
    }

    const handleTermAgree = useDebounce(() => {
        callCheckPassword('barcodeTerm')
        onToggle(true);
        handlePaymentBarcode('loading');
    })

    return (
        <div>
            {isOpen && (<div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // 검은색 배경, 투명도 조절 가능
                zIndex: 999, // BottomSheet보다 낮은 zIndex를 설정
            }}
                             onClick={() => onToggle(false)} // 배경을 클릭하면 BottomSheet 닫기
            />)}

            <BottomSheet isOpen={isOpen} hasHandlebar={true} innerScroll={false}
                         onClose={() => onToggle(false)}
                         bottomSpace={bottomSpace === undefined ? '2rem' : bottomSpace}>
                <BottomSheet.TopSlot>
                    <div style={{
                        paddingBottom: '28px',
                    }}>
                        {userInfo.memberStatus === '0' && userInfo.userStatus !== '49' ? (
                            <NoLogin/>
                        ) : (
                            <>
                                {/*  ----- 19세 미만 및 타사는 휴대폰 결제 탭이 안보인다 ---- */}
                                {!(userInfo.memberStatus === '5' || userInfo.memberStatus === '3' || userInfo.memberStatus === '4' || userInfo.userStatus === '49') && (
                                    <div
                                        style={{
                                            padding: '6px 20px',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <Segment
                                            id='membershipTab'
                                            buttons={[
                                                {
                                                    disabled: false,
                                                    label: 'U+멤버십',
                                                    selected: selectedTab === 'membership',
                                                    dataAttributes: {
                                                        'data-gtm-event-name': 'all_click',
                                                        'data-gtm-event-category': '클릭',
                                                        'data-gtm-event-action': '바코드 카테고리 - 링크 클릭',
                                                        'data-gtm-event-label': '컨텐츠 : U+멤버십',
                                                        'data-gtm-click-url': '',
                                                        'data-gtm-click-location': '바코드 카테고리',
                                                        'data-gtm-click-direction': '내부',
                                                        'data-gtm-click-text': 'U+멤버십',
                                                    }
                                                },
                                                {
                                                    disabled: false,
                                                    label: '휴대폰결제',
                                                    selected: selectedTab === 'payment',
                                                    dataAttributes: {
                                                        'data-gtm-event-name': 'all_click',
                                                        'data-gtm-event-category': '클릭',
                                                        'data-gtm-event-action': '바코드 카테고리 - 링크 클릭',
                                                        'data-gtm-event-label': '컨텐츠 : 휴대폰결제',
                                                        'data-gtm-click-url': '',
                                                        'data-gtm-click-location': '바코드 카테고리',
                                                        'data-gtm-click-direction': '내부',
                                                        'data-gtm-click-text': '휴대폰결제',
                                                    }
                                                }
                                            ]}
                                            onClick={handleTabChange}
                                            size='medium'
                                            status='default'
                                        />
                                    </div>
                                )}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '12px 20px 28px',
                                    gap: '8px',
                                }}
                                >
                                    {selectedTab === 'membership' && (
                                        <MembershipBarcodeSection barcode={membershipBarcode}
                                                                  onChange={handleBarcodeStatus}/>
                                    )}
                                    {selectedTab === 'payment' && (
                                        <PaymentBarcodeSection barcode={paymentBarcode} onChange={handlePaymentBarcode}
                                                               onTermBsOpen={() => setShowPaymentUseTermBS(true)}
                                                               onToggle={onToggle}
                                        />
                                    )}

                                    <CardInfo tabType={selectedTab} cardInfo={cardInfo} paymentLimit={paymentLimit}
                                              initialTab={initialTab}/>
                                </div>
                            </>
                        )}
                    </div>
                </BottomSheet.TopSlot>
            </BottomSheet>

            <CommonPopup title={'법정대리인 동의 안내'}
                         description={'법정대리인 동의 후 이용할 수 있어요.\n가까운 LG유플러스 매장 또는 고객센터,\nPASS 앱에서 동의 후 이용해 주세요.'}
                         submitText={'확인'} onSubmit={() => setShowRepresentativePopup(false)}
                         isOpen={showRepresentativePopup}/>

            <BS_PaymentTerm isOpen={showPaymentUseTermBS} onClose={() => setShowPaymentUseTermBS(false)}
                            onAgree={handleTermAgree}/>
        </div>
    );
};

export default BS_Barcode;
